import { useMutation } from '@apollo/client';
import { INVITE_USER_ORG } from '../../graphql/mutations/userRole';

export default function useInviteUser() {
  const [
    inviteUser,
    { loading: inviteUserLoading, data: inviteUserResponse, error: inviteUserError }
  ] = useMutation(INVITE_USER_ORG);
  return {
    inviteUser,
    inviteUserLoading,
    inviteUserError,
    inviteUserResponse: inviteUserResponse?.inviteUser
  };
}
