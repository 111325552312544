import React from 'react';

import './DashboardSkeleton.scss';

const DashboardSkeleton = () => {
  const widgetHeadings = ['Issue Types', 'Link Types', 'Top Hosts'];

  return (
    <div className="dashboard-skeleton">
      <div className="dashboard-skeleton__top-row">
        <div className="dashboard-skeleton__charts">
          <div className="dashboard-skeleton__heading">Total Broken Links</div>
          <div className="dashboard-skeleton__content">
            <span className="dashboard-skeleton__dummy" />
            <span className="dashboard-skeleton__dummy" />
            <span className="dashboard-skeleton__dummy dashboard-skeleton__dummy--small" />
          </div>
        </div>
        <div className="dashboard-skeleton__links">
          <div className="dashboard-skeleton__heading">All Links</div>
          <div className="dashboard-skeleton__content">
            <span className="dashboard-skeleton__dummy" />
            <span className="dashboard-skeleton__dummy" />
            <span className="dashboard-skeleton__dummy dashboard-skeleton__dummy--small" />
          </div>
        </div>
      </div>
      <div className="dashboard-skeleton__bottom-row">
        {widgetHeadings.map(item => (
          <div key={item} className="dashboard-skeleton__info-table">
            <div className="dashboard-skeleton__heading">{item}</div>
            <div className="dashboard-skeleton__content">
              <span className="dashboard-skeleton__dummy" />
              <span className="dashboard-skeleton__dummy" />
              <span className="dashboard-skeleton__dummy dashboard-skeleton__dummy--small" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default DashboardSkeleton;
