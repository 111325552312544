import React from 'react';
import useAPIError from '../../hooks/useAPIError';
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../../containers/Modal';
import { APP_ICONS } from '../../constants';
import sprite from '../../assets/sprite.svg';
import Button from '../Button/Button';
import './ErrorModal.scss';

const ErrorModal = () => {
  const { error, removeError } = useAPIError();

  const handleSubmit = () => {
    removeError();
  };
  return (
    <Modal show={!!error} addClass="error_modal">
      <ModalHeader addClass="error_modal__header">
        <>
          <div className="error_modal__icon-box">
            <svg className="error_modal__icon">
              <use href={`${sprite}#${APP_ICONS.EXCLAMATION}`} />
            </svg>
            <div>{error && error.title ? error.title : 'ERROR'}</div>
          </div>
          <div className="divider" />
        </>
      </ModalHeader>
      <ModalBody addClass="error_modal__body">
        {error && error.message ? (
          <div className="error_modal__message">{error.message}</div>
        ) : (
          <div />
        )}
      </ModalBody>
      <ModalFooter>
        <div className="error_modal__buttons">
          <Button
            label="OK"
            name="ok"
            type="button"
            customStyleClass="error_modal__button"
            clickHandler={handleSubmit}
          >
            Ok
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default ErrorModal;
