export const COLUMNS = [
  {
    Header: 'Name',
    accessor: 'name',
    sticky: 'left'
  },
  {
    Header: 'Email Id',
    accessor: 'email',
    sticky: 'left'
  },
  {
    Header: 'Role',
    accessor: 'role',
    sticky: 'left'
  },
  {
    Header: 'Organization',
    accessor: 'organization'
  },
  {
    Header: 'Website',
    accessor: 'website'
  },
  {
    Header: 'Action',
    accessor: 'action'
    // Cell: (
    //   <Button
    //     icon="three-dot"
    //     label=""
    //     type="button"
    //     name="run"
    //     appearance="secondary"
    //     size="nano"
    //     customStyleClass="user__table__click"
    //   />
    // )
  }
];

export const GROUPED_COLUMNS = [];
