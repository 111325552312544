/*

This component is meant to be used as the main wrapper of a card. The main content has to be passed to it.

*/

import React from 'react';
import PropTypes from 'prop-types';

import './Card.scss';

const Card = ({ children, className }) => (
  <div className={`card-wrapper ${className}`} data-testid="card-wrapper">
    {children}
  </div>
);

Card.defaultProps = {
  className: ''
};

Card.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

export default Card;
