import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { DELETE_ORG } from '../../graphql/mutations/organization';
import { USER_DATA_V1 } from '../../graphql/queries/UserData';

export default function useDeleteOrg() {
  const [openDeleteOrg, setOpenDeleteOrg] = useState(false);
  const [deleteOrg, { loading: deleteOrgLoading, data: deleteOrgResponse, error: deleteOrgError }] =
    useMutation(DELETE_ORG, { refetchQueries: [USER_DATA_V1] });

  useEffect(() => {
    if (!deleteOrgLoading && deleteOrgResponse) {
      if (deleteOrgResponse?.deleteOrganization.id) {
        setOpenDeleteOrg(false);
        toast.success('Organization Deleted successfully!');
      } else toast.error('Something went wrong!');
    } else if (!deleteOrgLoading && deleteOrgError) toast.error(`Something went wrong !`);
  }, [deleteOrgError, deleteOrgLoading, deleteOrgResponse]);

  return {
    deleteOrgResponse,
    deleteOrgError,
    deleteOrgLoading,
    deleteOrg,
    openDeleteOrg,
    setOpenDeleteOrg
  };
}
