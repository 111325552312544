import React from 'react';
import PropTypes from 'prop-types';
import handleGoogleAnalytics from '../../utils/googleAnalytics';
import sprite from '../../assets/sprite.svg';
import './Footer.scss';
import { APP_ICONS, LINKS } from '../../constants';
import TEXT_CONTENT from '../../languages';

const Footer = ({ includeSocials }) => {
  const COPYRIGHT_TEXT =
    TEXT_CONTENT.FOOTER_COPYRIGHT_ONE +
    new Date().getFullYear() +
    TEXT_CONTENT.FOOTER_COPYRIGHT_TWO;
  return (
    <div className="footer__container footer">
      <div className="footer__left-section">
        <p className="footer__text">{COPYRIGHT_TEXT}</p>
        <p className="footer__text footer__text--italic">
          {TEXT_CONTENT.FOOTER_AUTHOR_SUBTEXT}
          <a className="footer__link" href={LINKS.AUTHOR}>
            {TEXT_CONTENT.FOOTER_AUTHOR_MAIN}
          </a>
        </p>
      </div>
      <div className="footer__right-section">
        {includeSocials === true && (
          <div className="footer__link footer__socials" data-testid="footer__socials">
            <a
              href={LINKS.FACEBOOK}
              className="footer__social-link "
              target="_blank"
              rel="noreferrer"
            >
              <span className="footer__icon__container__social" data-testid="icon">
                <svg className="footer__icon__social">
                  <title>{APP_ICONS.FACEBOOK}</title>
                  <use href={`${sprite}#${APP_ICONS.FACEBOOK}`} />
                </svg>
              </span>
            </a>
            <a
              href={LINKS.TWITTER}
              className="footer__link footer__social-link"
              target="_blank"
              rel="noreferrer"
            >
              <span className="footer__icon__container__social" data-testid="icon">
                <svg className="footer__icon__social">
                  <title>{APP_ICONS.TWITTER}</title>
                  <use href={`${sprite}#${APP_ICONS.TWITTER}`} />
                </svg>
              </span>
            </a>
            <a
              href={LINKS.LINKEDIN}
              className="footer__link footer__social-link"
              target="_blank"
              rel="noreferrer"
            >
              <span className="footer__icon__container__social" data-testid="icon">
                <svg className="footer__icon__social">
                  <title>{APP_ICONS.LINKEDIN}</title>
                  <use href={`${sprite}#${APP_ICONS.LINKEDIN}`} />
                </svg>
              </span>
            </a>
          </div>
        )}
        <div className="footer__terms">
          <a
            href={LINKS.TERMS}
            onClick={() => {
              handleGoogleAnalytics(
                {
                  event: 'v2_tandc'
                },
                'NO_TOKEN'
              );
            }}
            className="footer__link"
            target="_blank"
            rel="noreferrer"
          >
            <p className="footer__text">{TEXT_CONTENT.FOOTER_TERMS}</p>
          </a>
          <a
            href={LINKS.PRIVACY_POLICY}
            onClick={() => {
              handleGoogleAnalytics(
                {
                  event: 'v2_privacy_policy'
                },
                'NO_TOKEN'
              );
            }}
            className="footer__link"
            target="_blank"
            rel="noreferrer"
          >
            <p className="footer__text footer__text--spaced">
              {TEXT_CONTENT.FOOTER_PRIVACY_POLICY}
            </p>
          </a>
        </div>
        {!includeSocials === true && (
          <div className="footer__gap">
            <p>Gap</p>
          </div>
        )}
      </div>
    </div>
  );
};

Footer.defaultProps = {
  includeSocials: true
};

Footer.propTypes = {
  includeSocials: PropTypes.bool
};

export default Footer;
