import React, { useState } from 'react';
import PropTypes from 'prop-types';
// import { useMutation } from '@apollo/client';
// import { toast } from 'react-toastify';
import Toggle from 'react-toggle';
import TextField from '../TextField/TextField';
import Button from '../Button/Button';
import Dropdown from '../DropDown';
import IconButton from '../IconButton/IconButton';
// import useAPIError from '../../hooks/useAPIError';
import { APP_ICONS } from '../../constants';

const ConfigFormV1 = ({
  urlError,
  configName,
  setConfigName,
  configUrl,
  setConfigUrl,
  SPA,
  setSPA,
  rowData,
  setRowData,
  SPAErrorString,
  setSPAErrorString
  // setUrlError
  // handleFreshConfig
}) => {
  const [isTouched, setIsTouched] = useState(false);

  // const [error, setError] = useState([]);

  // const { addError } = useAPIError();

  const handleUrlChange = e => {
    setConfigUrl(e.target.value);
  };

  const handleConfigNameChange = e => {
    setConfigName(e.target.value);
  };

  const handleChange = (e, i) => {
    const { value, name } = e.target;

    const newState = [...rowData];
    newState[i] = {
      ...newState[i],
      [name]: value
    };

    setRowData(newState);
  };

  // const handleSubmitForm = e => {
  //   e.preventDefault();
  //   const configDetails = {
  //     url: configUrl,
  //     name: configName,
  //     isSPA: SPA,
  //     error_page_string: SPAErrorString,
  //     other: rowData
  //   };
  // };

  const renderRows = () => {
    const arr = [...rowData];

    return arr.map(({ id, inclorexcl, pattern }, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <div key={id + index} className="config_modal__row">
        <div className="config_modal__select">
          <Dropdown
            addClass="config_modal-dropdown config_modal-select"
            label="Include/ Exclude"
            selectedOption={{ label: inclorexcl, value: inclorexcl }}
            options={[
              { label: 'Include', value: 'Include' },
              { label: 'Exclude', value: 'Exclude' }
            ]}
            position="BOTTOM"
            onChangeHandler={e => {
              const temp = [...rowData];
              temp[index] = { ...temp[index], inclorexcl: e.value };
              setRowData([...temp]);
            }}
          />
        </div>
        <div className="config_modal__pattern">
          <TextField
            type="text"
            name="pattern"
            size="small"
            label="String/Text"
            placeholder="Enter string to match"
            value={pattern || ''}
            changeHandler={e => {
              handleChange(e, index);
            }}
            isDisabled={rowData[0].inclorexcl === ''}
          />
        </div>
        <div role="none" className="config_modal__icon--delete" value={`${id}OOG`}>
          <IconButton
            icon={APP_ICONS.DELETE_ICON}
            onClickHandler={() => {
              const idTemp = id;

              const temp = [...rowData];

              temp.splice(
                temp.findIndex(item => item.id === idTemp),
                1
              );

              if (temp.length === 0) {
                temp.push({ id: Math.random().toString(), inclorexcl: '', pattern: '' });
              }

              setRowData([...temp]);

              // GOOGLE ANALYTICS START

              // handleGoogleAnalytics({
              //   event: 'v2_del_settings_filter',
              //   request_id: id,
              //   config: configName
              // });

              // GOOGLE ANALYTICS END
            }}
          />
        </div>
      </div>
    ));
  };

  return (
    <>
      <div className="config_modal__inputs">
        <div
          style={{
            marginBottom: urlError.status && urlError.status ? '1rem' : '2rem'
          }}
        >
          <TextField
            type="text"
            label="Enter URL"
            size="small"
            placeholder="https://"
            value={configUrl}
            changeHandler={handleUrlChange}
            statusMessage={urlError.statusMessage}
            status={urlError.status}
            onTouch={() => {
              setIsTouched(true);
            }}
            isTouched={isTouched}
            maxLength={255}
            subLabel="Max: 255 characters"
          />
        </div>
        <TextField
          type="text"
          label="Configuration Name"
          size="small"
          placeholder="Enter the Configuration Name"
          value={configName}
          changeHandler={handleConfigNameChange}
          maxLength={32}
          subLabel="Max: 32 characters"
        />
      </div>
      <div className="config_modal__toggle_container">
        <div className="config_modal__toggle">
          <span className="config_modal__label">Is an SPA?</span>
          <Toggle
            defaultChecked={SPA}
            icons={false}
            onChange={e => {
              setSPA(e.target.checked);
            }}
          />
        </div>
        {SPA && (
          <div className="config_modal__toggle_input">
            <TextField
              type="text"
              size="small"
              placeholder="Enter the error string"
              value={SPAErrorString}
              changeHandler={e => {
                setSPAErrorString(e.target.value);
              }}
            />
          </div>
        )}
      </div>
      <div className="setting_header">Settings</div>
      <div
        className="config_modal_container"
        style={{
          // border: rowData.length ? '1px solid #E0E0E0' : 'none'
          border: '1px solid #E0E0E0'
        }}
      >
        <div
          className="config_modal__row_container"
          // style={{ display: rowData.length ? 'block' : 'none' }}
        >
          {renderRows()}
        </div>
        <div className="config_modal__buttons">
          <Button
            label="Add row"
            type="button"
            name="addRow"
            appearance="primary"
            customStyleClass="config_modal__button"
            clickHandler={() => {
              // GOOGLE ANALYTICS START

              // handleGoogleAnalytics({
              //   event: 'v2_new_settings_filter',
              //   request_id: dataSelected.id,
              //   config: configName
              // });

              // GOOGLE ANALYTICS END

              setRowData([
                ...rowData,
                { id: Math.random().toString(), pattern: '', inclorexcl: '' }
              ]);
            }}
          />
        </div>
      </div>
    </>
  );
};

ConfigFormV1.defaultProps = {
  urlError: ''
};

ConfigFormV1.propTypes = {
  urlError: PropTypes.string,
  configName: PropTypes.string.isRequired,
  setConfigName: PropTypes.func.isRequired,
  configUrl: PropTypes.string.isRequired,
  setConfigUrl: PropTypes.func.isRequired,
  SPA: PropTypes.bool.isRequired,
  setSPA: PropTypes.func.isRequired,
  rowData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      inclorexcl: PropTypes.string,
      pattern: PropTypes.string
    })
  ).isRequired,
  setRowData: PropTypes.func.isRequired,
  SPAErrorString: PropTypes.string.isRequired,
  setSPAErrorString: PropTypes.func.isRequired
};

export default ConfigFormV1;
