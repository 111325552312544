import { useLazyQuery } from '@apollo/client';
import { GET_EMAIL_USERS } from '../../graphql/queries/User';

export default function useEmailSearch() {
  const [
    fetchEmailSearch,
    {
      loading: fetchEmailSearchLoading,
      data: fetchEmailSearchResponse,
      error: fetchEmailSearchError
    }
  ] = useLazyQuery(GET_EMAIL_USERS, { fetchPolicy: 'network-only' });
  return {
    fetchEmailSearch,
    fetchEmailSearchResponse: fetchEmailSearchResponse?.fetchEmail,
    fetchEmailSearchError,
    fetchEmailSearchLoading
  };
}
