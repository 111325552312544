/*

This component is to be used to create normal buttons. It can be configured using the following props -

label - It should be a string that'll be used as the button text.
type - It can either of 'button' and 'submit'. The value 'reset' is not supported.
icon - Its optional and it can be any of the icons contained in the APP_ICONS constant.
appearance - It can be either of 'primary,'secondary' and 'tertiary, which should be specified through the BUTTON_APPEARANCE constant exported from this file.
isDisabled - Its optional and it is to disbale or enable the button.
clickHandler - It's optional and we should assign a function to it.

*/

import React from 'react';
import propTypes from 'prop-types';

import './Button.scss';
import sprite from '../../assets/sprite.svg';

const Button = ({
  label,
  type,
  name,
  icon,
  appearance,
  isDisabled,
  clickHandler,
  size,
  customStyleClass,
  loading
}) => {
  let buttonClass =
    isDisabled || loading ? `button--${appearance}-disabled` : `button--${appearance}`;
  buttonClass = size ? `${buttonClass} button--${size}` : buttonClass;
  buttonClass = customStyleClass ? `${buttonClass} ${customStyleClass}` : buttonClass;
  return (
    <button
      type={type === 'button' ? 'button' : 'submit'}
      className={`button ${buttonClass} relative`}
      name={name}
      disabled={isDisabled || loading}
      onClick={clickHandler}
    >
      {icon && (
        <span className="button__icon-box" data-testid="icon">
          <svg className="button__icon">
            <use href={`${sprite}#${icon}`} />
          </svg>
        </span>
      )}
      {label && <span className="button__text">{label}</span>}
      {loading && <div className="button__icon button--loading" />}
    </button>
  );
};

export const BUTTON_APPEARANCE = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  TERTIARY: 'tertiary'
};

export const BUTTON_SIZE = {
  NANO: 'nano',
  MICRO: 'micro',
  XSMALL: 'xsmall',
  SMALL: 'small',
  NORMAL: 'normal',
  MEDIUM: 'medium',
  LARGE: 'large'
};

Button.defaultProps = {
  label: '',
  icon: null,
  isDisabled: false,
  clickHandler: e => {},
  size: BUTTON_SIZE.NORMAL,
  appearance: BUTTON_APPEARANCE.PRIMARY,
  customStyleClass: null,
  loading: false
};

Button.propTypes = {
  label: propTypes.string,
  type: propTypes.oneOf(['button', 'submit']).isRequired,
  icon: propTypes.string,
  appearance: propTypes.string,
  size: propTypes.string,
  isDisabled: propTypes.bool,
  customStyleClass: propTypes.string,
  clickHandler: propTypes.func,
  name: propTypes.string.isRequired,
  loading: propTypes.bool
};

export default Button;
