import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import CREATE_WEBSITE from '../../graphql/mutations/Website';
// import { USER_DATA_V1 } from '../../graphql/queries/UserData';

export default function useCreateWebsite(disableToast = false, refetchQueries = '') {
  const [openCreateWebsite, setOpenCreateWebsite] = useState(false);
  const [createWebsite, { loading: createWebsiteLoading, data: createWebsiteData, error }] =
    useMutation(CREATE_WEBSITE, refetchQueries ? { refetchQueries: [...refetchQueries] } : {});

  useEffect(() => {
    if (!createWebsiteLoading && createWebsiteData) {
      if (createWebsiteData.createWebsite.id) {
        // setLoadingParam('');
        setOpenCreateWebsite(false);
        !disableToast && toast.success('Website created successfully!');
      } else !disableToast && toast.error('Something went wrong!');
    } else if (!createWebsiteLoading && error) {
      if (error.message.includes('invalid')) !disableToast && toast.error(error.message);
      else !disableToast && toast.error('Something went wrong!');
    }
  }, [createWebsiteData, createWebsiteLoading, disableToast, error, setOpenCreateWebsite]);
  return {
    createWebsite,
    openCreateWebsite,
    setOpenCreateWebsite,
    createWebsiteError: error,
    createWebsiteLoading,
    createWebsiteData
  };
}
