import { useMutation } from '@apollo/client';
import { BLOCK_USER } from '../../graphql/mutations/userRole';
import { GET_EMAIL_USERS, GET_ORG_USERS } from '../../graphql/queries/User';

export default function useBlockUser() {
  const [blockUser, { loading: blockUserLoading, data: blockUserData, error: blockUserError }] =
    useMutation(BLOCK_USER, { refetchQueries: [GET_ORG_USERS, GET_EMAIL_USERS] });

  return {
    blockUser,
    blockUserResponse: blockUserData?.blockUserRole,
    blockUserLoading,
    blockUserError
  };
}
