import { useLazyQuery } from '@apollo/client';
import { useState, useEffect } from 'react';
import { ORG_USER, SUPER_USER } from '../constants';
import { USER_DATA_V1 } from '../graphql/queries/UserData';
import useLogin from './useLogin';

export default function useUserInfo(workSpace = 1) {
  const [websiteOptions, setWebsiteOptions] = useState([{ value: '', link: '', label: '' }]);
  const [orgOptions, setOrgOptions] = useState([{ value: '', link: '', label: '' }]);
  const [userType, setUserType] = useState(null);
  const [selectedOrg, setSelectedOrg] = useState({});
  const { loginLoading, loginResponse } = useLogin();
  // eslint-disable-next-line no-unused-vars
  const [selectedOrgWebOptions, setSelectedOrgWebOptions] = useState([
    { value: '', link: '', label: '' }
  ]);
  // const [userWebsites, setUserWebsites] = useState([]);
  // console.log(selectedOrgWebOptions, websiteOptions, 'option');
  const [fetchGetUser, { loading: userInfoLoading, data: userInfo, error: userInfoError }] =
    useLazyQuery(USER_DATA_V1, { fetchPolicy: 'network-only' });

  useEffect(() => {
    if ((!loginLoading && loginResponse) || window.location.pathname === '/guest/dashboard') {
      fetchGetUser();
    }
  }, [fetchGetUser, loginLoading, loginResponse]);

  useEffect(() => {
    // console.log('userInfo Outer');
    if (!userInfoLoading && userInfo) {
      const { getUser = null } = userInfo;
      // console.log('userInfo ', workSpace);
      if (getUser) {
        if (workSpace === 1 && getUser?.website.length > 0) {
          const options = getUser.website
            .filter(({ organization }) => !organization)
            .map(({ name, url, id }) => ({
              label: `${name}`,
              link: url,
              value: id
            }));
          setWebsiteOptions(options || [{ value: '', link: '', label: '' }]);
        } else if (workSpace === 2) {
          const options = getUser?.website
            .filter(({ organization }) => organization)
            .map(({ name, url, id, organization, role }) => ({
              label: `${name} (${organization ? organization.name : 'Unnamed'})`,
              link: url,
              value: id,
              role: role?.id
            }));
          const orgs = getUser?.userOrganizations.map(({ id, name }, index) => ({
            label: name,
            value: id
            // link: ''
          }));
          // console.log(orgs, 'orgs');
          if (userType && (userType === SUPER_USER || userType === ORG_USER)) {
            setOrgOptions(orgs || [{ value: '', link: '', label: '' }]);
            setSelectedOrg(orgs ? orgs[0] : null);
          }
          setWebsiteOptions(options || [{ value: '', link: '', label: '' }]);
        } else {
          setWebsiteOptions([{ value: '', link: '', label: '' }]);
          // setSelectedOrg([]);
          setOrgOptions([{ value: '', link: '', label: '' }]);
        }
      }
    }
  }, [userInfo, userInfoLoading, userType, workSpace]);

  useEffect(() => {
    if (!userInfoLoading && userInfo) {
      if (userInfo?.getUser?.userType) setUserType(userInfo?.getUser.userType.userType);
    }
  }, [userInfo, userInfoLoading, selectedOrg]);

  useEffect(() => {
    if (
      !userInfoLoading &&
      userInfo &&
      workSpace === 2 &&
      (userType === SUPER_USER || userType === ORG_USER)
    ) {
      const { getUser = null } = userInfo;
      if (getUser && getUser?.website.length > 0) {
        const options = getUser.website
          ?.filter(({ organization }) => organization && organization.id === selectedOrg?.value)
          .map(({ name, url, id, organization }) => ({
            label: `${name} (${organization ? organization.name : 'Unnamed'})`,
            link: url,
            value: id
          }));

        setWebsiteOptions(options || [{ value: '', link: '', label: '' }]);
      }
    }
  }, [selectedOrg, userInfo, userInfoLoading, userType, workSpace]);

  return {
    userInfoLoading,
    userInfo: userInfo?.getUser,
    userInfoError,
    websiteOptions,
    orgOptions,
    userType,
    selectedOrg,
    selectedOrgWebOptions,
    setSelectedOrg,
    setWebsiteOptions
  };
}
