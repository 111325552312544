import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import { toast } from 'react-toastify';
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../../containers/Modal';
import './WelcomeModal.scss';
// import ConfigForm from '../Config/ConfigForm';
import Button from '../Button/Button';
import TextField from '../TextField/TextField';
import SvgLogo from '../icons/Logo';
import { isValidURL } from '../../utils/index';
import ConfigFormV1 from '../ConfigV1/ConfigFormV1';
import { useConfigForm, useCreateWebsite, useUpdateConfig } from '../../hooks';
import { USER_DATA_V1 } from '../../graphql/queries/UserData';

const WELCOME_MODAL_STEPS_NAMES = {
  WELCOME: 'welcome',
  ENTER_URL: 'enter_url',
  ENTER_WEBSITE_NAME: 'enter_website_name',
  DEFINE_CONFIGURATION: 'create_config'
};

const WELCOME_MODAL_STEPS = [
  {
    name: WELCOME_MODAL_STEPS_NAMES.WELCOME
  },
  {
    name: WELCOME_MODAL_STEPS_NAMES.ENTER_URL,
    title: 'Enter URL'
  },
  {
    name: WELCOME_MODAL_STEPS_NAMES.ENTER_WEBSITE_NAME,
    title: 'Enter WebSite Name'
  },
  {
    name: WELCOME_MODAL_STEPS_NAMES.DEFINE_CONFIGURATION,
    title: 'Create Configuration'
  }
];

const WelcomeModal = ({ show, setShow }) => {
  // const configFormRef = useRef();
  // eslint-disable-next-line no-unused-vars
  const [urlError, setUrlError] = useState({});
  const [dataSelected, setDataSelected] = useState({});
  const [currentStep, setCurrentStep] = useState(0);

  const {
    configName,
    configUrl,
    isSPA,
    rowData,
    errorString: SPAErrorString,
    setConfigName,
    setConfigUrl,
    setIsSPA,
    setRowData,
    setErrorString: setSPAErrorString
  } = useConfigForm();

  const { createWebsite, createWebsiteLoading, createWebsiteData } = useCreateWebsite(true);
  const {
    updateConfiguration,
    updatedConfigId,
    setUpdatedConfigId,
    updateConfigurationError,
    updateConfigurationLoading,
    updateConfigurationResponse
  } = useUpdateConfig([USER_DATA_V1]);

  const handleCreateWebsite = async () => {
    await createWebsite({
      variables: {
        website_name: dataSelected?.websiteName,
        url: dataSelected?.websiteUrl,
        org_id: '',
        createJob: false
      }
    });
    await setCurrentStep(step => step + 1);
  };

  function nextClickHandler() {
    if (currentStep === 2) {
      handleCreateWebsite();
    } else setCurrentStep(step => step + 1);
  }

  useEffect(() => {
    // console.log('createWebsite', createWebsiteLoading, createWebsiteData);
    if (!createWebsiteLoading && createWebsiteData) {
      if (createWebsiteData?.createWebsite) {
        // console.log('createWebsite', createWebsiteData);
        const { config_name: configNameTemp, id: configId } =
          createWebsiteData.createWebsite.request.request;
        setConfigName(configNameTemp);
        setUpdatedConfigId(configId);
      }
    }
  }, [createWebsiteData, createWebsiteLoading, setConfigName, setUpdatedConfigId]);

  useEffect(() => {
    if (!updateConfigurationLoading && updateConfigurationResponse) {
      if (updateConfigurationResponse?.UpdateConfig?.message) {
        toast.success('Configuration created successfully!');
        setShow(false);
      } else toast.error('Something went wrong!');
    } else if (!updateConfigurationLoading && updateConfigurationError)
      toast.error(`Something went wrong!`);
  }, [setShow, updateConfigurationError, updateConfigurationLoading, updateConfigurationResponse]);

  function getHeaderContent() {
    const name = getCurrentStepName();

    switch (name) {
      case WELCOME_MODAL_STEPS_NAMES.WELCOME:
        return null;
      default:
        return (
          <h2 className="step-number">
            0{currentStep}
            <span className="step-total">/0{WELCOME_MODAL_STEPS.length - 1}</span>
            <span className="step-title">{WELCOME_MODAL_STEPS[currentStep].title}</span>
          </h2>
        );
    }
  }

  const handleWebsiteUrlChange = e => {
    setDataSelected({ ...dataSelected, websiteUrl: e.target.value });
  };

  const handleWebsiteNameChange = e => {
    setDataSelected({ ...dataSelected, websiteName: e.target.value });
  };

  const handleRunClick = e => {
    const configDetails = {
      url: dataSelected?.websiteUrl,
      config_name: configName,
      isSPA,
      error_page_string: SPAErrorString,
      inclusion: rowData
        .map(item => {
          if (item.inclorexcl === 'Include') return item.pattern;
          return '';
        })
        .filter(item => item)
        .toString(),
      exclusion: rowData
        .map(item => {
          if (item.inclorexcl === 'Exclude') return item.pattern;
          return '';
        })
        .filter(item => item)
        .toString()
    };
    updateConfiguration({
      variables: { updateConfigId: updatedConfigId, config: configDetails, createJob: true }
    });
  };

  function getCurrentStepName() {
    return WELCOME_MODAL_STEPS[currentStep].name;
  }

  function getBodyContent() {
    const name = getCurrentStepName();

    switch (name) {
      case WELCOME_MODAL_STEPS_NAMES.WELCOME:
        return (
          <div className="welcome-container">
            <div>
              <SvgLogo customStyle="sc_header__logo__img" />
            </div>
            <div className="welcome-text">Welcome!</div>
            <div className="welcome-description">
              We&apos;re here to guide you through the process of <br />
              setting up your first URL scan
            </div>
            <Button
              label="Get Started"
              type="button"
              name="start"
              customStyleClass="config_modal__button"
              // eslint-disable-next-line react/jsx-no-bind
              clickHandler={nextClickHandler}
            />
          </div>
        );

      case WELCOME_MODAL_STEPS_NAMES.ENTER_URL:
        return (
          <div className="welcome-text-container">
            <TextField
              type="text"
              label="Please provide the URL you want to scan for broken links"
              size="small"
              placeholder="Enter URL"
              value={dataSelected.websiteUrl || ''}
              changeHandler={handleWebsiteUrlChange}
              statusMessage={urlError.statusMessage}
              status={urlError.status}
              maxLength={255}
              subLabel="Max: 255 characters"
            />
          </div>
        );

      case WELCOME_MODAL_STEPS_NAMES.ENTER_WEBSITE_NAME:
        return (
          <div className="welcome-text-container">
            <TextField
              type="text"
              label="Please provide a website name for your scan"
              size="small"
              placeholder="Enter the Website Name"
              value={dataSelected.websiteName || ''}
              changeHandler={handleWebsiteNameChange}
              maxLength={32}
              subLabel="Max: 32 characters"
            />
            {/* <div className="note_header">Note:</div>
            <div className="note_content">
              You can have multiple runs and configurations for the same link
            </div> */}
          </div>
        );

      case WELCOME_MODAL_STEPS_NAMES.DEFINE_CONFIGURATION:
        return (
          <>
            <div className="settings-header">
              Optionally add the strings you want to exclude/include from the URL’s to scan or
              choose to “<span className="setting-highlight">Run URL</span>”
            </div>
            <ConfigFormV1
              urlError=""
              configName={configName || dataSelected.websiteName}
              setConfigName={setConfigName}
              configUrl={configUrl || dataSelected.websiteUrl}
              setConfigUrl={setConfigUrl}
              SPA={isSPA}
              setSPA={setIsSPA}
              rowData={rowData}
              setRowData={setRowData}
              SPAErrorString={SPAErrorString}
              setSPAErrorString={setSPAErrorString}
            />
          </>
        );
      default:
        return null;
    }
  }

  function getFooterContent() {
    const name = getCurrentStepName();

    switch (name) {
      case WELCOME_MODAL_STEPS_NAMES.ENTER_URL:
        return (
          <Button
            label="Next >"
            type="button"
            name="next"
            customStyleClass="config_modal__button"
            isDisabled={dataSelected?.websiteUrl ? !isValidURL(dataSelected.websiteUrl) : true}
            // eslint-disable-next-line react/jsx-no-bind
            clickHandler={nextClickHandler}
          />
        );
      case WELCOME_MODAL_STEPS_NAMES.ENTER_WEBSITE_NAME:
        return (
          <Button
            label="Next >"
            type="button"
            name="next"
            customStyleClass="config_modal__button"
            isDisabled={!dataSelected?.websiteName}
            loading={createWebsiteLoading}
            // eslint-disable-next-line react/jsx-no-bind
            clickHandler={nextClickHandler}
          />
        );

      case WELCOME_MODAL_STEPS_NAMES.DEFINE_CONFIGURATION:
        return (
          <Button
            label="Run URL"
            name="run"
            isDisabled={urlError.status === 'error'}
            type="button"
            loading={updateConfigurationLoading}
            customStyleClass="config_modal__button"
            clickHandler={handleRunClick}
          />
        );

      default:
        return null;
    }
  }

  return (
    <Modal show={show} setShow={setShow} addClass="welcome_modal" closeOnClickOutside={false}>
      <ModalHeader>
        {currentStep > 0 && (
          <ModalHeader addClass="welcome_modal_header">
            <div>{getHeaderContent()}</div>
          </ModalHeader>
        )}
      </ModalHeader>
      <ModalBody>
        <div className="welcome_modal_body">{getBodyContent()}</div>
      </ModalBody>
      <ModalFooter>
        <div className="welcome_modal__buttons--footer">{getFooterContent()}</div>
      </ModalFooter>
    </Modal>
  );
};

WelcomeModal.defaultProps = {
  // triggerReload: () => {}
};
WelcomeModal.propTypes = {
  show: propTypes.bool.isRequired,
  setShow: propTypes.func.isRequired
  // type: propTypes.oneOf(['ADD', 'UPDATE']).isRequired,
  // newJob: propTypes.func.isRequired,
  // triggerReload: propTypes.func
};
export default WelcomeModal;
