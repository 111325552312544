import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import userTypeContext from '../../contexts/userTypeContext';
import ConfigV1 from '../../components/ConfigV1/ConfigV1';
import ActionHeaderV1 from '../../components/ActionHeader/ActionHeaderV1';
import SideNav from '../../components/SideNav/SideNav';
import '../Dashboard/Dashboard.scss';
import '../../containers/layout/Layout.scss';
import './History.scss';
import { useConfigDetails, useConfigJobInfo } from '../../hooks';
import Loading from '../../components/DetailsTab/Loading';
import SubHeader from '../../components/SubHeader/SubHeader';

const History = ({ isProtected }) => {
  const [jobOptions, setJobOptions] = useState([]);
  // const [currentSelectedJobInfo, setCurrentSelectedJobInfo] = useState();
  const [jobSummary, setJobSummary] = useState({});
  const [selectedJob, setSelectedJob] = useState('');
  const { authType, handleAuthType } = useContext(userTypeContext);
  const { id } = useParams();
  const {
    fetchConfigurationDetails,
    configurationDetailsLoading,
    configurationDetails,
    configurationDetailsError
  } = useConfigDetails();

  const { computeSummary } = useConfigJobInfo();

  useEffect(() => {
    if (!configurationDetailsLoading && !configurationDetails) {
      fetchConfigurationDetails({ variables: { id } });
    } else if (!configurationDetailsLoading && configurationDetailsError) {
      toast.error('Something went wrong !');
    }
  }, [
    configurationDetails,
    configurationDetailsError,
    configurationDetailsLoading,
    fetchConfigurationDetails,
    id
  ]);

  useEffect(() => {
    if (configurationDetails) {
      const options = configurationDetails?.jobs?.map(job => {
        const time = job.start_time?.split('T');
        const dt = time ? time[0] : 'Unknown';
        const tm = time ? time[1].split('.')[0] : 'Unknown';
        const name = `${dt} | ${job.status}`;
        return {
          link: name,
          label: tm,
          value: job.id
        };
      });
      setJobOptions(options);
      setSelectedJob(options[0]);
    }
  }, [configurationDetails]);

  const onSelectJobOption = input => {
    setSelectedJob(input);
  };
  // console.log(currentSelectedJobInfo, 'cr', jobSummary, selectedJob);
  useEffect(() => {
    if (selectedJob) {
      const current = configurationDetails?.jobs?.filter(job => job.id === selectedJob.value);
      if (current) {
        // setCurrentSelectedJobInfo(current[0]);
        const summary = computeSummary(current[0]);
        setJobSummary(summary);
      }
    }
  }, [computeSummary, configurationDetails?.jobs, selectedJob, setJobSummary]);

  useEffect(() => {
    if (isProtected && authType !== 'AUTHENTICATED') {
      handleAuthType();
    }
  }, [authType, handleAuthType, isProtected]);

  return (
    <div className="sc-page">
      <div className="sc-page__wrapper">
        <div className="sc-page__common">
          <div className="sc-page__container">
            <ActionHeaderV1 />
            <div className="page-container">
              <SideNav />
              {configurationDetails ? (
                <div className="dashboard">
                  <div>
                    <h1 className="flex-class center-align-title  border-bottom-none ">
                      <span className="padding-title padding-right-0 font-18rem">{`History of Configuration :   `}</span>
                      <span className="config__heading border-bottom-none padding-title padding-left-0 font-18rem">
                        {configurationDetails.config_name}
                      </span>
                    </h1>
                  </div>
                  <div className="flex-class">
                    <div className="configuration__dropdown ">
                      <ConfigV1
                        dropTopDownTitle="Historic Runs"
                        showDelete={false}
                        showEdit={false}
                        showBottomContent={false}
                        options={jobOptions}
                        selected={selectedJob}
                        onChangeHandler={onSelectJobOption}
                      />
                    </div>
                  </div>
                  {jobSummary && jobSummary.totalBroken != null && (
                    <div className="dashboard__protected padding-top-3">
                      <SubHeader
                        // disableOtherTabs={disableOtherTabs}
                        showDownload={false}
                        summaryData={jobSummary}
                        jobId={selectedJob.value}
                        showSettings={false}
                        // requestId={requestID}
                        firstDataReceived
                        scanStatus={false}
                      />
                    </div>
                  )}
                </div>
              ) : (
                <Loading />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

History.propTypes = {
  isProtected: PropTypes.bool.isRequired
};

export default History;
