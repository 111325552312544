import React from 'react';
import propTypes from 'prop-types';

import Modal, { ModalBody, ModalFooter, ModalHeader } from '../../containers/Modal';
import { APP_ICONS } from '../../constants';
import Button from '../Button/Button';

import sprite from '../../assets/sprite.svg';

const SettingsDeleteModal = ({ handleDelete, show, setShow }) => (
  <Modal show={show} setShow={setShow} addClass="settings_modal">
    <ModalHeader>
      <>
        <div className="settings_modal__header">
          <svg className="settings_modal__icon">
            <use href={`${sprite}#${APP_ICONS.DELETE_ICON_PRIMARY}`} />
          </svg>
          <h2>Delete Setting</h2>
        </div>
        <div className="divider" />
      </>
    </ModalHeader>
    <ModalBody>
      <div className="settings_modal__content">
        <div className="settings_modal__text">Are you sure you want to delete this setting?</div>
        <div className="settings_modal__subtext">You can&apos;t undo this action</div>
      </div>
    </ModalBody>
    <ModalFooter>
      <div className="settings_modal__buttons">
        <Button
          label="Cancel"
          name="cancel"
          type="button"
          appearance="secondary"
          customStyleClass="settings_modal__button"
          clickHandler={() => {
            setShow(false);
          }}
        />

        <Button
          label="Delete"
          name="delete"
          type="button"
          customStyleClass="settings_modal__button"
          clickHandler={handleDelete}
        />
      </div>
    </ModalFooter>
  </Modal>
  // </>
);
SettingsDeleteModal.defaultProps = {};
SettingsDeleteModal.propTypes = {
  handleDelete: propTypes.func.isRequired,
  show: propTypes.bool.isRequired,
  setShow: propTypes.func.isRequired
};
export default SettingsDeleteModal;
