import { gql } from '@apollo/client';

const FETCH_CONFIG_DETAILS = gql`
  query fetchConfiguration($id: String!) {
    request(id: $id) {
      id
      url
      last_active
      inclusion
      exclusion
      user_id
      jobs {
        id
        status
        start_time
        finish_time
        totalScanTime
        error_message
        summary {
          external
          internal
          totalCrawlTime
          totalBroken
          noPagesScanned
          noLinksChecked
          issueTypes {
            title
            count
          }
          linkTypes {
            title
            count
          }
          hosts {
            title
            count
          }
        }
        is_aborted
      }
      isSPA
      error_page_string
      website_id
      config_name
      active
    }
  }
`;

export default FETCH_CONFIG_DETAILS;
