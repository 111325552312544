/*

This component is used to create nav buttons for the side navigation panel on the left. It can be configured with the following props - 

degaultIcon - The APP_ICONS constants should be use to specify an icon to be used when the correspoding route for the nav button is not active. This should an outline version of can icon.
activeIcon - This icon will be used when the corresponding route is active. This should be the solid versoin of the same icon.
label - Its just the text for the button
to - It's a route to be forwarded to the <NavLink> component  

*/

import React from 'react';
import propTypes from 'prop-types';
import { NavLink, useLocation } from 'react-router-dom';

import './NavButton.scss';
import sprite from '../../assets/sprite.svg';

const NavButton = ({ defaultIcon, activeIcon, label, to, active }) => {
  const location = useLocation();

  return (
    <NavLink
      // onClick={e => e.preventDefault()}
      to={to}
      className={`nav-button ${active ? 'nav-button-active' : ''}`}
      // activeClassName="nav-button-active"
    >
      <span className="nav-button__icon-box">
        <svg className="nav-button__icon" data-testid="svg">
          {location.pathname === to && <use href={`${sprite}#${activeIcon}`} />}
          {location.pathname !== to && <use href={`${sprite}#${defaultIcon}`} />}
        </svg>
      </span>
      <span className="nav-button__label">{label}</span>
    </NavLink>
  );
};

NavButton.defaultProps = {
  active: false
};

NavButton.propTypes = {
  defaultIcon: propTypes.string.isRequired,
  activeIcon: propTypes.string.isRequired,
  label: propTypes.string.isRequired,
  to: propTypes.string.isRequired,
  active: propTypes.bool
};

export default NavButton;
