/*

This component can be used to create the list of options for a selct box or anything else. Following are the configurable props - 

options - An array of strings containg the options
multi - A boolean that contorls whether or not multiple options are selectable
isOpen - A boolean that controls whether the list is shown or hidden
onChangeHandler - A function to which all the selected options are passed whenever the selection is changed

*/

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './Select.scss';

const Select = ({
  options,
  multi,
  isOpen,
  onChangeHandler,
  showIcon,
  width,
  topContent,
  bottomContent,
  rowRightContent,
  customStyleClass,
  position,
  isToolTip
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {}, [isOpen, selectedOptions]);

  const optionSelectionHandler = (option, event) => {
    event.stopPropagation();

    if (multi) {
      setSelectedOptions(prevSelection => {
        const newSelection = [...prevSelection];
        const presentAtIndex = prevSelection.map(item => item.value).indexOf(option.value);
        // console.log('prevSelection', prevSelection);
        // console.log('OPTION', option);
        // console.log('presentAtIndex', presentAtIndex);
        if (presentAtIndex === -1) newSelection.push(option);
        else newSelection.splice(presentAtIndex, 1);

        onChangeHandler(newSelection);

        return newSelection;
      });
    } else {
      setSelectedOptions(() => {
        onChangeHandler(option);
        return [option];
      });
    }
  };

  if (options.length === 0) {
    options.push({ label: 'No Options provided', value: 'none' });
  }

  const openClass = isOpen ? 'select--open' : 'select--close';

  const activeClass = currentValue => {
    let temp = '';
    if (!multi) {
      temp =
        selectedOptions && selectedOptions[0] && selectedOptions[0].value === currentValue.value
          ? 'select--active'
          : '';
    } else {
      temp =
        selectedOptions &&
        selectedOptions.length > 0 &&
        selectedOptions.some(ele => currentValue.value === ele.value)
          ? 'select--active'
          : '';
    }
    return temp;
  };

  const multiClass = multi ? 'select__marker--checkbox' : 'select';
  const textClass = multi ? 'select__option-text select__option-text-multi' : 'select__option-text';
  const timeStamp = Date.now();

  let defaultClass = `select ${openClass} ${customStyleClass}`;
  if (position === 'BOTTOM') defaultClass += ' select--bottom';

  return (
    <div
      className={defaultClass}
      data-testid="select-container"
      role="none"
      style={{ width }}
      onClick={event => event.stopPropagation()}
    >
      <div className="flex-class config__border_bt">
        <div className="config__flex_1">{topContent}</div>
        <div className="config__flex_1">{bottomContent}</div>
      </div>
      {options[0].value !== 'none' && (
        <div className="select__options">
          {options.map((option, index) => (
            <div className={`select__option ${activeClass(option)}`} key={option.value}>
              <input
                type={multi ? 'checkbox' : 'radio'}
                className="select__input"
                id={option.value + timeStamp}
                name={!multi ? 'select' : undefined}
              />
              <label
                htmlFor={option.value + timeStamp}
                className="select__label"
                tabIndex={index}
                role="none"
                onClick={optionSelectionHandler.bind(null, option)}
                data-testid="select-option"
              >
                <span className={`select__marker ${multiClass}`} data-testid="select-indicator" />
                <span className={`${textClass}`}>
                  {isToolTip ? (
                    <>
                      <span className="select__tooltip">
                        <span className="select__tooltip-option-label">
                          {option.label ? option.label : null}
                        </span>
                        <span className="select__tooltiptext" style={{ left: '10rem' }}>
                          {option.label ? option.label : null}
                        </span>
                      </span>
                      <span className="select__tooltip">
                        <span className="select__tooltip-option-link">
                          {option.link ? option.link : null}
                        </span>
                        <span className="select__tooltiptext" style={{ left: '30rem' }}>
                          {option.link ? option.link : null}
                        </span>
                      </span>
                    </>
                  ) : (
                    <>
                      <span className="select__option-label">{option.label}</span>
                      <span className="select__option-link">
                        {option.link ? option.link : null}
                      </span>
                    </>
                  )}
                </span>
                {/* //{showIcon && { */}
                {/* </span> //   </svg> //     <use href={`${sprite}#${APP_ICONS.DELETE_ICON}`} /> //   <svg> // <span className="select__option-icon"> */}
              </label>
              <div data-key={option.value ? option.value : ''} style={{ width: '15%' }}>
                {rowRightContent}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

Select.defaultProps = {
  multi: false,
  isOpen: false,
  showIcon: true,
  width: '100%',
  topContent: <div style={{ display: 'none' }} />,
  bottomContent: <div style={{ display: 'none' }} />,
  rowRightContent: <div style={{ display: 'none' }} />,
  customStyleClass: '',
  position: 'TOP',
  isToolTip: false
};

Select.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })
  ).isRequired,
  multi: PropTypes.bool,
  isOpen: PropTypes.bool,
  onChangeHandler: PropTypes.func.isRequired,
  showIcon: PropTypes.bool,
  width: PropTypes.string,
  topContent: PropTypes.node,
  bottomContent: PropTypes.node,
  rowRightContent: PropTypes.node,
  customStyleClass: PropTypes.string,
  position: PropTypes.string,
  isToolTip: PropTypes.bool
};

export default Select;
