import React, { useState, useRef, useEffect, useContext } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate, useLocation } from 'react-router-dom';
import './Home.scss';
import { toast } from 'react-toastify';
import {
  setLocalStorageKey,
  getLocalStorageKey,
  createAxiosConnection,
  isValidURL
} from '../../utils/index';
import LoginPageLayout from '../../containers/layout/LoginPageLayout';
import Dashboard from '../../assets/dashboard.png';
import Footer from '../../components/Footer/Footer';
import Button, { BUTTON_SIZE } from '../../components/Button/Button';
import TextField from '../../components/TextField/TextField';
import TEXT_CONTENT from '../../languages';
// import CREATE_REQUEST from '../../graphql/mutations/CreateRequest';
import handleGoogleAnalytics from '../../utils/googleAnalytics';
import { APP_ICONS, LOCAL_KEYS, ERRORS } from '../../constants';
import { AuthContext } from '../../contexts/auth-context';
import { useCreateWebsite, useLogin } from '../../hooks/index';

const base64 = require('base-64');

let guestKey = getLocalStorageKey(LOCAL_KEYS.GUEST_TOKEN);
let guestId = getLocalStorageKey(LOCAL_KEYS.GUEST_ID);

const HomePage = () => {
  const location = useLocation();
  const recaptchaRef = useRef(null);
  const sitekey = process.env.REACT_APP_RECAPTCHA_KEY;
  const [url, setUrl] = useState('');
  const [error, setError] = useState({ status: '', statusMessage: '' });
  const [isTouched, setIsTouched] = useState(false);
  const [captchaToken, setCaptchaToken] = useState('');
  const { loginWithRedirect, user, isLoading } = useAuth0();
  const { loginLoading } = useLogin();
  const { createWebsite } = useCreateWebsite();
  // const { fetchUserType, fetchUserTypeResponse, fetchUserTypeLoading } = useGetUserType();
  const authContext = useContext(AuthContext);

  const navigate = useNavigate();

  const instance = createAxiosConnection(process.env.REACT_APP_API_ENDPOINT);
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (
      params.get('success') &&
      params.get('code') === TEXT_CONTENT.HOME_EMAIL_VERIFIED_SUCCESS &&
      params.get('message') === TEXT_CONTENT.HOME_EMAIL_VERIFIED_MESSAGE
    )
      loginWithRedirect();

    // DISABLED FOR RECREATING DIDMOUNT
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user && isLoading === false && !loginLoading) {
      navigate('/dashboard', {
        state: { userType: 'authenticated_user' }
      });
    }
  }, [isLoading, loginLoading, navigate, user]);

  useEffect(() => {
    guestId = getLocalStorageKey(LOCAL_KEYS.GUEST_ID);
    if (guestId) {
      /* ------RETURNING USER-----*/
      /*

        FLOW
        Redirect to dashboard on page load when coming to home page if uuid exists
        pass guest id to token, get jwt token back
        pass jwt token to query 'user', get requests array
        get item in array with last_active as true,
        take request id to latestJob
        populate with response

        */
      guestId = base64.decode(getLocalStorageKey(LOCAL_KEYS.GUEST_ID));

      instance
        .post('/token', {
          id: guestId
        })
        .then(response => {
          try {
            // console.log('tOKEN', response.data.token);
            guestKey = base64.encode(response.data.token).toString('Base64');
            setLocalStorageKey(LOCAL_KEYS.GUEST_TOKEN, guestKey);
            if (base64.decode(getLocalStorageKey(LOCAL_KEYS.GUEST_TOKEN)) === response.data.token) {
              navigate('/guest/dashboard', {
                state: { userType: 'returning_guest' }
              });
            }
          } catch (err) {
            /* --CONSOLE WILL BE REPLACED BY COMMON ERROR POPUP --*/
            /*eslint-disable*/
            console.log(`ERROR IN HOME PAGE FOR RETURNING GUEST ->${err}`);
          }
        })
        .catch(e => {
          throw e;
        });
    }
  }, []);

  useEffect(() => {
    if (isValidURL(url)) setError({ status: '', statusMessage: '' });
    else setError({ status: 'error', statusMessage: ERRORS.URL_VALIDATION_ERROR });
  }, [url]);

  const submitGuestUrl = () => {
    /* check if local storage has guest key stored */
    if (isValidURL(url)) {
      // console.log('guestId: ', guestId);
      // if (!guestId) {
      /* ------FIRST TIME USER-----*/
      handleGoogleAnalytics(
        {
          event: 'v2_guest_scan_url',
          scan_url: url
        },
        'NO_TOKEN'
      );
      instance
        .post('/token', {
          token: captchaToken
        })
        .then(response => {
          guestKey = base64.encode(response.data.token).toString('Base64');
          guestId = base64.encode(response.data.id).toString('Base64');
          // console.log(guestKey, guestId, "guest");
          setLocalStorageKey(LOCAL_KEYS.GUEST_TOKEN, guestKey);
          setLocalStorageKey(LOCAL_KEYS.GUEST_ID, guestId);
          createWebsite({
            variables: { url: url, website_name: 'Default_Guest1', createJob: false, org_id: '' }
          }).then(response => {
            try {
              navigate('/guest/dashboard', {
                state: {
                  requestID: response.data.createWebsite.request.request.id,
                  userType: 'new_guest'
                }
              });
            } catch (err) {
              /* --CONSOLE WILL BE REPLACED BY COMMON ERROR POPUP --*/
              /*eslint-disable*/
              toast.error('Something went wrong!');
              console.log(`ERROR IN RETREIVING REQUEST ID ->${err}`);
            }
          });
        })
        .catch(e => {
          toast.error('Something went wrong!');
          throw e;
        });
      // }
    }
  };

  return (
    <LoginPageLayout showLogin>
      <div className="home-page">
        <div className="home-page__content">
          <div className="home-page__left">
            <h1 className="home-page__heading"> {TEXT_CONTENT.HOME_HEADING} </h1>
            <p className="home-page__caption"> {TEXT_CONTENT.HOME_CAPTION} </p>
            <div className="home-page__textbox">
              <TextField
                placeholder={TEXT_CONTENT.LANDING_URL}
                icon={APP_ICONS.URL}
                changeHandler={e => {
                  setUrl(e.target.value);
                }}
                onTouch={() => {
                  setIsTouched(true);
                }}
                isTouched={isTouched}
                type="text"
                value={url}
                statusMessage={error.statusMessage}
                status={error.status}
              />
            </div>

            <ReCAPTCHA
              className="home-page__recaptcha"
              data-testid="recaptcha"
              ref={recaptchaRef}
              sitekey={sitekey}
              onChange={val => {
                setCaptchaToken(val);
              }}
            />

            <Button
              type="submit"
              name="submit"
              size={BUTTON_SIZE.MEDIUM}
              label={TEXT_CONTENT.HOME_SCAN_BUTTON}
              clickHandler={() => {
                submitGuestUrl();
                if (captchaToken) authContext.onLogin();
              }}
            />
          </div>
          <div className="home-page__right">
            <img src={Dashboard} className="home-page__right-img" alt="Site checker dashboard" />
          </div>
        </div>
        <Footer includeSocials={false} />
      </div>
    </LoginPageLayout>
  );
};

export default HomePage;
