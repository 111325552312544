import { useMutation } from '@apollo/client';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { UPDATE_ORG } from '../../graphql/mutations/organization';
import { USER_DATA_V1 } from '../../graphql/queries/UserData';
import { USER_ERROR_MESSAGE } from '../../constants';

export default function useUpdateOrg() {
  const [openEditOrg, setOpenEditOrg] = useState(false);
  const [updateOrg, { loading: updateOrgLoading, data: updateOrgResponse, error: updateOrgError }] =
    useMutation(UPDATE_ORG, { refetchQueries: [USER_DATA_V1] });

  useEffect(() => {
    if (!updateOrgLoading && updateOrgResponse) {
      if (updateOrgResponse.updateOrganization.id) {
        toast.success('Organization updated successfully !');
        setOpenEditOrg(false);
      } else toast.error('Something went wrong !');
    } else if (!updateOrgLoading && updateOrgError) {
      if (updateOrgError.message.includes('Validation')) toast.error(USER_ERROR_MESSAGE.ORG_EXIST);
      else toast.error('Something went wrong!');
    }
  }, [updateOrgError, updateOrgLoading, updateOrgResponse]);

  return {
    updateOrg,
    updateOrgLoading,
    updateOrgError,
    updateOrgResponse,
    openEditOrg,
    setOpenEditOrg
  };
}
