import React from 'react';
import propTypes from 'prop-types';

const RowLeft = ({ children }) => <span>{children}</span>;

RowLeft.propTypes = {
  children: propTypes.element.isRequired
};

export default RowLeft;
