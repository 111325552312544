import React from 'react';
import propTypes from 'prop-types';
import RowLeft from './TableRowLeft';
import RowRight from './TableRowRight';

const TableWidgetRow = ({ data, type }) => (
  <ul style={{ height: '38rem', overflowY: 'auto', overflowX: 'hidden' }}>
    {data &&
      data.list &&
      data.list.map((entry, id) => {
        const temp = entry;
        const key = Math.random() * id;

        temp.percent = (entry.count / data.total) * 100;
        return (
          <li className="widget__row" key={key}>
            <div className="widget__row-left-entry" style={{ color: '#333333' }}>
              <RowLeft>
                <p>{temp.title}</p>
              </RowLeft>
            </div>
            <div
              className="widget__row-right-entry"
              style={{ color: '#828282', marginRight: '3rem' }}
            >
              <RowRight data={temp} type={type} />
            </div>
          </li>
        );
      })}
  </ul>
);

TableWidgetRow.propTypes = {
  data: propTypes.shape({
    list: propTypes.arrayOf(propTypes.shape({ title: propTypes.string, count: propTypes.number })),
    total: propTypes.number
  }),
  type: propTypes.string
};

TableWidgetRow.defaultProps = {
  type: 'primary',
  data: {}
};

export default TableWidgetRow;
