import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import './emailConfirmation.scss';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import EmailResendModal from './emailResendModal';
import { createAxiosConnection } from '../../utils/index';
import confirmationImage from '../../assets/confirmation_email.png';
import sprite from '../../assets/sprite.svg';
import TEXT_CONTENT from '../../languages';
import { APP_ICONS } from '../../constants';
import 'react-toastify/dist/ReactToastify.css';

const ConfirmationPage = () => {
  const location = useLocation();
  const customId = 'custom-id-email-verification';
  const instance = createAxiosConnection(process.env.REACT_APP_API_ENDPOINT);
  const id = location.state.mail.split('&usub=')[1];
  const { logout } = useAuth0();
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const notify = (type, text) => {
    setLoading(false);
    if (type === 'SUCCEEDED')
      toast.success(text || 'Success!', {
        position: toast.POSITION.BOTTOM_RIGHT,
        toastId: customId
      });
    else
      toast.error(text || 'Failure', {
        position: toast.POSITION.BOTTOM_RIGHT,
        toastId: customId
      });
  };

  const handleChangeMail = () => {
    logout({ returnTo: window.location.origin });
  };

  const handleResendMail = () => {
    setLoading(true);
    instance
      .post('/send-verification-email', {
        id
      })
      .then(response => {
        try {
          if (response && response.data && response.data.message === 'Success')
            notify('SUCCEEDED', TEXT_CONTENT.RESEND_MAIL_SUCCESS);
        } catch (err) {
          /*eslint-disable*/
          console.log(`ERROR IN RESENDING VERIFICATION MAIL ->${err}`);
        }
      })
      .catch(e => {
        if ({ ...e }.response.status === 409) notify('FAILED', TEXT_CONTENT.RESEND_MAIL_FAILURE);

        setLoading(false);
        throw e;
      });
  };

  return (
    <div className="confirmation">
      <EmailResendModal show={show} setShow={setShow} handleChangeMail={handleChangeMail} />
      <div className="confirmation__card">
        <div className="confirmation__content">
          <div className="confirmation__image">
            <img
              src={confirmationImage}
              alt="confirmation email"
              className="confirmation__image--email"
            />
          </div>
          <div className="confirmation__text">
            <h3 className="confirmation__heading">{TEXT_CONTENT.CONFIRMATION_HEADING}</h3>
            <div className="confirmation__subtext">
              {TEXT_CONTENT.CONFIRMATION_SUBTEXT_ONE}

              <span className="confirmation__subtext--bold" aria-label="confirmation__email">
                {location.state.mail ? location.state.mail.split('&usub=')[0] : ''}
              </span>

              <br />
              {TEXT_CONTENT.CONFIRMATION_SUBTEXT_TWO}
            </div>
          </div>
        </div>
      </div>
      <div className="confirmation__footer-text-container">
        <button
          type="button"
          disabled={false}
          className="confirmation__button"
          onClick={() => {
            setShow(true);
          }}
        >
          <span className="confirmation__button-text">{TEXT_CONTENT.INCORRECT_EMAIL}</span>
        </button>
      </div>
      <div className="confirmation__footer-text-container">
        <p>
          {TEXT_CONTENT.CONFIRMATION_ISSUE_ONE}
          <button
            type="button"
            disabled={loading}
            className="confirmation__button"
            onClick={handleResendMail}
          >
            <span className="confirmation__button-text confirmation__button-text--ml-5">
              {TEXT_CONTENT.CONFIRMATION_ISSUE_TWO}
            </span>

            <span className="confirmation__icon__container" data-testid="icon">
              <svg className="confirmation__icon">
                <title>{APP_ICONS.RELOAD}</title>
                <use href={`${sprite}#${APP_ICONS.RELOAD}`} />
              </svg>
            </span>
          </button>
        </p>
      </div>
    </div>
  );
};

export default ConfirmationPage;
