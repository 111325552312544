import React from 'react';
import PropTypes from 'prop-types';
// import { toast } from 'react-toastify';
// import Toggle from 'react-toggle';
import Dropdown from '../DropDown';
// import IconButton from '../IconButton/IconButton';
// import { APP_ICONS, ERRORS } from '../../constants';
import TextField from '../TextField/TextField';
// import Button from '../Button/Button';
import 'react-toggle/style.css';
import '../Config/Config.scss';

const WebsiteForm = ({
  showOrgs,
  showUrl,
  websiteName,
  websiteUrl,
  websiteOrg,
  orgList,
  setWebsiteName,
  setWebsiteUrl,
  setWebsiteOrg
}) => {
  const handleWebsiteNameChange = e => {
    setWebsiteName(e.target.value);
  };

  const handleWebsiteUrlChange = e => {
    setWebsiteUrl(e.target.value);
  };

  const handleWebsiteOrgChange = input => {
    setWebsiteOrg(input);
  };

  return (
    <div className="config_modal__inputs">
      {showUrl && (
        <div
          style={{
            marginBottom: '2rem'
          }}
        >
          <TextField
            type="text"
            label="Enter URL"
            size="small"
            placeholder="https://"
            value={websiteUrl}
            changeHandler={handleWebsiteUrlChange}
            // statusMessage={urlError.statusMessage}
            // status={urlError.status}
            // onTouch={() => {
            //   setIsTouched(true);
            // }}
            // isTouched={isTouched}
            maxLength={255}
            subLabel="Max: 255 characters"
          />
        </div>
      )}

      <div
        style={{
          marginBottom: '2rem'
        }}
      >
        <TextField
          type="text"
          label="Website Name"
          size="small"
          placeholder="Enter the Name for Website"
          value={websiteName}
          changeHandler={handleWebsiteNameChange}
          maxLength={32}
          subLabel="Max: 32 characters"
        />
      </div>

      {showOrgs && (
        <div
          style={{
            marginBottom: '2rem'
          }}
        >
          <div
            style={{
              marginBottom: '2rem'
            }}
            className="input__label"
          >
            Organization Name
          </div>
          <Dropdown
            color="rgba(224, 224, 224,0.4)"
            addClass="web-form__org_dropdown"
            selectedOption={websiteOrg}
            onChangeHandler={handleWebsiteOrgChange}
            options={orgList}
          />
        </div>
      )}
    </div>
  );
};

WebsiteForm.defaultProps = {
  showOrgs: false,
  showUrl: true,
  websiteName: '',
  websiteUrl: '',
  websiteOrg: { label: '', value: '' },
  orgList: [],
  setWebsiteUrl: e => {},
  setWebsiteOrg: e => {}
};

WebsiteForm.propTypes = {
  // isOpen: PropTypes.bool.isRequired
  showOrgs: PropTypes.bool,
  showUrl: PropTypes.bool,
  websiteName: PropTypes.string,
  websiteUrl: PropTypes.string,
  websiteOrg: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  }),
  setWebsiteName: PropTypes.func.isRequired,
  setWebsiteUrl: PropTypes.func,
  setWebsiteOrg: PropTypes.func,
  orgList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })
  )
};

export default WebsiteForm;
