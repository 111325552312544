/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTable, usePagination } from 'react-table';
import Pagination from 'rc-pagination';
// import MOCK_DATA from './MOCK_DATA.json';
import { toast } from 'react-toastify';
import { COLUMNS } from './columns';
import './user.scss';
import sprite from '../../assets/sprite.svg';
import { APP_ICONS } from '../../constants';
import UserCreate from './userCreate';
import DropDown from '../../components/DropDown';
import '../../components/DetailsTab/DetailsTab.scss';
import Button from '../../components/Button/Button';
import ConfigModal from '../../components/Config/ConfigModal';
import DeleteConfigModal from '../../components/DeleteConfigModal/DeleteConfigModal';
import {
  useCreateUserRole,
  useRemoveUser,
  useUpdateRole,
  useUserOrgs,
  useBlockUser
} from '../../hooks';

import UserRoleForm from '../../components/ConfigV1/UserRoleForm';

const UserTableContent = ({ tableData, searchText, filterType }) => {
  const columns = useMemo(() => COLUMNS, []);
  const pageCountOptions = useMemo(
    () => [
      { label: '10', value: '10' },
      { label: '15', value: '15' },
      { label: '20', value: '20' },
      { label: '25', value: '25' }
    ],
    []
  );

  const [limitLocal, setLimitLocal] = useState({ label: `10`, value: `10` });
  const [offsetLocal, setOffsetLocal] = useState(0);
  const [currentTableData, setCurrentTableData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [deleteUserTitle, setDeleteUserTitle] = useState('');
  const [selectUser, setSelectUser] = useState(null);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [openUserRoleModel, setOpenUserRoleModel] = useState(false);
  // options
  const [orgOptions, setOrgOptions] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedWeb, setSelectedWeb] = useState(null);
  const [webOptions, setWebOptions] = useState([]);

  const { removeUserRole, removeUserData, removeUserLoading, removeUserError } = useRemoveUser();
  const { blockUser, blockUserResponse, blockUserLoading, blockUserError } = useBlockUser();
  const { fetchUserOrgs, fetchUserOrgsLoading, fetchUserOrgsResponse } = useUserOrgs();
  const { createUserRole, createUserRoleLoading, createUserRoleResponse, createUserRoleError } =
    useCreateUserRole();
  const { updateUserRole, updateUserRoleLoading, updateUserRoleResponse, updateUserRoleError } =
    useUpdateRole();

  // console.log(selectUser, 'su');
  const data = useMemo(
    () =>
      tableData?.map(
        ({ id, is_blocked: isBlocked, userDetails, orgDetails, roleDetails, webDetails }) => ({
          id,
          name: userDetails?.first_name,
          email: userDetails?.email,
          organization: orgDetails ? orgDetails?.name : ' - ',
          role: roleDetails ? roleDetails?.name : ' - ',
          website: `${webDetails ? `${webDetails?.url}  ( ${webDetails.name} )` : '-'} `,
          action: '',
          is_blocked: isBlocked
        })
      ),
    [tableData]
  );

  const roleOptions = useMemo(
    () => [
      { label: 'Org Admin', value: '2' },
      { label: 'Web Admin', value: '3' },
      { label: 'User Read', value: '4' },
      { label: 'User Write', value: '5' }
    ],
    []
  );

  const calculateOffset = pageNum => (pageNum - 1) * limitLocal.value;

  const onOptionChange = (e, options, setSelected) => {
    const selectedOption = options.filter(x => x.value === e.value);
    if (selectedOption.length > 0) setSelected(selectedOption[0]);
  };

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow, setPageSize } =
    useTable(
      {
        columns,
        data: currentTableData,
        manualPagination: true
      },
      usePagination
    );

  const handleIsOpen = val => {
    // console.log(isOpen, val === selectedId, 'act', selectedId);
    const { id } = val;
    setSelectUser(val);
    setSelectedId(id);
    if (id === selectedId) setIsOpen(prev => !prev);
    else setIsOpen(true);
  };

  const handleRemoveAction = val => {
    setDeleteUserTitle(val);
    setIsOpen(false);
    setOpenDeleteModel(true);
  };

  const handleOpenModifyModel = () => {
    setOpenUserRoleModel(true);
    setIsOpen(false);
  };

  useEffect(() => {
    if (!removeUserLoading && removeUserData) {
      if (removeUserError || !removeUserData.success) toast.error('Something went wrong !');
      else {
        setOpenDeleteModel(false);
        toast.success(removeUserData?.message);
      }
    }
  }, [removeUserData, removeUserError, removeUserLoading]);

  useEffect(() => {
    fetchUserOrgs();
    // console.log('fetchUserOrgs');
  }, [fetchUserOrgs]);

  useEffect(() => {
    if (!fetchUserOrgsLoading && fetchUserOrgsResponse) {
      const tmp = fetchUserOrgsResponse?.map(({ id, name }) => ({ value: id, label: name }));
      setOrgOptions(tmp);
      setSelectedOrg(tmp[0]);
    }
  }, [fetchUserOrgsLoading, fetchUserOrgsResponse]);

  useEffect(() => {
    if (!blockUserLoading && blockUserResponse) {
      if (blockUserError || !blockUserResponse.success) toast.error('Something went wrong !');
      else {
        setOpenDeleteModel(false);
        toast.success(blockUserResponse?.message);
      }
    }
  }, [blockUserError, blockUserLoading, blockUserResponse]);

  const handleDeleteModelSubmit = () => {
    if (deleteUserTitle === 'Remove User') {
      removeUserRole({ variables: { id: selectedId } });
    } else {
      blockUser({ variables: { id: selectedId } });
    }
  };

  const handleUpdateRoleSubmit = val => {
    if (val === 'create') {
      createUserRole({
        variables: {
          user_id: selectedId,
          org_id: selectedOrg.value,
          role_id: selectedRole.value,
          website_id: selectedWeb?.value
        }
      });
    } else {
      updateUserRole({
        variables: {
          id: selectedId,
          org_id: selectedOrg.value,
          role_id: selectedRole.value,
          website_id: selectedWeb?.value
        }
      });
    }
  };

  useEffect(() => {
    const userOrg = orgOptions?.filter(({ label }) => label === selectUser?.organization);
    // console.log(userOrg, 'uO');
    userOrg.length > 0 ? setSelectedOrg(userOrg[0]) : setSelectedOrg(null);
  }, [orgOptions, selectUser?.organization]);

  useEffect(() => {
    const userRole = roleOptions?.filter(({ label }) => label === selectUser?.role);
    // console.log(userRole, 'uR');
    userRole.length > 0 ? setSelectedRole(userRole[0]) : setSelectedRole(null);
  }, [roleOptions, selectUser?.role]);

  useEffect(() => {
    const userWeb = webOptions?.filter(({ label }) => label === selectUser?.website);
    // console.log(userRole, 'uR');
    userWeb.length > 0 ? setSelectedWeb(userWeb[0]) : setSelectedWeb(null);
  }, [selectUser?.website, webOptions]);

  useEffect(() => {
    if (!updateUserRoleLoading && updateUserRoleResponse) {
      if (updateUserRoleResponse.success) {
        setOpenUserRoleModel(false);
        toast.success(updateUserRoleResponse.message);
      } else toast.error(updateUserRoleResponse.message);
    } else if (!updateUserRoleLoading && updateUserRoleError) toast.error('Something went wrong!');
  }, [updateUserRoleError, updateUserRoleLoading, updateUserRoleResponse]);

  useEffect(() => {
    if (!createUserRoleLoading && createUserRoleResponse) {
      if (createUserRoleResponse.id) {
        setOpenUserRoleModel(false);
        toast.success('Role Assigned successfully!');
      } else toast.error('Failed to Assign Role !');
    } else if (!createUserRoleLoading && createUserRoleError) toast.error('Something went wrong!');
  }, [createUserRoleError, createUserRoleLoading, createUserRoleResponse]);

  useEffect(() => {
    if (!fetchUserOrgsLoading && fetchUserOrgsResponse && selectedOrg) {
      const webOp = fetchUserOrgsResponse?.filter(({ id }) => id === selectedOrg?.value);
      if (webOp && webOp?.length > 0) {
        const tmp = webOp[0]?.webList?.map(({ url, name, id }) => ({
          label: `${url}  ( ${name} ) `,
          value: id
        }));
        setWebOptions(tmp);
      }
    }
  }, [fetchUserOrgsLoading, fetchUserOrgsResponse, selectedOrg]);

  useEffect(() => {
    const current = data.slice(+offsetLocal, +offsetLocal + parseInt(limitLocal.value, 10));
    setCurrentTableData(current);
  }, [data, limitLocal.value, offsetLocal]);

  useEffect(() => {
    setPageSize(limitLocal.value);
  }, [limitLocal.value, setPageSize]);

  // eslint-disable-next-line no-unused-vars
  // const { pageIndex, pagesize } = state;
  // console.log(pageIndex, pagesize, 'pt');
  return (
    <>
      <div className="user__table">
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                ))}
              </tr>
            ))}
          </thead>
          {/* {console.log(selectUser, selectedId, 'user s')} */}
          <tbody {...getTableBodyProps()}>
            {page.map(row => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    if (cell.column.Header === 'Action') {
                      const { id, is_blocked: isBlocked, organization } = cell.row.original;
                      return (
                        <td {...cell.getCellProps()} className="user__actions">
                          <Button
                            icon="three-dot"
                            label=""
                            type="button"
                            name={id}
                            // eslint-disable-next-line react/jsx-no-bind
                            clickHandler={handleIsOpen.bind(null, cell.row.original)}
                            appearance="secondary"
                            size="nano"
                            customStyleClass="user__table__click"
                          />
                          {isOpen && id.toString() === selectedId && (
                            <div className={`${`user__actions__prompt`} `}>
                              <div
                                className="user__actions__options_pop"
                                onClick={handleOpenModifyModel}
                              >
                                {organization === ' - ' ? `Assign Role` : `Modify Role`}
                              </div>
                              {organization !== ' - ' && (
                                <>
                                  <div
                                    className="user__actions__options_pop"
                                    onClick={handleRemoveAction.bind(null, 'Remove User')}
                                  >
                                    Remove
                                  </div>
                                  <div
                                    className="user__actions__options_pop"
                                    onClick={handleRemoveAction.bind(
                                      null,
                                      isBlocked ? 'Unblock User' : 'Block User'
                                    )}
                                  >
                                    {isBlocked ? 'Unblock' : 'Block '}
                                  </div>
                                </>
                              )}
                            </div>
                          )}
                        </td>
                      );
                    }
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                  })}
                </tr>
              );
            })}
            {tableData.length < 1 && filterType && (
              <tr>
                <td colSpan={6}>
                  <div className="user__not_found">
                    <div className="user__not_found_wrapper">
                      <h1>
                        {`${filterType} not found !, ${
                          filterType === 'Email'
                            ? `please click the 'Invite' button below to send an invitation.`
                            : ''
                        }`}
                      </h1>
                      {filterType === 'Email' && <UserCreate showTitle={false} />}
                    </div>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>

        <DeleteConfigModal
          show={openDeleteModel}
          setShow={setOpenDeleteModel}
          handleDeleteConfig={handleDeleteModelSubmit}
          loading={removeUserLoading || blockUserLoading}
          configName={selectUser?.name}
          deleteTitle={deleteUserTitle}
          deleteBodyMsg={`Are you sure you want to ${deleteUserTitle.toLowerCase()}`}
        />
        {openUserRoleModel && (
          <ConfigModal
            saveButtonText="cancel"
            saveAndRunButtonText="Submit"
            isOpen={openUserRoleModel}
            addClass="height-max"
            modelBodyClass="height-max"
            configTitle="Update Role"
            setShow={setOpenUserRoleModel}
            handleSaveButtonClick={() => setOpenUserRoleModel(false)}
            // eslint-disable-next-line react/jsx-no-bind
            handleSaveAndRunButtonClick={handleUpdateRoleSubmit.bind(
              null,
              selectUser?.organization !== ' - ' ? 'update' : 'create'
            )}
            saveAndRunLoading={updateUserRoleLoading || createUserRoleLoading}
          >
            <UserRoleForm
              name={selectUser?.name}
              email={selectUser?.email}
              orgOptions={orgOptions}
              webOptions={webOptions}
              roleOptions={roleOptions}
              selectedRole={selectedRole}
              selectedOrg={selectedOrg}
              selectedWeb={selectedWeb}
              setSelectedOrg={setSelectedOrg}
              setSelectedRole={setSelectedRole}
              setSelectedWeb={setSelectedWeb}
            />
          </ConfigModal>
        )}
      </div>
      {tableData.length > 0 && (
        <div className="user details__footer">
          <div className="details__footer__child">
            <p className="details__text-primary">Results per page:</p>
            <div>
              <DropDown
                addClass="details_page_count"
                selectedOption={limitLocal}
                options={pageCountOptions}
                onChangeHandler={e => onOptionChange(e, pageCountOptions, setLimitLocal)}
              />
            </div>
          </div>
          <div className="details__footer__child ">
            <Pagination
              current={Math.floor(parseInt(offsetLocal, 10) / parseInt(limitLocal.value, 10)) + 1}
              total={tableData.length}
              pageSizeOptions={[10, 15, 20, 25]}
              pageSize={limitLocal.value}
              onChange={num => {
                const newOffset = calculateOffset(num);
                if (newOffset >= 0) setOffsetLocal(String(newOffset));
              }}
              prevIcon={
                <div className="details__icon-container">
                  <svg className=" details__icon">
                    <use href={`${sprite}#${APP_ICONS.LEFT_ARROW}`} />
                  </svg>
                </div>
              }
              nextIcon={
                <div className="details__icon-container">
                  <svg className=" details__icon">
                    <use href={`${sprite}#${APP_ICONS.RIGHT_ARROW}`} />
                  </svg>
                </div>
              }
            />
          </div>
        </div>
      )}
    </>
  );
};

UserTableContent.defaultProps = {
  searchText: '',
  filterType: ''
};

UserTableContent.propTypes = {
  tableData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string
    })
  ).isRequired,
  searchText: PropTypes.string,
  filterType: PropTypes.string
};

export default UserTableContent;
