import { gql } from '@apollo/client';

const FETCH_SELECTED_JOB_DETAILS = gql`
  query fetchSelectedJobDetails($request_id: String!) {
    fetchSelectedJob(request_id: $request_id) {
      id
      status
      start_time
      finish_time
      totalScanTime
      error_message
      summary {
        external
        internal
        totalCrawlTime
        totalBroken
        noPagesScanned
        noLinksChecked
        issueTypes {
          title
          count
        }
        linkTypes {
          title
          count
        }
        hosts {
          title
          count
        }
      }
      is_aborted
    }
  }
`;

export default FETCH_SELECTED_JOB_DETAILS;
