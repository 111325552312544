import { gql } from '@apollo/client';

const GET_EXCELREPORT = gql`
  query ExcelReport($jobId: String!) {
    excelReport(job_id: $jobId) {
      download_link
      queryStatus {
        message
      }
    }
  }
`;
export default GET_EXCELREPORT;
