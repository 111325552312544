/*

This is the Action Header component to be used in the results page. It has only two configurable prop - 

domain - The domain name that was crawled
scanStatus - status of the crawl - RUNNING, FINISHED
*/

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// import Config from '../Config';

import Button, { BUTTON_APPEARANCE, BUTTON_SIZE } from '../Button/Button';
import AbortModal from '../AbortModal/AbortModal';
import handleGoogleAnalytics from '../../utils/googleAnalytics';
import { APP_ICONS } from '../../constants';
import './ActionHeader.scss';
import sprite from '../../assets/sprite.svg';
// import FETCH_USER_WEBSITES from '../../graphql/queries/fetchUserWebSite';
import ConfigV1 from '../ConfigV1/ConfigV1';

const ActionHeader = ({
  scanStatus,
  isAborted,
  requestId,
  newJob,
  stopJob,
  refreshData,
  showButton,
  websiteOptions,
  selectedWebsite,
  onSelectWebsiteOption
}) => {
  // eslint-disable-next-line
  const [requestIdLocal, setRequestId] = useState(requestId);
  const [isSmallWidth, setIsSamllWidth] = useState(false);
  const [showAbortModal, setShowAbortModal] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    if (scanStatus === 'INITIALISING' || scanStatus === 'STARTING' || scanStatus === 'RUNNING') {
      setIsDisabled(false);
    }
  }, [scanStatus]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      const bodyElement = entries[0];

      if (bodyElement.contentRect.width <= 574) setIsSamllWidth(true);
      else setIsSamllWidth(false);
    });

    resizeObserver.observe(document.documentElement);

    return () => resizeObserver.disconnect();
  }, []);

  const makeFirstLetterUppercase = str => {
    const temp = str.toLowerCase();
    return temp.charAt(0).toUpperCase() + temp.slice(1);
  };

  const handleAbort = () => {
    stopJob();
    setShowAbortModal(false);
  };

  // let rightAction;

  // if (scanStatus !== null && scanStatus.length >= 0) {
  //   rightAction = (
  //     <>
  //       {!(scanStatus === 'SUCCEEDED' || scanStatus === 'FAILED' || scanStatus === 'None') && (
  //         <>
  //           <div className="ah-cont_spin">
  //             <div className="ah-cont_square ah-cont_square-one" />
  //             <div className="ah-cont_square ah-cont_square-two" />
  //             <div className="ah-cont_square ah-cont_square-three" />
  //             <div className="ah-cont_square ah-cont_square-four" />
  //           </div>
  //           <span className="ah-cont__status">{makeFirstLetterUppercase(scanStatus)}</span>
  //           <span style={{ borderRight: '2px solid #2E74F3', height: '3rem' }} />
  //         </>
  //       )}

  //       {scanStatus === 'SUCCEEDED' || scanStatus === 'FAILED' || scanStatus === 'None' ? (
  //         <Button
  //           label="Run URL"
  //           size={BUTTON_SIZE.XSMALL}
  //           type="button"
  //           appearance={BUTTON_APPEARANCE.SECONDARY}
  //           clickHandler={() => {
  //             newJob(requestId);
  //           }}
  //         />
  //       ) : (
  //         <Button
  //           label="Abort"
  //           size={BUTTON_SIZE.XSMALL}
  //           type="button"
  //           appearance={BUTTON_APPEARANCE.SECONDARY}
  //           clickHandler={() => {
  //             stopJob();
  //           }}
  //         />
  //       )}
  //     </>
  //   );
  // } else rightAction = <div />;

  // Config Actions
  // const [loadOptions, { data, refetch }] = useLazyQuery(FETCH_USER_WEBSITES, {
  //   fetchPolicy: 'network-only',
  //   onCompleted: res => {
  //     if (res.fetchUserWebsite) {
  //       const arr = res.fetchUserWebsite;
  //       let activeItem;
  //       if (arr.length) {
  //         activeItem = arr.filter(item => item.id === initialRequestId)[0]
  //           ? arr.filter(item => item.id === initialRequestId)[0]
  //           : arr[0];
  //         refreshData(activeItem ? activeItem.id : undefined);
  //       }

  //       setDataSelected(activeItem);
  //     }
  //     // if (res.requests)
  //     //   setDataSelected(res.requests.filter(item => item.id === initialRequestId)[0]);
  //   }
  // });
  return (
    <div className="ah-cont">
      <header className="ah-cont__header">
        <div className="ah-cont__domain">
          <div className="ah-cont__icon-box">
            <svg className="ah-cont__icon">
              <use href={`${sprite}#${APP_ICONS.LINK_CHECKER_SMALL_ICON}`} />
            </svg>
          </div>
          <div className="ah-cont__divider" />
          <div className="ah-cont__dropdown  --relative">
            {/* <Config newJob={newJob} initialRequestId={requestId} refreshData={refreshData} /> */}
            <ConfigV1
              dropTopDownTitle="Websites"
              dropDownBottomTitle="+ New Website"
              options={websiteOptions}
              selected={selectedWebsite}
              onChangeHandler={onSelectWebsiteOption}
              updateModelTitle="Update Website"
            />
          </div>
        </div>

        {showButton ? (
          <div className="ah-cont__btn-loader-grp">
            {!(
              scanStatus === 'SUCCEEDED' ||
              scanStatus === 'FAILED' ||
              scanStatus === 'None' ||
              scanStatus === 'ABORTED'
            ) && (
              <>
                <div className="ah-cont_spin">
                  <div className="ah-cont_square ah-cont_square-one" />
                  <div className="ah-cont_square ah-cont_square-two" />
                  <div className="ah-cont_square ah-cont_square-three" />
                  <div className="ah-cont_square ah-cont_square-four" />
                </div>

                <span className="ah-cont__status">
                  {scanStatus ? makeFirstLetterUppercase(scanStatus) : ''}
                </span>

                <span style={{ borderRight: '2px solid #2E74F3', height: '3rem' }} />
              </>
            )}
            {isAborted && (
              <>
                <div className="ah-cont__status-msg">
                  <svg className="ah-cont__status-icon">
                    <use href={`${sprite}#${APP_ICONS.EXCLAMATION}`} />
                  </svg>
                  <p>This check was aborted</p>
                </div>
                <span style={{ borderRight: '2px solid #2E74F3', height: '3rem' }} />
              </>
            )}
            <div className="ah-cont__btn-grp">
              {scanStatus === 'SUCCEEDED' ||
              scanStatus === 'FAILED' ||
              scanStatus === 'None' ||
              scanStatus === 'ABORTED' ? (
                <Button
                  icon={isSmallWidth ? APP_ICONS.LINK : null}
                  label={isSmallWidth ? '' : `${isAborted ? 'Re-Run URL' : 'Run URL'}`}
                  size={isSmallWidth ? BUTTON_SIZE.MICRO : BUTTON_SIZE.XSMALL}
                  type="button"
                  name="run"
                  appearance={BUTTON_APPEARANCE.SECONDARY}
                  clickHandler={() => {
                    isAborted
                      ? handleGoogleAnalytics({
                          event: 'v2_rerun_url',
                          request_id: requestId
                        })
                      : handleGoogleAnalytics({
                          event: 'v2_run_url',
                          request_id: requestId
                        });
                    setIsDisabled(true);
                    newJob(requestId);
                  }}
                  isDisabled={isDisabled}
                />
              ) : (
                <Button
                  icon={isSmallWidth ? APP_ICONS.LINK : null}
                  label={isSmallWidth ? '' : 'Abort'}
                  size={isSmallWidth ? BUTTON_SIZE.MICRO : BUTTON_SIZE.XSMALL}
                  type="button"
                  name="abort"
                  appearance={BUTTON_APPEARANCE.SECONDARY}
                  clickHandler={() => {
                    setShowAbortModal(true);
                  }}
                />
              )}
            </div>
          </div>
        ) : (
          ''
        )}
        <AbortModal show={showAbortModal} setShow={setShowAbortModal} handleAbort={handleAbort} />
      </header>
    </div>
  );
};
ActionHeader.defaultProps = {
  scanStatus: '',
  isAborted: false,
  showButton: true
};
ActionHeader.propTypes = {
  scanStatus: PropTypes.string,
  isAborted: PropTypes.bool,
  requestId: PropTypes.string.isRequired,
  newJob: PropTypes.func.isRequired,
  stopJob: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired,
  showButton: PropTypes.bool,
  selectedWebsite: PropTypes.objectOf({
    link: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string
  }).isRequired,
  websiteOptions: PropTypes.arrayOf({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  }).isRequired,
  onSelectWebsiteOption: PropTypes.func.isRequired
};

export default ActionHeader;
