import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import Button from '../../components/Button/Button';
import './user.scss';
import ConfigModal from '../../components/Config/ConfigModal';
import InviteForm from '../../components/ConfigV1/InviteFrom';
import { useUserOrgs } from '../../hooks';
import useInviteUser from '../../hooks/userManagement/useInviteUser';

// import { useInviteForm } from '../../hooks';

const UserCreate = ({ showTitle }) => {
  // const { openInvite, setOpenInvite } = useInviteForm();
  const [isOpen, setIsOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [orgOptions, setOrgOptions] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedWeb, setSelectedWeb] = useState(null);
  const [webOptions, setWebOptions] = useState([]);

  const { fetchUserOrgs, fetchUserOrgsLoading, fetchUserOrgsResponse } = useUserOrgs();
  const { inviteUser, inviteUserLoading, inviteUserError, inviteUserResponse } = useInviteUser();

  const roleOptions = useMemo(
    () => [
      { label: 'Org Admin', value: '2' },
      { label: 'Web Admin', value: '3' },
      { label: 'User Read', value: '4' },
      { label: 'User Write', value: '5' }
    ],
    []
  );

  const resetParams = () => {
    setSelectedOrg(orgOptions[0]);
    setSelectedRole('');
    setSelectedWeb('');
    setEmail('');
  };

  const inviteUserClickHandler = e => {
    // console.log('click');
    setIsOpen(prev => !prev);
    resetParams();
  };

  const handleInviteUserSubmit = () => {
    if (email && selectedOrg?.value && selectedRole?.value) {
      if (selectedRole?.value >= 3 && selectedWeb?.value == null)
        toast.error('Please complete all required fields before Invite !');
      else {
        inviteUser({
          variables: {
            email,
            org_id: selectedOrg?.value,
            role_id: selectedRole?.value,
            website_id: selectedWeb?.value ? selectedWeb?.value : null
          }
        });
      }
    } else {
      toast.error('Please complete all required fields before Invite !');
    }
  };

  useEffect(() => {
    fetchUserOrgs();
  }, [fetchUserOrgs]);

  useEffect(() => {
    if (!fetchUserOrgsLoading && fetchUserOrgsResponse) {
      const tmp = fetchUserOrgsResponse?.map(({ id, name }) => ({ value: id, label: name }));
      setOrgOptions(tmp);
      setSelectedOrg(tmp[0]);
      setSelectedRole(roleOptions[2]);
    }
  }, [fetchUserOrgsLoading, fetchUserOrgsResponse, roleOptions]);

  useEffect(() => {
    if (!inviteUserLoading && inviteUserResponse) {
      if (inviteUserResponse?.success) {
        toast.success(inviteUserResponse.message);
        setIsOpen(false);
      } else toast.error(inviteUserResponse?.message);
    } else if (!inviteUserLoading && inviteUserError) toast.error('Something Went Wrong !');
  }, [inviteUserError, inviteUserLoading, inviteUserResponse]);

  useEffect(() => {
    if (!fetchUserOrgsLoading && fetchUserOrgsResponse && selectedOrg) {
      const webOp = fetchUserOrgsResponse?.filter(({ id }) => id === selectedOrg?.value);
      if (webOp && webOp?.length > 0) {
        const tmp = webOp[0]?.webList?.map(({ url, name, id }) => ({
          label: `${url}  ( ${name} ) `,
          value: id
        }));
        setWebOptions(tmp);
      }
    }
  }, [fetchUserOrgsLoading, fetchUserOrgsResponse, selectedOrg]);

  return (
    <div className="user__create">
      {showTitle && (
        <div>
          <h1>Users</h1>
        </div>
      )}
      <div>
        <Button
          icon="plus-icon"
          label="invite user"
          type="button"
          name="run"
          appearance="primary"
          size="normal"
          customStyleClass="user__btn"
          clickHandler={inviteUserClickHandler}
        />
      </div>
      <ConfigModal
        isOpen={isOpen}
        setShow={setIsOpen}
        configTitle="Invite User"
        addClass="height-max user__invite"
        modelBodyClass="height-max"
        saveAndRunButtonText="Invite"
        saveButtonText="Cancel"
        saveAndRunLoading={inviteUserLoading}
        handleSaveButtonClick={() => setIsOpen(false)}
        handleSaveAndRunButtonClick={handleInviteUserSubmit}
      >
        <InviteForm
          email={email}
          setEmail={setEmail}
          orgOptions={orgOptions}
          roleOptions={roleOptions}
          webOptions={webOptions}
          selectedOrg={selectedOrg}
          selectedRole={selectedRole}
          selectedWeb={selectedWeb}
          setSelectedOrg={setSelectedOrg}
          setSelectedRole={setSelectedRole}
          setSelectedWeb={setSelectedWeb}
        />
      </ConfigModal>
    </div>
  );
};

UserCreate.defaultProps = {
  showTitle: true
};

UserCreate.propTypes = {
  showTitle: PropTypes.bool
};

export default UserCreate;
