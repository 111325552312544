import React, { useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';

import './App.css';
import './styles/_main.scss';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Loader from './components/Loader';
import ScrollToTop from './components/ScrollTop';
import AppRoutes from './components/routes';
import AuthorizedApolloProvider from './utils/ApolloClient';
import APIErrorProvider from './providers/APIErrorProvider';
import ErrorModal from './components/ErrorModal';
import userTypeContext from './contexts/userTypeContext';
import AuthContextProvider from './contexts/auth-context';

const App = () => {
  const [authType, setAuthType] = useState('GUEST');

  // console.log('USddER', JSON.stringify(user), 'IS AUTHENTddICATED', isAuthenticated);
  const handleAuthType = () => {
    setAuthType('AUTHENTICATED');
  };
  return (
    <React.Suspense fallback={<Loader />}>
      <ToastContainer />
      {/* eslint-disable-next-line react/jsx-no-constructed-context-values */}
      <userTypeContext.Provider value={{ authType, handleAuthType }}>
        <AuthContextProvider>
          <Auth0Provider
            domain={process.env.REACT_APP_AUTH_CLIENT_DOMAIN}
            clientId={process.env.REACT_APP_AUTH_CLIENT_ID}
            audience={process.env.REACT_APP_AUTH_AUDIENCE}
            redirectUri={`${window.location.origin}${process.env.REACT_APP_AUTH_REDIRECT}`}
            scope="openid profile email"
          >
            <AuthorizedApolloProvider type="AUTHENTICATED">
              <APIErrorProvider>
                <div className="app">
                  <BrowserRouter>
                    <ScrollToTop />
                    <AppRoutes />
                  </BrowserRouter>
                </div>
                <ErrorModal />
              </APIErrorProvider>
            </AuthorizedApolloProvider>
          </Auth0Provider>
        </AuthContextProvider>
      </userTypeContext.Provider>
    </React.Suspense>
  );
};

export default App;
