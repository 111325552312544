import React, { useEffect, useRef } from 'react';
import propTypes from 'prop-types';
import './Modal.scss';

const Modal = ({ show, children, setShow, addClass, closeOnClickOutside }) => {
  const modalRef = useRef();

  useEffect(() => {
    const clickOutsideContent = e => {
      if (e.target === modalRef.current && closeOnClickOutside) {
        setShow(false);
      }
    };
    window.addEventListener('click', clickOutsideContent);
    return () => {
      window.removeEventListener('click', clickOutsideContent);
    };
    /*eslint-disable*/
  }, []);

  return (
    <div ref={modalRef} className={`modal ${show ? 'active' : ''}`}>
      <div className={addClass.length ? `modal__content ${addClass}` : 'modal__content'}>
        {children}
      </div>
    </div>
  );
};

export default Modal;

Modal.defaultProps = {
  show: false,
  children: <div />,
  setShow: () => {},
  addClass: '',
  closeOnClickOutside: true
};

Modal.propTypes = {
  show: propTypes.bool,
  children: propTypes.node,
  setShow: propTypes.func,
  addClass: propTypes.string,
  closeOnClickOutside: propTypes.bool
};

export const ModalHeader = ({ children, addClass }) => (
  <div className={addClass ? `modal__header ${addClass}` : 'modal__header'}>{children}</div>
);

export const ModalBody = ({ children, addClass }) => (
  <div className={addClass ? `modal__body ${addClass}` : 'modal__body'}>{children}</div>
);

export const ModalFooter = ({ children, addClass }) => (
  <div className={addClass ? `modal__footer ${addClass}` : 'modal__footer'}>{children}</div>
);

ModalHeader.propTypes = {
  children: propTypes.node.isRequired,
  addClass: propTypes.string
};

ModalBody.propTypes = {
  children: propTypes.node.isRequired,
  addClass: propTypes.string
};

ModalFooter.propTypes = {
  children: propTypes.node.isRequired,
  addClass: propTypes.string
};
