import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import PropTypes from 'prop-types';
// import { useLazyQuery, useMutation } from '@apollo/client';
// import { toast } from 'react-toastify';
import handleGoogleAnalytics from '../../utils/googleAnalytics';
import { APP_ICONS } from '../../constants';
import Select from '../Select/Select';
import IconButton from '../IconButton/IconButton';
import '../Config/Config.scss';
import sprite from '../../assets/sprite.svg';
// import DeleteConfigModal from '../DeleteConfigModal/DeleteConfigModal';
import Button, { BUTTON_APPEARANCE, BUTTON_SIZE } from '../Button/Button';
import useAPIError from '../../hooks/useAPIError';

const ConfigV1 = ({
  selected,
  options,
  dropDownBottomTitle,
  dropTopDownTitle,
  onChangeHandler,
  createComponent,
  editComponent,
  deleteComponent,
  setCreateNewModel,
  handleOnEditClick,
  handleOnDeleteClick,
  showEdit,
  showDelete,
  showTopContent,
  showBottomContent,
  showDivider
}) => {
  // API CALLS START
  const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);
  // const customId = 'custom-id-cfg-mdl-v1';
  // console.log(selected, 'selected');
  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      const bodyElement = entries[0];
      setDeviceWidth(bodyElement.contentRect.width);
    });

    resizeObserver.observe(document.body);

    return () => resizeObserver.disconnect();
  }, []);

  const [isOpen, setOpen] = useState(false);

  // const [showDeleteConfigModal, setShowDeleteConfigModal] = useState(false);
  const [error, setError] = useState([]);
  const { addError } = useAPIError();
  // STATE END
  const { loginWithRedirect } = useAuth0();
  const userLogin = () => {
    loginWithRedirect();
  };

  useEffect(() => {
    const eventHandler = event => {
      setOpen(false);
    };

    document.body.addEventListener('click', eventHandler);

    return () => {
      document.body.removeEventListener('click', eventHandler);
    };
  }, [selected]);

  useEffect(() => {
    if (error.length) {
      addError(error[0], error[1], error[2]);
    }
  }, [addError, setError, error]);
  // USEEFFECT END

  // FUNCTIONS START

  // const notify = (type, text) =>
  //   type === 'SUCCEEDED'
  //     ? toast.success(text || 'Success!', {
  //         position: toast.POSITION.BOTTOM_RIGHT,
  //         toastId: customId
  //       })
  //     : toast.error(text || 'Failure', {
  //         position: toast.POSITION.BOTTOM_RIGHT,
  //         toastId: customId
  //       });

  const onItemClick = e => {
    onChangeHandler(e);
    // refreshData(itemClicked.id, 'CONFIG');
    setOpen(false);
  };

  const selectionBox = () => (
    <div
      className="dd-cont__selection-box config__selection-box"
      onClick={toggleHandler}
      role="none"
      // data-testid="dropdown-box"
    >
      <div className="dd-cont__selection-text">
        <div className="dd-cont__selection-text-child1">
          {selected && selected.link ? selected.link : ''}
        </div>
        {showDivider ? <div>|</div> : <div className="p-y-18" />}
        <div className="dd-cont__selection-text-child2">
          {selected && selected?.label ? selected.label : ''}
        </div>
      </div>
      <span className="dd-cont__icon-box">
        <svg className={`dd-cont__icon ${flipClass}`}>
          <use href={`${sprite}#${APP_ICONS.DOWN_ARROW}`} />
        </svg>
      </span>
    </div>
  );

  const toggleHandler = event => {
    event.stopPropagation();
    setOpen(prevState => !prevState);
  };

  const errorModalAction = (
    <span>
      You have reached the maximum number of configurations (two) allowed for a guest user. To
      continue either delete one of the existing configurations or register yourself by clicking
      <button className="config__link" type="button" onClick={userLogin}>
        here
      </button>
      .
    </span>
  );

  const flipClass = isOpen ? 'dd-cont__icon--flip' : '';
  const topContent = <div className="config__heading">{dropTopDownTitle}</div>;
  const bottomContent = (
    <div className="config__btn-container config__flex_end">
      <Button
        label={dropDownBottomTitle}
        type="button"
        name="guest"
        customStyleClass="config__btn-borderless"
        size={BUTTON_SIZE.MICRO}
        appearance={BUTTON_APPEARANCE.TERTIARY}
        clickHandler={() => {
          if (window.location.pathname.startsWith('/guest') && options.length === 2) {
            handleGoogleAnalytics({
              event: 'v2_scan_exceeded'
              // config: data.fetchUserWebsite
            });
            setError(['Config creation failed', errorModalAction, '400']);
          } else {
            setCreateNewModel(true);
          }
        }}
      />
    </div>
  );

  // const handleDeleteConfig = () => {
  //   const obj = data.fetchUserWebsite[configValue.split('$')[1]];

  //   // GOOGLE ANALYTICS START

  //   handleGoogleAnalytics({
  //     event: 'v2_delete_settings_config',
  //     config: obj.config_name,
  //     request_id: configValue.split('$')[0]
  //   });

  // GOOGLE ANALYTICS END

  //   deleteConfig({
  //     variables: {
  //       deleteConfigId: configValue.split('$')[0]
  //     }
  //   });
  //   setShowDeleteConfigModal(false);
  // };

  const rowRightContent = (
    <div style={{ display: 'flex', gap: '3rem' }}>
      <IconButton icon={showEdit ? APP_ICONS.EDIT : ''} onClickHandler={handleOnEditClick} />
      <IconButton
        icon={showDelete ? APP_ICONS.DELETE_ICON : ''}
        onClickHandler={handleOnDeleteClick}
      />
    </div>
  );

  const modalPaddingWidth = deviceWidth > 768 ? 24 : 10;

  return (
    <div className="dd-cont" data-testid="dropdown-container">
      {showEdit && editComponent}
      {createComponent}
      {showDelete && deleteComponent}
      {selectionBox()}
      <div>
        <Select
          width={deviceWidth > 934 ? '65rem' : `${deviceWidth - modalPaddingWidth * 2}px`}
          isOpen={isOpen}
          options={options}
          multi={false}
          onChangeHandler={onItemClick}
          topContent={showTopContent ? topContent : ''}
          bottomContent={showBottomContent ? bottomContent : ''}
          rowRightContent={rowRightContent}
          customStyleClass="config__select"
          isToolTip
        />
      </div>
    </div>
  );
};

ConfigV1.defaultProps = {
  options: [{ label: 'loading', value: '', link: '' }],
  selected: { label: '', value: '', link: '' },
  dropDownBottomTitle: '',
  dropTopDownTitle: '',
  createComponent: '',
  editComponent: '',
  handleOnEditClick: () => {},
  handleOnDeleteClick: () => {},
  deleteComponent: () => {},
  setCreateNewModel: () => {},
  showDelete: true,
  showEdit: true,
  showTopContent: true,
  showBottomContent: true,
  showDivider: true
};

ConfigV1.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      link: PropTypes.string
    })
  ),
  dropDownBottomTitle: PropTypes.string,
  deleteComponent: PropTypes.node,
  dropTopDownTitle: PropTypes.string,
  onChangeHandler: PropTypes.func.isRequired,
  selected: PropTypes.shape({
    link: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string
  }),
  setCreateNewModel: PropTypes.func,
  createComponent: PropTypes.node,
  editComponent: PropTypes.node,
  handleOnEditClick: PropTypes.func,
  handleOnDeleteClick: PropTypes.func,
  showDelete: PropTypes.bool,
  showEdit: PropTypes.bool,
  showTopContent: PropTypes.bool,
  showBottomContent: PropTypes.bool,
  showDivider: PropTypes.bool
};

export default ConfigV1;
