import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useTable, usePagination } from 'react-table';
import propTypes from 'prop-types';
import Pagination from 'rc-pagination';
import Dropdown from '../DropDown';
import sprite from '../../assets/sprite.svg';
import { APP_ICONS, eventLinker } from '../../constants';
import TEXT_CONTENT from '../../languages';
import 'rc-pagination/assets/index.css';

const Table = ({
  columns,
  hiddenColumns,
  tableData,
  statusDropdownOptions,
  typeOptions,
  pageCountOptions,
  count,
  noLinksChecked,
  handleRefetch,
  jobId,
  search,
  status,
  type,
  limit,
  offset
}) => {
  const data = useMemo(() => tableData, [tableData]);

  /* ------------ REFS START ----------*/
  const inputEl = useRef(null);
  const firstRender = useRef(true);
  const firstOffset = useRef(true);
  /* ------------ REFS END ----------*/

  /* ---------STATES START ---------*/
  const [searchLocal, setSearchLocal] = useState(search);
  const [statusLocal, setStatusLocal] = useState({ label: status, value: status });
  const [typeLocal, setTypeLocal] = useState({ label: type, value: type });
  const [limitLocal, setLimitLocal] = useState({ label: limit, value: limit });
  const [offsetLocal, setOffsetLocal] = useState(offset);

  /* ------------STATES END ---------*/

  /* ----- TABLE CONFIG START ------*/
  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow, setHiddenColumns } =
    useTable(
      {
        columns,
        data,
        manualPagination: true,
        pageCount: limit
      },
      usePagination
    );

  const columnStyle = {};
  columns.forEach(column => {
    columnStyle[column.Header] = { width: `${column.width}%` };
  });

  const onOptionChange = (e, options, setSelected) => {
    const selectedOption = options.filter(x => x.value === e.value);
    if (selectedOption.length > 0) setSelected(selectedOption[0]);
  };
  /* ----- TABLE CONFIG END ------*/

  /* --------- USE EFFECT START ------------*/
  // useEffect(() => {
  //   /* ON MOUNT */
  //   console.log('MOUNTING TABLE COMPONENT');
  // }, []);

  useEffect(() => {
    setHiddenColumns(hiddenColumns);
  }, [hiddenColumns, setHiddenColumns]);

  /* ---------- USE EFFECT END -------------*/

  /* ------ FUNCTIONS START ------------*/

  const calculateOffset = pageNum => (pageNum - 1) * limit;

  const calculateDetailsVariable = (searchKey, change = TEXT_CONTENT.TABLE_NONE) => {
    const obj = {
      variables: { job: jobId, dropdown: true }
    };
    obj.variables[eventLinker.LIMIT] = parseInt(limitLocal.value, 10);
    obj.variables[eventLinker.OFFSET] = parseInt(offsetLocal, 10);
    obj.variables.parameters = {};
    if (searchKey) {
      obj.variables.parameters[eventLinker.SEARCH] = searchKey;
    }
    if (Object.keys(statusLocal).length && statusLocal.value !== 'All') {
      obj.variables.parameters[eventLinker.STATUS] = statusLocal.value.toString();
    }
    if (Object.keys(typeLocal).length && typeLocal.value !== 'All') {
      obj.variables.parameters[eventLinker.TYPE] = typeLocal.value;
    }
    if (change !== TEXT_CONTENT.TABLE_MAINTAIN_OFFSET) {
      obj.variables[eventLinker.OFFSET] = 0;
    }

    return obj;
  };

  const handleKeyDown = event => {
    if (event.keyCode === 13) {
      const searchKey = searchLocal;
      const variableLocal = calculateDetailsVariable(searchKey).variables;
      handleRefetch(variableLocal);
    }
  };

  /* --------- FUNCTIONS END -------------*/

  /* ------ USE MEMO START -------*/
  useMemo(() => {
    if (!firstRender.current) {
      const searchKey = searchLocal;
      const variableLocal = calculateDetailsVariable(searchKey).variables;
      handleRefetch(variableLocal);
    } else {
      firstRender.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limitLocal, typeLocal, statusLocal, noLinksChecked]);

  useMemo(() => {
    if (!firstOffset.current) {
      const searchKey = searchLocal;
      const variableLocal = calculateDetailsVariable(
        searchKey,
        TEXT_CONTENT.TABLE_MAINTAIN_OFFSET
      ).variables;
      handleRefetch(variableLocal);
    } else {
      firstOffset.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offsetLocal]);

  /* ------- USE MEMO END ------*/

  // HAD TO BE DISABLED BECAUSE DOCS SPECIFY SPREADING
  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <>
      <table className="details__table" {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps()}
                  style={columnStyle[column.render('Header')]}
                  className={column.render('Header').length > 0 ? 'details__title' : ''}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          <tr style={{ background: 'rgba(224, 224, 224,0.4)', color: '#333333' }}>
            {!hiddenColumns.includes('sno') && <td />}
            {!hiddenColumns.includes('link') && (
              <td>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <input
                    placeholder="Quick URL Search..."
                    className="details__input"
                    value={searchLocal}
                    ref={inputEl}
                    type="text"
                    onChange={e => {
                      if (e.target.value === '') {
                        setSearchLocal('');
                        if (search) {
                          const searchKey = '';
                          const variableLocal = calculateDetailsVariable(searchKey).variables;
                          handleRefetch(variableLocal);
                        }
                      } else {
                        setSearchLocal(e.target.value);
                      }
                    }}
                    onKeyUp={handleKeyDown}
                  />
                  <span>
                    {searchLocal ? (
                      <span
                        role="none"
                        onClick={() => {
                          setSearchLocal('');
                          if (search) {
                            const variableLocal = calculateDetailsVariable().variables;
                            handleRefetch(variableLocal);
                          }
                        }}
                      >
                        <svg className="summary__widget-body-left-icon --pointer">
                          <use href={`${sprite}#${APP_ICONS.CROSS_CIRCLE}`} />
                        </svg>
                      </span>
                    ) : (
                      <svg className="summary__widget-body-left-icon">
                        <use href={`${sprite}#${APP_ICONS.SEARCH_ICON_DARK}`} />
                      </svg>
                    )}
                  </span>
                </div>
              </td>
            )}
            {!hiddenColumns.includes('page') && <td />}
            {!hiddenColumns.includes('link_status') && (
              <td>
                <Dropdown
                  selectedOption={statusLocal}
                  options={statusDropdownOptions}
                  onChangeHandler={e => {
                    onOptionChange(e, statusDropdownOptions, setStatusLocal);
                  }}
                  color="rgba(224, 224, 224,0.4)"
                  icon="THREE_VERTICAL_DOTS_ICON"
                />
              </td>
            )}
            {!hiddenColumns.includes('link_location') && (
              <td>
                <Dropdown
                  selectedOption={typeLocal}
                  options={typeOptions}
                  onChangeHandler={e => onOptionChange(e, typeOptions, setTypeLocal)}
                  color="rgba(224, 224, 224,0.4)"
                  icon="THREE_VERTICAL_DOTS_ICON"
                />
              </td>
            )}
          </tr>
          {page.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="details__footer">
        <div className="details__footer__child">
          <p className="details__text-primary">Results per page:</p>
          <div>
            <Dropdown
              addClass="details_page_count"
              selectedOption={limitLocal}
              options={pageCountOptions}
              onChangeHandler={e => onOptionChange(e, pageCountOptions, setLimitLocal)}
            />
          </div>
        </div>
        <div className="details__footer__child ">
          {/* <button
            type="button"
            className="details__previous"
            onClick={() => {
              const newOffset = parseInt(offsetLocal, 10) - parseInt(limitLocal.value, 10);
              if (newOffset >= 0) setOffsetLocal(String(newOffset));
            }}
          >
            <svg className=" details__icon">
              <use href={`${sprite}#${APP_ICONS.LEFT_ARROW}`} />
            </svg>
          </button>
          <span className="details__pageInfo">
            Page{' '}
            <strong>
              {Math.floor(parseInt(offsetLocal, 10) / parseInt(limitLocal.value, 10)) + 1}
            </strong>{' '}
            of <strong>{Math.floor(count / parseInt(limitLocal.value, 10)) + 1}</strong>
          </span>
          <button
            type="button"
            className="details__next"
            onClick={() => {
              const newOffset = parseInt(limitLocal.value, 10) + parseInt(offsetLocal, 10);

              if (newOffset <= count) setOffsetLocal(String(newOffset));
            }}
          >
            <svg className=" details__icon">
              <use href={`${sprite}#${APP_ICONS.RIGHT_ARROW}`} />
            </svg>
          </button> */}
          <Pagination
            current={Math.floor(parseInt(offsetLocal, 10) / parseInt(limitLocal.value, 10)) + 1}
            total={count}
            pageSize={limitLocal.value}
            onChange={num => {
              const newOffset = calculateOffset(num);
              if (newOffset >= 0) setOffsetLocal(String(newOffset));
            }}
            prevIcon={
              <div className="details__icon-container">
                <svg className=" details__icon">
                  <use href={`${sprite}#${APP_ICONS.LEFT_ARROW}`} />
                </svg>
              </div>
            }
            nextIcon={
              <div className="details__icon-container">
                <svg className=" details__icon">
                  <use href={`${sprite}#${APP_ICONS.RIGHT_ARROW}`} />
                </svg>
              </div>
            }
            // prevIcon={
            //   <button
            //     type="button"
            //     className="details__previous"
            //     onClick={() => {
            //       const newOffset = parseInt(offsetLocal, 10) - parseInt(limitLocal.value, 10);
            //       if (newOffset >= 0) setOffsetLocal(String(newOffset));
            //     }}
            //   >
            //     <svg className=" details__icon">
            //       <use href={`${sprite}#${APP_ICONS.LEFT_ARROW}`} />
            //     </svg>
            //   </button>
            // }
            // nextIcon={
            //   <button
            //     type="button"
            //     className="details__next"
            //     onClick={() => {
            //       const newOffset = parseInt(limitLocal.value, 10) + parseInt(offsetLocal, 10);

            //       if (newOffset <= count) setOffsetLocal(String(newOffset));
            //     }}
            //   >
            //     <svg className=" details__icon">
            //       <use href={`${sprite}#${APP_ICONS.RIGHT_ARROW}`} />
            //     </svg>
            //   </button>
            // }
          />
        </div>
      </div>
    </>
  );
};

Table.propTypes = {
  columns: propTypes.arrayOf(
    propTypes.shape({
      Header: propTypes.string,
      accessor: propTypes.string,
      width: propTypes.string
    })
  ).isRequired,
  hiddenColumns: propTypes.arrayOf(propTypes.string).isRequired,
  tableData: propTypes.arrayOf(
    propTypes.shape({
      link: propTypes.string,
      link_status: propTypes.string,
      link_type: propTypes.string,
      link_location: propTypes.string,
      page: propTypes.string,
      content_type: propTypes.string,
      __typename: propTypes.string
    })
  ).isRequired,
  statusDropdownOptions: propTypes.arrayOf(
    propTypes.shape({
      label: propTypes.string,
      value: propTypes.string
    })
  ).isRequired,
  typeOptions: propTypes.arrayOf(
    propTypes.shape({
      label: propTypes.string,
      value: propTypes.string
    })
  ).isRequired,
  pageCountOptions: propTypes.arrayOf(
    propTypes.shape({
      label: propTypes.string,
      value: propTypes.string
    })
  ).isRequired,
  count: propTypes.number.isRequired,
  noLinksChecked: propTypes.number.isRequired,
  handleRefetch: propTypes.func.isRequired,
  jobId: propTypes.string.isRequired,
  search: propTypes.string.isRequired,
  status: propTypes.string.isRequired,

  type: propTypes.string.isRequired,

  limit: propTypes.string.isRequired,
  offset: propTypes.string.isRequired
};

export default Table;
