import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import 'rc-pagination/assets/index.css';
import Button from '../../components/Button/Button';
import UserTableContent from './userTable';
import { useEmailSearch } from '../../hooks';
import useOrgSearch from '../../hooks/userManagement/useOrgSearch';

const UserDisplay = ({ renderData }) => {
  const optionList = [
    { value: 'Email', label: 'Search By Email' },
    { value: 'Organization', label: 'Search By Organization' }
  ];
  const customStyles = {
    control: base => ({
      ...base,
      height: 40,
      minHeight: 39,
      background: 'none'
    })
  };
  const [placeholder, setPlaceholder] = useState('Email');
  const [searchText, setSearchText] = useState('');
  const [selectedOption, setSelectedOption] = useState(optionList[0]);
  const [tableData, setTableData] = useState(renderData);

  const { fetchEmailSearch, fetchEmailSearchResponse, fetchEmailSearchLoading } = useEmailSearch();
  const { fetchOrgSearch, fetchOrgSearchLoading, fetchOrgSearchResponse } = useOrgSearch();

  const onSelectSearchByOption = option => {
    setSearchText('');
    setPlaceholder(option.value);
    setSelectedOption(option);
    setTableData(renderData);
  };

  // console.log(renderData, 'rd', tableData);

  const handleSearchText = e => {
    const text = e.target.value.trim().toLowerCase();
    if (!text) setTableData(renderData);
    setSearchText(text);
  };

  const handleReset = () => {
    setPlaceholder('Email');
    setSelectedOption(optionList[0]);
    setTableData(renderData);
    setSearchText('');
  };

  // useEffect(() => {
  //   if (renderData && searchText && placeholder === 'Organization') {
  //     const result = renderData
  //       .map(itm => {
  //         if (itm && itm?.orgDetails) {
  //           const name = itm.orgDetails.name.toLowerCase();
  //           if (name.includes(searchText)) return itm;
  //         }
  //         return '';
  //       })
  //       .filter(Boolean);
  //     setTableData(result);
  //   }
  // }, [placeholder, renderData, searchText]);
  // console.log(renderData, 'rD', tableData);

  useEffect(() => {
    // console.log('email, side');
    let timer = null;
    if (searchText && placeholder === 'Email') {
      timer = setTimeout(() => fetchEmailSearch({ variables: { pattern: searchText } }), 200);
    }
    return () => {
      timer && clearTimeout(timer);
    };
  }, [fetchEmailSearch, placeholder, searchText]);

  useEffect(() => {
    let timer = null;
    if (searchText && placeholder === 'Organization') {
      timer = setTimeout(() => fetchOrgSearch({ variables: { pattern: searchText } }), 200);
    }
    return () => {
      timer && clearTimeout(timer);
    };
  }, [fetchOrgSearch, placeholder, searchText]);

  useEffect(() => {
    // console.log('email response, side');
    if (
      !fetchEmailSearchLoading &&
      fetchEmailSearchResponse &&
      placeholder === 'Email' &&
      searchText
    ) {
      const structuredData = fetchEmailSearchResponse
        ?.flatMap(({ privilege, id, email, first_name: firstName }) => {
          if (privilege.length > 0)
            return [...privilege, { id, userDetails: { email, first_name: firstName } }];
          return { id, userDetails: { email, first_name: firstName } };
        })
        .flat();
      // console.log(structuredData, 'dt');
      setTableData(structuredData);
    } else setTableData(renderData);
  }, [fetchEmailSearchLoading, fetchEmailSearchResponse, placeholder, renderData, searchText]);

  useEffect(() => {
    // console.log('email response, side');
    if (
      !fetchOrgSearchLoading &&
      fetchOrgSearchResponse &&
      placeholder === 'Organization' &&
      searchText
    ) {
      const structuredData = fetchOrgSearchResponse
        ?.flatMap(({ privilege, id, email, first_name: firstName }) => {
          if (privilege.length > 0)
            return [...privilege, { id, userDetails: { email, first_name: firstName } }];
          return { id, userDetails: { email, first_name: firstName } };
        })
        .flat();
      // console.log(structuredData, 'dt');
      setTableData(structuredData);
    } else setTableData(renderData);
  }, [fetchOrgSearchLoading, fetchOrgSearchResponse, placeholder, renderData, searchText]);

  return (
    <>
      <div className="user__search">
        <input
          id="second"
          className="user__search__input"
          placeholder={`Search by ${placeholder}`}
          onChange={handleSearchText}
          value={searchText}
        />
        <Select
          id="second"
          className="user__search__select"
          options={optionList}
          value={selectedOption}
          onChange={onSelectSearchByOption}
          placeholder="Search By"
          styles={customStyles}
          classNamePrefix="search-by"
        />
        <Button
          icon="cross"
          label="Reset"
          type="button"
          name="run"
          appearance="secondary"
          clickHandler={handleReset}
          size="nano"
          customStyleClass="user__search__reset"
        />
      </div>
      <UserTableContent tableData={tableData} searchText={searchText} filterType={placeholder} />
    </>
  );
};

UserDisplay.defaultProps = {
  // renderData: [{ id:  }]
};

UserDisplay.propTypes = {
  renderData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string
    })
  ).isRequired
};
export default UserDisplay;
