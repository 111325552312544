import { useState } from 'react';

export default function useWebsiteForm() {
  const [websiteName, setWebsiteName] = useState('');
  const [websiteUrl, setWebsiteUrl] = useState('');
  const [websiteOrg, setWebsiteOrg] = useState({ label: 'Choose Org ...', value: '' });

  const resetWebsiteForm = () => {
    setWebsiteName('');
    setWebsiteUrl('');
    setWebsiteOrg('');
  };

  return {
    // openCreateWebsite,
    websiteName,
    websiteUrl,
    websiteOrg,
    // setOpenCreateWebsite,
    setWebsiteName,
    setWebsiteUrl,
    setWebsiteOrg,
    resetWebsiteForm
    // handleCreateWebsiteModel
  };
}
