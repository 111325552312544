import React from 'react';
import propTypes from 'prop-types';

import Modal, { ModalBody, ModalFooter, ModalHeader } from '../../containers/Modal';
import { APP_ICONS } from '../../constants';
import Button from '../Button/Button';

import sprite from '../../assets/sprite.svg';

import './DeleteConfigModal.scss';

const DeleteConfigModal = ({
  show,
  setShow,
  handleDeleteConfig,
  configName,
  loading,
  deleteTitle,
  deleteBodyMsg
}) => (
  <Modal show={show} setShow={setShow} addClass="delete_config_modal">
    <ModalHeader>
      <div className="delete_config_modal__header">
        <svg className="delete_config_modal__icon">
          <use href={`${sprite}#${APP_ICONS.DELETE_ICON_PRIMARY}`} />
        </svg>
        <h2>{deleteTitle}</h2>
      </div>
      <div className="divider" />
    </ModalHeader>
    <ModalBody>
      <div className="delete_config_modal__body">
        <div className="delete_config_modal__body-text">{`${deleteBodyMsg} ${configName} ?`}</div>
      </div>
    </ModalBody>
    <ModalFooter>
      <div className="delete_config_modal__footer">
        <Button
          label="Cancel"
          type="button"
          name="cancel"
          appearance="secondary"
          customStyleClass="delete_config_modal__footer-button"
          clickHandler={() => {
            setShow(false);
          }}
        />

        <Button
          label="Yes"
          name="yes"
          type="button"
          isDisabled={loading}
          loading={loading}
          customStyleClass="delete_config_modal__footer-button"
          clickHandler={handleDeleteConfig}
        />
      </div>
    </ModalFooter>
  </Modal>
);
DeleteConfigModal.defaultProps = {
  loading: false,
  deleteTitle: '',
  deleteBodyMsg: '',
  configName: ''
};
DeleteConfigModal.propTypes = {
  show: propTypes.bool.isRequired,
  setShow: propTypes.func.isRequired,
  handleDeleteConfig: propTypes.func.isRequired,
  configName: propTypes.string,
  loading: propTypes.bool,
  deleteTitle: propTypes.string,
  deleteBodyMsg: propTypes.string
};
export default DeleteConfigModal;
