import React from 'react';
import Button, { BUTTON_APPEARANCE } from '../../components/Button/Button';

import './Login.scss';
import { APP_ICONS } from '../../constants';
import SideNav from '../../components/SideNav/SideNav';
import ActionHeader from '../../components/ActionHeader/ActionHeader';

const Login = () => (
  <>
    <ActionHeader domain="https://geotab.com" />
    <h1>Login Page</h1>
    <div className="login">
      <Button
        label="download"
        type="button"
        name="download"
        icon={APP_ICONS.DOWNLOAD}
        appearance={BUTTON_APPEARANCE.SECONDARY}
      />

      <Button
        label="copy link"
        type="button"
        name="copy"
        icon={APP_ICONS.LINK}
        appearance={BUTTON_APPEARANCE.SECONDARY}
      />

      <Button
        label="copy link"
        type="button"
        name="copy"
        icon={APP_ICONS.LINK}
        appearance={BUTTON_APPEARANCE.TERTIARY}
      />

      <Button label="start check" type="button" appearance={BUTTON_APPEARANCE.PRIMARY} />

      <Button
        label="copy link"
        type="button"
        name="copy"
        icon={APP_ICONS.LINK}
        appearance="secondary"
        isDisabled
      />

      <Button
        label="start check"
        name="check"
        type="button"
        appearance={BUTTON_APPEARANCE.PRIMARY}
        isDisabled
      />
      <Button
        label="start check"
        name="check"
        type="button"
        appearance={BUTTON_APPEARANCE.TERTIARY}
        isDisabled
      />

      <SideNav />
    </div>
  </>
);

export default Login;
