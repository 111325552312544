import { gql } from '@apollo/client';

const UPDATE_CONFIG = gql`
  mutation UpdateConfig(
    $updateConfigId: String!
    $config: ConfigUpdateInput!
    $createJob: Boolean
  ) {
    UpdateConfig(id: $updateConfigId, config: $config, createJob: $createJob) {
      message
    }
  }
`;

export default UPDATE_CONFIG;
