import { useLazyQuery } from '@apollo/client';
import FETCH_CONFIG_DETAILS from '../graphql/queries/Configuration';

export default function useConfigDetails() {
  const [
    fetchConfigurationDetails,
    {
      data: configurationDetails,
      error: configurationDetailsError,
      loading: configurationDetailsLoading
    }
  ] = useLazyQuery(FETCH_CONFIG_DETAILS);
  return {
    configurationDetails: configurationDetails?.request,
    configurationDetailsError,
    configurationDetailsLoading,
    fetchConfigurationDetails
  };
}
