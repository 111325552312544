import { useLazyQuery } from '@apollo/client';
// import { useEffect, useState } from 'react';
import { ORGS_ANDS_WEBS_FOR_USERS_PAGE } from '../../graphql/queries/User';

export default function useUserOrgs() {
  // const [currentData, setCurrentData] = useState(null);
  const [
    fetchUserOrgs,
    { loading: fetchUserOrgsLoading, error: fetchUserOrgsError, data: fetchUserOrgsResponse }
  ] = useLazyQuery(ORGS_ANDS_WEBS_FOR_USERS_PAGE, { fetchPolicy: 'network-only' });

  // useEffect(() => {
  //   if (!fetchUserOrgsLoading && fetchUserOrgsResponse) {
  //     setCurrentData(fetchUserOrgsResponse?.fetchOrgsForUserRole);
  //   }
  // }, [fetchUserOrgs, fetchUserOrgsLoading, fetchUserOrgsResponse]);

  return {
    fetchUserOrgs,
    fetchUserOrgsLoading,
    fetchUserOrgsError,
    fetchUserOrgsResponse: fetchUserOrgsResponse?.fetchOrgsForUserRole
  };
}
