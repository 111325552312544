/*

This is the charts widget component that contains the area chart and the donut chart. For the two charts, it takes 2 JSON objects in the following formats - 

donutChartData: [
  { 
    name: string,
    value: number
  },
];

areaChartData: [
  {
    name: string,
    uv: number
  },
]

*/

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Pie,
  PieChart,
  Sector,
  ResponsiveContainer,
  Cell,
  Legend,
  AreaChart,
  Area
} from 'recharts';

import { CHART_TEXT_COLOR, CHART_SEGMENT_COLORS } from '../../constants';

import './ChartsWidget.scss';

const ChartsWidget = ({ donutChartData, areaChartData, totalBroken }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isSmallWidth, setIsSamllWidth] = useState(false);
  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      const bodyElement = entries[0];

      if (bodyElement.contentRect.width <= 500) setIsSamllWidth(true);
      else setIsSamllWidth(false);
    });

    resizeObserver.observe(document.documentElement);

    return () => resizeObserver.disconnect();
  }, []);

  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };

  const labelTextRenderFunction = (value, entry) => (
    <span
      style={{
        color: CHART_TEXT_COLOR,
        display: 'inline-block',
        marginBlock: '1rem',
        marginLeft: '.5rem',
        fontFamily: 'Poppins',
        fontWeight: 300,
        fontSize: '1.2rem'
      }}
    >
      {value}
    </span>
  );

  return (
    <div className="charts-widget" data-testid="charts-widget">
      <div className="charts-widget__left-side">
        <span className="summary__widget-title">Total Broken Links</span>
        <p className="charts-widget__count">{totalBroken}</p>
        <div className="charts-widget__left-chart">
          <ResponsiveContainer width="100%" height={100}>
            <AreaChart
              width={400}
              height={70}
              data={areaChartData}
              margin={{
                top: 0,
                right: 24,
                left: 0,
                bottom: 0
              }}
            >
              <Area type="monotone" dataKey="uv" stroke="#4ECDF6" fill="#2C75F3" />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </div>
      {/* <div className="charts-widget__separator" /> */}
      <div className="charts-widget__right-side">
        {/* <div className="charts-widget__right-chart"> */}
        <ResponsiveContainer
          width="60%"
          height={isSmallWidth ? 280 : 200}
          minWidth={isSmallWidth ? 350 : 450}
        >
          <PieChart width={400} height={400}>
            <Pie
              activeIndex={activeIndex}
              activeShape={renderActiveShape}
              // data={tmp}
              data={donutChartData}
              cx="45%"
              cy="50%"
              innerRadius={65}
              outerRadius={75}
              fill="#8884d8"
              dataKey="value"
              onMouseEnter={onPieEnter}
            >
              {donutChartData &&
                donutChartData.map((entry, index) => (
                  <Cell
                    key={entry.name}
                    fill={CHART_SEGMENT_COLORS[index % CHART_SEGMENT_COLORS.length]}
                  />
                ))}
            </Pie>
            <Legend
              layout={isSmallWidth ? 'horizontal' : 'vertical'}
              align={isSmallWidth ? 'center' : 'right'}
              verticalAlign={isSmallWidth ? 'bottom' : 'middle'}
              iconType="circle"
              formatter={labelTextRenderFunction}
            />
          </PieChart>
        </ResponsiveContainer>
        {/* </div> */}
      </div>
    </div>
  );
};

const renderActiveShape = props => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    // payload,
    value,
    percent
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 0) * cos;
  const sy = cy + (outerRadius + 0) * sin;
  const mx = cx + (outerRadius - 10) * cos;
  const my = cy + (outerRadius - 10) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';
  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <text
        x={cx}
        y={cy - 10}
        dy={0}
        textAnchor="middle"
        fill="#2E74F3"
        style={{
          fontFamily: 'Commissioner, sans-serif',
          fontWeight: 300,
          fontSize: '1.4rem'
        }}
      >
        Broken Links
      </text>
      <text
        x={cx}
        y={cy + 20}
        textAnchor="middle"
        fill={CHART_TEXT_COLOR}
        style={{
          fontFamily: 'Commissioner, sans-serif',
          fontWeight: 500,
          fontSize: '3rem'
        }}
      >
        {value}
      </text>
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex + (cos >= 0 ? 1 : -1) * -11},${
          ey + (cos >= 0 ? 1 : -1) * -20
        }`}
        stroke={fill}
        fill="none"
        style={{ display: 'none' }}
      />

      <text
        x={ex + (cos >= 0 ? 1 : -1) * -12}
        y={ey + (cos >= 0 ? 1 : -1) * -21}
        dy={1}
        textAnchor={textAnchor}
        fill={CHART_TEXT_COLOR}
        style={{
          fontFamily: 'Commissioner, sans-serif',
          fontWeight: 600,
          fontSize: percent >= 0.6 ? '1.1rem' : '1.4rem'
        }}
      >
        {`${(percent * 100).toFixed(2)} %`}
      </text>
    </g>
  );
};

ChartsWidget.defaultProps = {
  totalBroken: '0'
};

ChartsWidget.propTypes = {
  totalBroken: PropTypes.string,
  donutChartData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number
    })
  ).isRequired,
  areaChartData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      uv: PropTypes.number
    })
  ).isRequired
};

export default ChartsWidget;
