import React from 'react';
import PropTypes from 'prop-types';
// import { toast } from 'react-toastify';
// import Toggle from 'react-toggle';
// import Dropdown from '../DropDown';
// import IconButton from '../IconButton/IconButton';
// import { APP_ICONS, ERRORS } from '../../constants';
import TextField from '../TextField/TextField';
// import Button from '../Button/Button';
import 'react-toggle/style.css';
import '../Config/Config.scss';

const OrgForm = ({ showUrl, orgName, orgUrl, setOrgName, setOrgUrl }) => {
  const handleOrgNameChange = e => {
    setOrgName(e.target.value);
  };

  const handleOrgUrlChange = e => {
    setOrgUrl(e.target.value);
  };

  return (
    <div className="config_modal__inputs">
      {showUrl && (
        <div
          style={{
            marginBottom: '2rem'
          }}
        >
          <TextField
            type="text"
            label="Enter URL"
            size="small"
            placeholder="https://"
            value={orgUrl}
            changeHandler={handleOrgUrlChange}
            // statusMessage={urlError.statusMessage}
            // status={urlError.status}
            // onTouch={() => {
            //   setIsTouched(true);
            // }}
            // isTouched={isTouched}
            maxLength={255}
            subLabel="Max: 255 characters"
          />
        </div>
      )}

      <div
        style={{
          marginBottom: '2rem'
        }}
      >
        <TextField
          type="text"
          label="Org Name"
          size="small"
          placeholder="Enter the Name for Organization"
          value={orgName}
          changeHandler={handleOrgNameChange}
          maxLength={32}
          subLabel="Max: 32 characters"
        />
      </div>
    </div>
  );
};

OrgForm.defaultProps = {
  showUrl: true,
  orgName: '',
  orgUrl: '',
  setOrgUrl: () => {}
};

OrgForm.propTypes = {
  // isOpen: PropTypes.bool.isRequired
  showUrl: PropTypes.bool,
  orgName: PropTypes.string,
  orgUrl: PropTypes.string,
  setOrgName: PropTypes.func.isRequired,
  setOrgUrl: PropTypes.func
};

export default OrgForm;
