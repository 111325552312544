import React, { useContext } from 'react';
import { ApolloClient, InMemoryCache, createHttpLink, ApolloProvider } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { useAuth0 } from '@auth0/auth0-react';
import { PropTypes } from 'prop-types';
import { LOCAL_KEYS } from '../constants';
import { getLocalStorageKey } from './index';
import userTypeContext from '../contexts/userTypeContext';

const base64 = require('base-64');

const AuthorizedApolloProvider = ({ children, type }) => {
  const { authType } = useContext(userTypeContext);

  const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();

  const endpoint =
    isAuthenticated && !isLoading
      ? `${process.env.REACT_APP_API_ENDPOINT}`
      : `${process.env.REACT_APP_API_ENDPOINT}chc-app-test-guest`;

  const httpLink = createHttpLink({
    uri: endpoint
  });

  const authLink = setContext(async () => {
    let token = '';
    if (isAuthenticated && !isLoading) {
      // get the authentication token from local storage if it exists
      token = await getAccessTokenSilently();
    } else if (authType === 'GUEST') {
      token = base64.decode(getLocalStorageKey(LOCAL_KEYS.GUEST_TOKEN));
    }

    if (token.length > 0) token = `Bearer ${token}`;
    // console.log('endpoint', token);
    return {
      headers: {
        authorization: token || ''
      }
    };
  });

  const Client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
  });
  return <ApolloProvider client={Client}>{children}</ApolloProvider>;
};

AuthorizedApolloProvider.propTypes = {
  children: PropTypes.element.isRequired,
  type: PropTypes.string.isRequired
};

export default AuthorizedApolloProvider;
