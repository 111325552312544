import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import Button, { BUTTON_APPEARANCE, BUTTON_SIZE } from '../Button/Button';
import { APP_ICONS } from '../../constants';
import Select from '../Select/Select';
import './ButtonDropdown.scss';

const ButtonDropdown = ({ options, onChangeHandler, selectedOption }) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const eventHandler = event => {
      setIsOpen(false);
    };

    document.body.addEventListener('click', eventHandler);

    return () => {
      document.body.removeEventListener('click', eventHandler);
    };
  }, [selectedOption, onChangeHandler]);

  const toggleHandler = event => {
    event.stopPropagation();
    setIsOpen(prevState => !prevState);
  };

  return (
    <div className="btn_dropdown">
      <div className="btn_dropdown__box">
        <Button
          label="Add/Remove Columns"
          icon={APP_ICONS.ADD_REMOVE_COLUMN_ICON}
          type="button"
          name="add"
          size={BUTTON_SIZE.NANO}
          appearance={BUTTON_APPEARANCE.TERTIARY}
          clickHandler={toggleHandler}
        />
      </div>

      <Select
        isOpen={isOpen}
        options={options}
        multi
        onChangeHandler={onChangeHandler}
        showIcon={false}
      />
    </div>
  );
};

ButtonDropdown.propTypes = {
  options: propTypes.arrayOf(
    propTypes.shape({
      label: propTypes.string,
      value: propTypes.string
    })
  ).isRequired,
  onChangeHandler: propTypes.func.isRequired,
  selectedOption: propTypes.arrayOf(
    propTypes.shape({
      label: propTypes.string,
      value: propTypes.string
    })
  ).isRequired
};
export default ButtonDropdown;
