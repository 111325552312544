/*

This component is to be used to represent the summary data of a scan. It takes only one JSON object in the following format - 

{
  "heading": {
    "title": string,
    "subTitle": string
  },
  "data": [
    {
      "icon": string,
      "label": string,
      "value": string | number
    }
  ]
}

*/

import React from 'react';
import propTypes from 'prop-types';

import Card from '../../containers/Card/Card';

import './SummaryTab.scss';
import sprite from '../../assets/sprite.svg';
import { APP_ICONS } from '../../constants';
import ChartsWidget from '../ChartsWidget/ChartsWidget';
import TableWidgetRow from '../Widgets/TableWidgetRow';
import WidgetLayout from '../../containers/layout/WidgetLayout';
// import DashboardSkeleton from '../DashboardSkeleton/DashboardSkeleton';

const Summary = ({ data }) => (
  <div className="summary__widgets-container">
    <div className="summary__top-row">
      <Card className="summary__charts ">
        <ChartsWidget
          totalBroken={data.totalBroken}
          donutChartData={data.donutChartData}
          areaChartData={data.areaChartData}
        />
      </Card>
      <Card className="summary__links ">
        <div className="summary__links-wrapper">
          <div className="summary__widget-header">
            <div className="summary__widget-title">All Links</div>
          </div>
          <div className="divider" />
          <div className="summary__widget-body">
            <div className="summary__widget-body-left">
              <ul>
                <li>
                  <svg className="summary__widget-body-left-icon">
                    <use href={`${sprite}#${APP_ICONS.SEARCH_ICON_PRIMARY}`} />
                  </svg>
                  <span>Pages Scanned</span>
                </li>
                <li>
                  <svg className="summary__widget-body-left-icon">
                    <use href={`${sprite}#${APP_ICONS.CHECK_CIRCLE_ICON}`} />
                  </svg>
                  <span>Links Checked</span>
                </li>
                <li>
                  <svg className="summary__widget-body-left-icon">
                    <use href={`${sprite}#${APP_ICONS.BROKEN_LINK_ICON}`} />
                  </svg>
                  <span>Total Broken Links</span>
                </li>
                <li>
                  <svg className="summary__widget-body-left-icon">
                    <use href={`${sprite}#${APP_ICONS.CLOCK_ICON}`} />
                  </svg>
                  <span>Total Scan Time</span>
                </li>
              </ul>
            </div>
            <div className="summary__widget-body-right --flex-horizontal-right">
              <ul>
                <li>
                  <span className="margin__1 --bold">
                    {data === null ? 0 : data.noPagesScanned}
                  </span>
                </li>
                <li>
                  <span className="margin__1 --bold">
                    {data === null ? 0 : data.noLinksChecked}
                  </span>
                </li>
                <li>
                  <span className="margin__1 --bold">
                    {(data === null) === null ? 0 : data.totalBroken}
                  </span>
                </li>
                <li>
                  <span className="margin__1 --bold">
                    {(data === null) === null ? 0 : parseFloat(data.totalScanTime).toFixed(2)}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Card>
    </div>
    <div className="summary__bottom-row">
      <WidgetLayout
        classes="summary__widget summary__card-bottom"
        title="Issue Types"
        subTitle="Nos. | Percentage"
      >
        <TableWidgetRow data={data.issueTypesData} />
      </WidgetLayout>
      <WidgetLayout
        classes="summary__widget summary__card-bottom"
        title="Link Types"
        subTitle="Nos. | Percentage"
      >
        <TableWidgetRow data={data.linkTypesData} />
      </WidgetLayout>
      <WidgetLayout
        classes="summary__widget summary__card-bottom"
        title="Top Hosts"
        subTitle="Nos. | Percentage"
      >
        <TableWidgetRow data={data.hostsData} type="secondary" />
      </WidgetLayout>
    </div>
  </div>
);

Summary.propTypes = {
  data: propTypes.shape({
    totalBroken: propTypes.string,
    totalScanTime: propTypes.string,
    donutChartData: propTypes.arrayOf(
      propTypes.shape({
        name: propTypes.string,
        value: propTypes.number
      })
    ),
    areaChartData: propTypes.arrayOf(
      propTypes.shape({
        name: propTypes.string,
        uv: propTypes.number,
        y: propTypes.number
      })
    ),
    noPagesScanned: propTypes.number,
    noLinksChecked: propTypes.number,
    issueTypesData: propTypes.shape({
      list: propTypes.arrayOf(
        propTypes.shape({
          title: propTypes.string,
          count: propTypes.number
        })
      ),
      total: propTypes.number
    }),
    linkTypesData: propTypes.shape({
      list: propTypes.arrayOf(
        propTypes.shape({
          title: propTypes.string,
          count: propTypes.number
        })
      ),
      total: propTypes.number
    }),
    hostsData: propTypes.shape({
      list: propTypes.arrayOf(
        propTypes.shape({
          title: propTypes.string,
          count: propTypes.number
        })
      ),
      total: propTypes.number
    })
  }).isRequired
};

export default Summary;
