/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import propTypes from 'prop-types';
import Button from '../Button/Button';
import sprite from '../../assets/sprite.svg';
import { APP_ICONS } from '../../constants';
import Modal, { ModalBody, ModalHeader, ModalFooter } from '../../containers/Modal';
import IconButton from '../IconButton/IconButton';
// import ConfigForm from './ConfigForm';

const ConfigModal = ({
  // dataSelected,
  isOpen,
  setShow,
  configTitle,
  addClass,
  disableSaveButton,
  disableSavAndRunButton,
  handleSaveButtonClick,
  handleSaveAndRunButtonClick,
  modelBodyClass,
  showSaveAndRunButton,
  saveLoading,
  saveAndRunLoading,
  saveButtonText,
  saveAndRunButtonText,
  children
}) => (
  <Modal show={isOpen} setShow={setShow} addClass={`config_modal ${addClass}`}>
    <ModalHeader addClass="config_modal__header">
      <div className="config_modal__icon-box">
        <svg className="config_modal__icon">
          <use href={`${sprite}#${APP_ICONS.PLUS_SQUARE}`} />
        </svg>

        <h2>{configTitle}</h2>
      </div>
      <div>
        <IconButton
          icon={APP_ICONS.CROSS}
          onClickHandler={() => {
            setShow(false);
          }}
        />
      </div>
    </ModalHeader>
    <ModalBody addClass={`config_modal__body ${modelBodyClass}`}>
      {/* <ConfigForm
          ref={configFormRef}
          dataSelected={dataSelected}
          setShow={setShow}
          type={type}
          newJob={newJob}
          triggerReload={triggerReload}
          handleFreshConfig={handleFreshConfig}
          urlError={urlError}
          setUrlError={setUrlError}
        /> */}
      {children}
    </ModalBody>
    <ModalFooter addClass="config_modal__footer">
      <div className="config_modal__buttons--footer">
        <Button
          label={saveButtonText}
          name="save"
          type="button"
          appearance="secondary"
          isDisabled={disableSaveButton}
          loading={saveLoading}
          customStyleClass="config_modal__button"
          clickHandler={handleSaveButtonClick}
        />
        {showSaveAndRunButton && (
          <Button
            label={saveAndRunButtonText}
            name="saveAndRun"
            isDisabled={disableSavAndRunButton}
            type="button"
            customStyleClass="config_modal__button"
            loading={saveAndRunLoading}
            // clickHandler={() => configFormRef?.current?.disPatchHandleRun()}
            clickHandler={handleSaveAndRunButtonClick}
          />
        )}
      </div>
    </ModalFooter>
  </Modal>
);

ConfigModal.defaultProps = {
  dataSelected: {},
  // triggerReload: () => {},
  // handleFreshConfig: () => {},
  children: null,
  addClass: '',
  modelBodyClass: '',
  showSaveAndRunButton: true,
  saveAndRunLoading: false,
  saveLoading: false,
  disableSavAndRunButton: false,
  disableSaveButton: false,
  saveAndRunButtonText: 'Save and Run',
  saveButtonText: 'Save',
  handleSaveAndRunButtonClick: e => {}
};
ConfigModal.propTypes = {
  dataSelected: propTypes.shape({
    __typename: propTypes.string,
    config_name: propTypes.string,
    exclusion: propTypes.string,
    id: propTypes.string,
    inclusion: propTypes.string,
    last_active: propTypes.bool,
    user_id: propTypes.string,
    website_url: propTypes.string
  }),
  isOpen: propTypes.bool.isRequired,
  setShow: propTypes.func.isRequired,
  // type: propTypes.oneOf(['ADD', 'UPDATE']).isRequired,
  // newJob: propTypes.func.isRequired,
  // triggerReload: propTypes.func,
  // handleFreshConfig: propTypes.func,
  configTitle: propTypes.string.isRequired,
  children: propTypes.node,
  disableSaveButton: propTypes.bool,
  disableSavAndRunButton: propTypes.bool,
  handleSaveButtonClick: propTypes.func.isRequired,
  handleSaveAndRunButtonClick: propTypes.func,
  addClass: propTypes.string,
  modelBodyClass: propTypes.string,
  showSaveAndRunButton: propTypes.bool,
  saveAndRunLoading: propTypes.bool,
  saveLoading: propTypes.bool,
  saveAndRunButtonText: propTypes.string,
  saveButtonText: propTypes.string
};
export default ConfigModal;
