import React from 'react';
import propTypes from 'prop-types';
import PercentageBar from '../Charts/PercentageBar';

const RowRight = ({ data, type }) => (
  <div>
    <span className="margin__1 width__20 --flex-horizontal-right" style={{ width: '10%' }}>
      {data.count}
    </span>
    <span style={{ width: '90%' }}>
      <PercentageBar percentage={data.percent} type={type} />
    </span>
  </div>
);

RowRight.propTypes = {
  data: propTypes.shape({
    title: propTypes.string,
    count: propTypes.number,
    percent: propTypes.number
  }).isRequired,
  type: propTypes.string.isRequired
};

export default RowRight;
