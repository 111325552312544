import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import propTypes from 'prop-types';
import handleGoogleAnalytics from '../../utils/googleAnalytics';
import './Header.scss';
import SvgLogo from '../icons/Logo';
import LogoMobile from '../../assets/Logo_mobile.svg';
import TextContent from '../../languages';
import Button from '../Button/Button';

const Header = ({ showLogin }) => {
  const { loginWithRedirect, isAuthenticated, logout, isLoading } = useAuth0();

  const userLogout = () => logout({ returnTo: window.location.origin });
  const userLogin = () => {
    handleGoogleAnalytics(
      {
        event: 'v2_login_button_click'
      },
      'NO_TOKEN'
    );
    loginWithRedirect();
  };

  return (
    <header className="sc-header">
      <Link to="/" className="sc-header__logo">
        <SvgLogo customStyle="sc_header__logo__img" />
        <img className="sc-header__logo-img" src={LogoMobile} alt="site checker" />
      </Link>
      {showLogin && !isLoading && (
        <Button
          type="button"
          name="login"
          customStyleClass="--login"
          label={isAuthenticated ? TextContent.LOGOUT : TextContent.LOGIN}
          clickHandler={isAuthenticated ? userLogout : userLogin}
        />
      )}
    </header>
  );
};

Header.defaultProps = {
  showLogin: true
};

Header.propTypes = {
  showLogin: propTypes.bool
};

export default Header;
