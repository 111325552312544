import axios from 'axios';
/**
 *  Common utility function
 */

/**
 * Email id validation
 * @param {String} email
 */
export function ValidateEmail(email) {
  const emailRegx =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegx.test(email);
}

/**
 * URL validation
 * @param {String} email
 */
export function isValidURL(string) {
  const url = string.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g
  );
  return url !== null;
}

/**
 * Get and set local storage key
 */
export function setLocalStorageKey(key, value) {
  localStorage.setItem(key, value);
}
export function getLocalStorageKey(key) {
  const value = localStorage.getItem(key);
  return value;
}
export function removeLocalStorageKey(key) {
  const value = localStorage.removeItem(key);
  return value;
}

/**
 * API Request Utils
 */
export function createAxiosConnection(endpoint) {
  return axios.create({
    baseURL: endpoint
  });
}
