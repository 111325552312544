import React, { useState, useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import propTypes from 'prop-types';
import { toast } from 'react-toastify';
import Button from '../Button/Button';
import TextField from '../TextField/TextField';
import Dropdown from '../DropDown';
import IconButton from '../IconButton/IconButton';
import { APP_ICONS } from '../../constants';
// import GET_REQUEST from '../../graphql/queries/Request';
import UPDATE_CONFIG from '../../graphql/mutations/UpdateConfig';
import SettingsDeleteModal from './SettingsDeleteModal';
import handleGoogleAnalytics from '../../utils/googleAnalytics';
import 'react-toastify/dist/ReactToastify.css';

import './SettingsTab.scss';
import FETCH_CONFIG_DETAILS from '../../graphql/queries/Configuration';

const SettingsTab = ({ requestID, currentTab }) => {
  const notify = (type, text) =>
    type === 'SUCCEEDED'
      ? toast.success(text || 'Success!', {
          position: toast.POSITION.BOTTOM_RIGHT,
          toastId: customId
        })
      : toast.error(text || 'Failure', {
          position: toast.POSITION.BOTTOM_RIGHT,
          toastId: customId
        });
  const [rowData, setRowData] = useState([
    { id: Math.random().toString(), inclorexcl: '', pattern: '' }
  ]);
  const [dataSelected, setDataSelected] = useState({});

  const [showModal, setShowModal] = useState(false);
  const [selectedRow, setRow] = useState('');
  const [disableSave, setDisable] = useState(true);
  const customId = 'custom-id-settings';

  const [loadOptions] = useLazyQuery(FETCH_CONFIG_DETAILS, {
    fetchPolicy: 'cache-and-network',
    onCompleted: res => {
      const data = res.request;

      // if (data.inclusion || data.exclusion) setDataSelected(data);
      // else setDataSelected({ id: Math.random().toString(), inclorexcl: '', pattern: '' });

      setDataSelected(data);
      // setRowData([{ id: Math.random().toString(), inclorexcl: '', pattern: '' }]);
    },
    onError: err => {
      notify('FAILURE', err.message);
      // eslint-disable-next-line no-console
      console.error(err);
    }
  });
  const [updateConfig] = useMutation(UPDATE_CONFIG, {
    onCompleted: () => {
      notify('SUCCEEDED', 'Updated Config successfully!');
      setDisable(true);
      loadOptions({
        variables: {
          id: requestID
        }
      });
    },
    onError: err => {
      if (err.graphQLErrors) {
        // eslint-disable-next-line no-console
        console.error(err.graphQLErrors);
      }
      if (err.networkError) {
        // eslint-disable-next-line no-console
        console.error(err.networkError.result.errors);
      }
    }
  });
  useEffect(() => {
    loadOptions({
      variables: {
        id: requestID
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   loadOptions({
  //     variables: {
  //       requestId: requestID
  //     }
  //   });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [requestID]);

  useEffect(() => {
    if (currentTab === '3') {
      loadOptions({
        variables: {
          id: requestID
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab]);

  useEffect(() => {
    if (dataSelected && dataSelected.config_name) {
      let arr = [];
      if (dataSelected.inclusion) {
        let temp = dataSelected.inclusion.split(',');

        temp = temp.map(item => ({
          id: Math.random().toString(),
          inclorexcl: 'Include',
          pattern: item
        }));
        if (arr.length > 0) arr = [arr, ...temp];
        else arr = [...temp];

        setRowData([...arr]);
      }

      if (dataSelected.exclusion) {
        let temp = dataSelected.exclusion.split(',');

        temp = temp.map(item => ({
          id: Math.random().toString(),
          inclorexcl: 'Exclude',
          pattern: item
        }));

        if (arr.length > 0) arr = arr.concat(temp);
        else arr = temp;

        setRowData([...arr]);
      }

      if (!dataSelected.inclusion && !dataSelected.exclusion) {
        setRowData([{ id: Math.random().toString(), inclorexcl: '', pattern: '' }]);
      }
    }
  }, [dataSelected]);

  const handleChange = (e, i) => {
    if (disableSave) setDisable(false);
    const { value, name } = e.target;

    const newState = [...rowData];
    newState[i] = {
      ...newState[i],
      [name]: value
    };

    setRowData(newState);
  };
  // console.log(dataSelected, 'dt');
  const handleSave = () => {
    const updateConfigObj = {
      updateConfigId: dataSelected.id,
      config: {
        inclusion: null,
        exclusion: null,
        url: dataSelected.url
      }
    };
    let inclusion = '';
    let exclusion = '';
    rowData.forEach(row => {
      if (row.inclorexcl === 'Include') {
        inclusion += `${row.pattern},`;
      } else if (row.inclorexcl === 'Exclude') {
        exclusion += `${row.pattern},`;
      }
    });
    if (inclusion.length > 0 || exclusion.length > 0)
      updateConfigObj.config = { inclusion: null, exclusion: null, url: dataSelected.url };
    if (inclusion.length > 0) updateConfigObj.config.inclusion = inclusion.slice(0, -1);
    if (exclusion.length > 0) updateConfigObj.config.exclusion = exclusion.slice(0, -1);

    updateConfig({
      variables: { ...updateConfigObj }
    });
  };

  const handleDelete = () => {
    const idTemp = selectedRow;
    const temp = [...rowData];

    temp.splice(
      temp.findIndex(item => item.id === idTemp),
      1
    );
    if (!temp.length) {
      temp.push({ id: Math.random().toString(), inclorexcl: '', pattern: '' });
    }

    setRowData([...temp]);
    setShowModal(false);
    setDisable(false);

    handleGoogleAnalytics({
      event: 'v2_del_settings_tab_filter',
      request_id: requestID,
      config: dataSelected.config_name
    });
  };

  const renderRows = () => {
    const arr = [...rowData];

    return arr.map(({ id, inclorexcl, pattern }, index) => (
      <div key={id} className="settings__row">
        <div className="settings__select">
          <Dropdown
            addClass="settings-dropdown settings-select"
            label="Include/ Exclude"
            selectedOption={{ label: inclorexcl, value: inclorexcl }}
            options={[
              { label: 'Include', value: 'Include' },
              { label: 'Exclude', value: 'Exclude' }
            ]}
            position="BOTTOM"
            onChangeHandler={e => {
              const temp = [...rowData];
              temp[index] = { ...temp[index], inclorexcl: e.value };
              setRowData([...temp]);
              setDisable(false);
            }}
          />
        </div>
        <div className="settings__pattern">
          <TextField
            type="text"
            name="pattern"
            size="small"
            label="String/Text"
            placeholder="Enter string to match"
            value={pattern || ''}
            changeHandler={e => {
              handleChange(e, index);
            }}
            isDisabled={rowData[0].inclorexcl === ''}
          />
        </div>
        <div role="none" className="settings__icon--delete" value={`${id}OOG`}>
          <IconButton
            icon={APP_ICONS.DELETE_ICON}
            onClickHandler={() => {
              setRow(id);
              setShowModal(true);
            }}
          />
        </div>
      </div>
    ));
  };

  return (
    <div className="settings">
      <SettingsDeleteModal handleDelete={handleDelete} show={showModal} setShow={setShowModal} />
      <div className="settings__row-container">{renderRows()}</div>
      <div className="settings__buttons">
        <Button
          label="Add row"
          type="button"
          size="small"
          name="addRow"
          appearance="secondary"
          customStyleClass="settings__button"
          clickHandler={() => {
            handleGoogleAnalytics({
              event: 'v2_new_settings_tab_filter',
              request_id: requestID,
              config: dataSelected.config_name
            });
            setRowData([...rowData, { id: Math.random().toString(), pattern: '', inclorexcl: '' }]);
          }}
        />
        <Button
          label="Save"
          type="button"
          name="save"
          isDisabled={disableSave}
          appearance="primary"
          customStyleClass="settings__button"
          clickHandler={() => {
            handleSave();
          }}
        />
      </div>
    </div>
  );
};
SettingsTab.defaultProps = {
  requestID: ''
};
SettingsTab.propTypes = {
  requestID: propTypes.string,
  currentTab: propTypes.string.isRequired
};

export default SettingsTab;
