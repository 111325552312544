import CommonContent from './common.json';
import HomeContent from './en/home.json';
import ConfirmationContent from './en/confirmation.json';
import FooterContent from './en/footer.json';
import ResetContent from './en/reset.json';
import TableContent from './en/table.json';

const TextContent = {
  ...CommonContent,
  ...HomeContent,
  ...ConfirmationContent,
  ...FooterContent,
  ...ResetContent,
  ...TableContent
};
export default TextContent;
