import React from 'react';
import propTypes from 'prop-types';

import Modal, { ModalBody, ModalFooter, ModalHeader } from '../../containers/Modal';
import { APP_ICONS } from '../../constants';
import Button from '../Button/Button';

import sprite from '../../assets/sprite.svg';

import './AbortModal.scss';

const AbortModal = ({ show, setShow, handleAbort }) => (
  <Modal show={show} setShow={setShow} addClass="abort_modal">
    <ModalHeader>
      <div className="abort_modal__header">
        <svg className="abort_modal__icon">
          <use href={`${sprite}#${APP_ICONS.EXCLAMATION}`} />
        </svg>
        <h2>Abort Scan</h2>
      </div>
      <div className="divider" />
    </ModalHeader>
    <ModalBody>
      <div className="abort_modal__body">
        <div className="abort_modal__body-text">
          Do you want to abort the site scan for broken links?
        </div>
      </div>
    </ModalBody>
    <ModalFooter>
      <div className="abort_modal__footer">
        <Button
          label="Cancel"
          type="button"
          name="cancel"
          appearance="secondary"
          customStyleClass="abort_modal__footer-button"
          clickHandler={() => {
            setShow(false);
          }}
        />

        <Button
          label="Yes"
          type="button"
          name="yes"
          customStyleClass="abort_modal__footer-button"
          clickHandler={handleAbort}
        />
      </div>
    </ModalFooter>
  </Modal>
);
AbortModal.defaultProps = {};
AbortModal.propTypes = {
  show: propTypes.bool.isRequired,
  setShow: propTypes.func.isRequired,
  handleAbort: propTypes.func.isRequired
};
export default AbortModal;
