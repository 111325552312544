import getToken from './getToken';

export default function handleGoogleAnalytics(reqObj, type = 'WITH_TOKEN') {
  const token = getToken();
  // DEEP COPY
  const obj = JSON.parse(JSON.stringify(reqObj));

  if (type !== 'NO_TOKEN' && token !== 'NONE') obj.user_token = token;

  window.dataLayer.push(obj);
}
