import * as React from 'react';
import { PropTypes } from 'prop-types';
import Card from '../Card/Card';

import './Layout.scss';

const WidgetLayout = ({ children, classes, title, subTitle }) => (
  <Card className={`widget ${classes} `}>
    <div className="widget__header">
      <div className="widget__title">{title}</div>
      <div className="widget__subtitle">{subTitle}</div>
    </div>
    <div className="divider" />
    <div className="widget__body">{children}</div>
  </Card>
);

WidgetLayout.propTypes = {
  children: PropTypes.element.isRequired,
  classes: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired
};

export default WidgetLayout;
