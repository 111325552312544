/* eslint-disable react-hooks/exhaustive-deps */
import { useLazyQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import LOGIN from '../graphql/queries/Login';

export default function useLogin() {
  const { isAuthenticated } = useAuth0();
  const [login, { loading: loginLoading, data: loginResponse, error: loginError }] = useLazyQuery(
    LOGIN
    // {
    //   // fetchPolicy: 'cache-and-network',
    //   onCompleted: res => {
    //     firstRender.current = false;
    //   },
    //   onError: err => {
    //     // notify('FAILURE', err.message);
    //     // eslint-disable-next-line no-console
    //     console.error('LOGIN ERROR', err);
    //   }
    // }
  );

  useEffect(() => {
    if (isAuthenticated) {
      login();
    }
  }, [isAuthenticated, login]);

  return { loginError, loginLoading, loginResponse, login };
}
