import { useMutation } from '@apollo/client';
import CREATE_REQUEST from '../../graphql/mutations/CreateRequest';
import { FETCH_CONFIG_BY_WEBSITE_ID } from '../../graphql/queries/Requests';

export default function useCreateConfig() {
  const [createConfiguration, { loading, data, error }] = useMutation(CREATE_REQUEST, {
    refetchQueries: [FETCH_CONFIG_BY_WEBSITE_ID]
  });
  return {
    createConfiguration,
    createConfigurationLoading: loading,
    createConfigurationResponse: data,
    createConfigurationError: error
  };
}
