import { useState } from 'react';

export default function useOrgForm() {
  const [openEditOrg, setOpenEditOrg] = useState(false);
  const [selectedDeleteOrgId, setSelectedDeleteOrgId] = useState('');
  const [selectedDeleteOrg, setSelectedDeleteOrg] = useState('');
  const [orgName, setOrgName] = useState('');
  // const [orgUrl, setOrgUrl] = useState('');

  const resetOrgForm = () => {
    setOrgName('');
    // setOrgUrl('');
  };

  return {
    openEditOrg,
    orgName,
    // orgUrl,
    selectedDeleteOrgId,
    selectedDeleteOrg,
    setOpenEditOrg,
    setOrgName,
    // setOrgUrl,
    resetOrgForm,
    setSelectedDeleteOrgId,
    setSelectedDeleteOrg
  };
}
