import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import handleGoogleAnalytics from '../../utils/googleAnalytics';
import './SubHeader.scss';
import Button, { BUTTON_APPEARANCE, BUTTON_SIZE } from '../Button/Button';
import TabNav from '../TabNav/TabNav';
import { APP_ICONS } from '../../constants';
import SummaryTab from '../SummaryTab/SummaryTab';
import DetailsTab from '../DetailsTab';
import SettingsTab from '../SettingsTab/SettingsTab';
import ButtonDropdown from '../ButtonDropdown/ButtonDropdown';
import DashboardSkeleton from '../DashboardSkeleton/DashboardSkeleton';

import GET_EXCELREPORT from '../../graphql/queries/ExcelReports';

const SubHeader = ({
  showLogin,
  disableOtherTabs,
  summaryData,
  jobId,
  firstDataReceived,
  requestId,
  scanStatus,
  showSettings,
  showDownload
}) => {
  const [currentTab, setCurrentTab] = useState('1');
  const [isSmallWidth, setIsSamllWidth] = useState(false);
  const [jobID, setjobID] = useState('');

  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      const bodyElement = entries[0];

      if (bodyElement.contentRect.width <= 574) setIsSamllWidth(true);
      else setIsSamllWidth(false);
    });

    resizeObserver.observe(document.documentElement);

    return () => resizeObserver.disconnect();
  }, []);

  useEffect(() => {
    if (scanStatus === 'INITIALISING')
      handleGoogleAnalytics({
        event: 'v2_scan_initialise',
        job_id: jobId
      });
    else if (scanStatus === 'STARTING')
      handleGoogleAnalytics({
        event: 'v2_scan_starting',
        job_id: jobId
      });
    else if (scanStatus === 'RUNNING')
      handleGoogleAnalytics({
        event: 'v2_scan_inprogress',
        job_id: jobId
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scanStatus]);

  const dropdownOptions = [
    { label: 'SNo', value: 'sno' },
    { label: 'Link', value: 'link' },
    { label: 'Parent Page', value: 'page' },
    { label: 'Result', value: 'link_status' },
    { label: 'Type', value: 'link_location' }
  ];
  const [selected, setSelected] = useState([]);
  const tableColumns = [
    {
      Header: '',
      accessor: 'sno',
      width: '4'
    },
    {
      Header: 'Link',
      accessor: 'link',
      width: '56.18'
    },
    {
      Header: 'Parent Page',
      accessor: 'page',
      width: '14.29'
    },
    {
      Header: 'Result',
      accessor: 'link_status',
      width: '14.29'
    },
    {
      Header: 'Type',
      accessor: 'link_location',
      width: '11.24'
    }
  ];
  const data = [
    {
      id: 1,
      heading: 'Summary',
      body: firstDataReceived ? <SummaryTab data={summaryData} /> : <DashboardSkeleton />
    },
    {
      id: 2,
      heading: 'Details',
      body: (
        <DetailsTab
          tableColumns={tableColumns}
          hiddenColumns={selected.map(item => item.value)}
          jobId={jobId}
          noLinksChecked={summaryData.noLinksChecked}
        />
      )
    }
  ];

  showSettings &&
    data.push({
      id: 3,
      heading: 'Settings',
      body: <SettingsTab requestID={requestId} currentTab={currentTab} />
    });

  const onSelectHandler = index => {
    setCurrentTab(index);
  };

  const onOptionChange = newSelected => {
    setSelected([...newSelected]);
  };

  const downloadReport = excelData => {
    if (excelData.download_link) {
      window.location.href = excelData.download_link;
      setjobID('');
    }
  };

  const [downloadExcel] = useLazyQuery(GET_EXCELREPORT, {
    variables: { jobId: jobID },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: res => {
      if (res.excelReport) {
        const excelData = res.excelReport;
        downloadReport(excelData);
      }
    },
    onError: err => {
      if (err.graphQLErrors) {
        // eslint-disable-next-line no-console
        console.error(err.graphQLErrors);
      }
      if (err.networkError) {
        // eslint-disable-next-line no-console
        console.error(err.networkError.result.errors);
      }
    }
  });

  const handleDownload = () => {
    setjobID(jobId);
    downloadExcel();
    handleGoogleAnalytics({
      event: 'v2_download_report',
      job_id: jobId
    });
  };

  if (currentTab !== '1' && disableOtherTabs === true) setCurrentTab('1');
  return (
    <div className="sh-cont__container">
      <div className="sh-cont__domain">
        <TabNav
          data={data}
          onSelectHandler={index => {
            if (index === '2')
              handleGoogleAnalytics({
                event: 'v2_details_tab',
                job_id: jobId
              });
            else if (index === '3')
              handleGoogleAnalytics({
                event: 'v2_settings_tab'
              });
            onSelectHandler(index);
          }}
          selected={currentTab}
          disableOtherTabs={disableOtherTabs}
          hiddenColumns={selected}
          rightAction={
            <div className="sh-cont__btn-grp">
              {currentTab === '2' ? (
                <ButtonDropdown
                  options={dropdownOptions}
                  onChangeHandler={onOptionChange}
                  selectedOption={selected}
                />
              ) : null}
              {currentTab !== '3' && showDownload ? (
                <div className="sh-cont__btn-container">
                  <Button
                    label={isSmallWidth ? '' : 'download'}
                    icon={APP_ICONS.DOWNLOAD}
                    type="button"
                    name="download"
                    size={isSmallWidth ? BUTTON_SIZE.MICRO : BUTTON_SIZE.NANO}
                    appearance={BUTTON_APPEARANCE.TERTIARY}
                    clickHandler={handleDownload}
                    isDisabled={disableOtherTabs || scanStatus === 'ABORTED'}
                  />
                </div>
              ) : null}
            </div>
          }
        />
        <div className="sh-cont__icon-box" />
      </div>
    </div>
  );
};

SubHeader.defaultProps = {
  showLogin: true,
  disableOtherTabs: false,
  firstDataReceived: false,
  jobId: '',
  showSettings: true,
  showDownload: true
};

SubHeader.propTypes = {
  showLogin: PropTypes.bool,
  disableOtherTabs: PropTypes.bool,
  jobId: PropTypes.string,
  summaryData: PropTypes.shape({
    totalBroken: PropTypes.string,
    totalScanTime: PropTypes.string,
    donutChartData: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.number
      })
    ),
    areaChartData: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        uv: PropTypes.number,
        y: PropTypes.number
      })
    ),
    noPagesScanned: PropTypes.number,
    noLinksChecked: PropTypes.number,
    issueTypesData: PropTypes.shape({
      list: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          count: PropTypes.number
        })
      ),
      total: PropTypes.number
    }),
    linkTypesData: PropTypes.shape({
      list: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          count: PropTypes.number
        })
      ),
      total: PropTypes.number
    }),
    hostsData: PropTypes.shape({
      list: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          count: PropTypes.number
        })
      ),
      total: PropTypes.number
    })
  }).isRequired,
  firstDataReceived: PropTypes.bool,
  requestId: PropTypes.string.isRequired,
  scanStatus: PropTypes.string.isRequired,
  showSettings: PropTypes.bool,
  showDownload: PropTypes.bool
};

export default SubHeader;
