import { useMutation } from '@apollo/client';
import DELETE_CONFIG from '../../graphql/mutations/DeleteConfig';
import { FETCH_CONFIG_BY_WEBSITE_ID } from '../../graphql/queries/Requests';
// import { USER_DATA_V1 } from '../graphql/queries/UserData';

export default function useDeleteConfig() {
  const [
    deleteConfig,
    { loading: deleteConfigLoading, data: deleteConfigResponse, error: deleteConfigError }
  ] = useMutation(DELETE_CONFIG, { refetchQueries: [FETCH_CONFIG_BY_WEBSITE_ID] });
  return { deleteConfig, deleteConfigError, deleteConfigResponse, deleteConfigLoading };
}
