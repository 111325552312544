/*

This component is to be used to create normal buttons. It can be configured using the following props -

placeholder - String inside the input field
value - should pass the value state for the input field 
icon - should be the id of icon svg
changeHandler - function that runs on change event
statusMessage - pass the string to be displayed below, pass it as empty or do not pass it to hide component
status='warning'|'error'|'success'-to be passed to change color of status and border of input field accordingly
type - type of input to be expected
textArea-boolean - should be true if the input field needs to be a text area
*/

import React from 'react';
import propTypes from 'prop-types';
import './TextField.scss';
import sprite from '../../assets/sprite.svg';
import { TEXTFIELD_STATUS } from '../../constants';
import TEXT_CONTENT from '../../languages';

const TextField = ({
  label,
  name,
  placeholder,
  icon,
  changeHandler,
  focusHandler,
  onTouch,
  isTouched,
  value,
  statusMessage,
  status,
  textArea,
  type,
  size,
  maxLength,
  subLabel,
  isDisabled
}) => {
  let fieldClass = 'input__field';
  if (
    status === TEXTFIELD_STATUS.WARNING ||
    status === TEXTFIELD_STATUS.ERROR ||
    status === TEXTFIELD_STATUS.SUCCESS
  )
    fieldClass += isTouched ? ` input__type-${status}` : '';

  if (size.length) fieldClass += ` input__size-${size}`;

  return (
    <div className="input" data-testid="input">
      {label && label.length > 0 && (
        <div className="input__label">
          {label}
          {subLabel && <span className="input__sub-label">{subLabel}</span>}
        </div>
      )}
      {!textArea ? (
        <div className="input__container">
          <input
            className={fieldClass}
            type={type}
            aria-label="input__field"
            placeholder={placeholder}
            onChange={changeHandler}
            onFocus={focusHandler}
            onBlur={onTouch}
            value={value}
            name={name}
            maxLength={maxLength}
            disabled={isDisabled}
          />
          <span className="input__icon__container" data-testid="icon">
            <svg className="input__icon">
              <title>{icon}</title>
              <use href={`${sprite}#${icon}`} />
            </svg>
          </span>
        </div>
      ) : (
        <div>
          <textArea
            className={
              status === TEXTFIELD_STATUS.WARNING ||
              status === TEXTFIELD_STATUS.ERROR ||
              status === TEXTFIELD_STATUS.SUCCESS
                ? `input__field-area input__type-${status}`
                : `input__field-area`
            }
            rows="50"
            cols="50"
            aria-label="input__field"
            placeholder={placeholder}
            onChange={changeHandler}
            value={value}
            name={name}
          />
        </div>
      )}
      {statusMessage && statusMessage.length > 0 && isTouched && (
        <div
          className={
            status === TEXTFIELD_STATUS.WARNING ||
            status === TEXTFIELD_STATUS.ERROR ||
            status === TEXTFIELD_STATUS.SUCCESS
              ? `input__status input__status-${status}`
              : `input__status`
          }
          aria-label="input__status-message"
        >
          {statusMessage}
        </div>
      )}
    </div>
  );
};

TextField.defaultProps = {
  label: '',
  placeholder: TEXT_CONTENT.TEXTFIELD_DEFAULT_PLACEHOLDER,
  icon: '',
  changeHandler: () => {},
  focusHandler: () => {},
  onTouch: () => {},
  isTouched: false,
  statusMessage: '',
  status: '',
  textArea: false,
  name: '',
  size: '',
  maxLength: null,
  subLabel: '',
  isDisabled: false
};

TextField.propTypes = {
  label: propTypes.string,
  placeholder: propTypes.string,
  icon: propTypes.string,
  changeHandler: propTypes.func,
  focusHandler: propTypes.func,
  onTouch: propTypes.func,
  isTouched: propTypes.bool,
  value: propTypes.string.isRequired,
  statusMessage: propTypes.string,
  status: propTypes.string,
  textArea: propTypes.bool,
  type: propTypes.string.isRequired,
  name: propTypes.string,
  size: propTypes.string,
  maxLength: propTypes.number,
  subLabel: propTypes.string,
  isDisabled: propTypes.bool
};

export default TextField;
