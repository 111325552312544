import { gql } from '@apollo/client';

const GET_USER = gql`
  query User {
    login {
      id
      email
      active
      auth_id
    }
  }
`;

const GET_USER_TYPE = gql`
  query getUserType {
    getUserType {
      userType
    }
  }
`;

const GET_ORG_USERS = gql`
  {
    fetchUsers {
      id
      is_blocked
      userDetails {
        id
        email
        first_name
        last_name
      }
      webDetails {
        id
        name
        url
        org_id
        created_by
      }
      orgDetails {
        id
        name
      }
      roleDetails {
        id
        name
      }
    }
  }
`;

const GET_EMAIL_USERS = gql`
  query fetchEmail($pattern: String!) {
    fetchEmail(pattern: $pattern) {
      id
      email
      first_name
      privilege {
        id
        is_blocked
        userDetails {
          id
          email
          first_name
        }
        webDetails {
          id
          name
          url
          org_id
        }
        orgDetails {
          id
          name
        }
        roleDetails {
          id
          name
        }
      }
    }
  }
`;

const GET_ORG_SEARCH_USERS = gql`
  query searchOrganization($pattern: String!) {
    searchOrganization(pattern: $pattern) {
      id
      email
      first_name
      privilege {
        id
        is_blocked
        userDetails {
          id
          email
          first_name
        }
        webDetails {
          id
          name
          url
          org_id
        }
        orgDetails {
          id
          name
        }
        roleDetails {
          id
          name
        }
      }
    }
  }
`;

const ORGS_ANDS_WEBS_FOR_USERS_PAGE = gql`
  query fetchOrgsForUserRole {
    fetchOrgsForUserRole {
      id
      name
      webList {
        id
        name
        url
        org_id
        created_by
      }
    }
  }
`;

export {
  GET_USER_TYPE,
  GET_ORG_USERS,
  GET_EMAIL_USERS,
  ORGS_ANDS_WEBS_FOR_USERS_PAGE,
  GET_ORG_SEARCH_USERS
};
export default GET_USER;
