import { gql } from '@apollo/client';

const CREATE_WEBSITE = gql`
  mutation createWebsite(
    $website_name: String!
    $url: String!
    $org_id: String
    $createJob: Boolean!
  ) {
    createWebsite(website_name: $website_name, url: $url, org_id: $org_id, createJob: $createJob) {
      id
      name
      url
      org_id
      created_by
      request {
        message
        request {
          id
          website_id
          user_id
          config_name
        }
      }
    }
  }
`;

export const UPDATE_WEBSITE = gql`
  mutation updateWebsite($website_name: String!, $id: String!) {
    updateWebsite(website_name: $website_name, id: $id) {
      id
      name
      url
      org_id
      created_by
    }
  }
`;

export const DELETE_WEBSITE = gql`
  mutation deleteWebsite($id: String!) {
    deleteWebsite(id: $id) {
      message
    }
  }
`;

export default CREATE_WEBSITE;
