import { gql } from '@apollo/client';

const USER_DATA = gql`
  query User {
    user {
      id
      requests {
        id
        config_name
        webasite_url
        last_active
        inclusion
        exclusion
        user_id
      }
    }
  }
`;

export const USER_DATA_V1 = gql`
  query User {
    getUser {
      id
      email
      website {
        id
        url
        name
        last_active
        created_by
        organization {
          id
          name
        }
        role {
          id
          name
        }
      }
      userOrganizations {
        id
        name
      }
      userType {
        userType
      }
    }
  }
`;
export default USER_DATA;
