/* eslint-disable react/jsx-props-no-spreading */

import React, { useEffect } from 'react';
import { useNavigate, Navigate, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import propTypes from 'prop-types';
import { removeLocalStorageKey } from '../../utils/index';
import { ERRORS, LOCAL_KEYS } from '../../constants';
import Loader from '../Loader/Loader';
import { useLogin } from '../../hooks';

const ProtectedRoute = ({ children }) => {
  const { user, isLoading, error, isAuthenticated } = useAuth0();
  const { loginLoading } = useLogin();
  const navigate = useNavigate();
  const { search } = useLocation();
  let mail = '';

  try {
    const temp = new URLSearchParams(search).get('error_description');
    if (temp !== null) {
      const [, email] = temp.split('&mail=');
      if (email) mail = email;
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
  }
  useEffect(() => {
    if (
      isLoading === false &&
      String(error).includes(ERRORS.EMAIL_VERIFICATION_ERROR) &&
      !loginLoading
    )
      navigate('/email-confirmation', { replace: true, state: { mail } });

    if (isAuthenticated) removeLocalStorageKey(LOCAL_KEYS.GUEST_ID);
  }, [isLoading, error, navigate, user, isAuthenticated, mail, loginLoading]);

  if (isLoading || loginLoading) {
    return (
      <div className="loading-flex">
        <Loader />
      </div>
    );
  }

  return isAuthenticated ? children : <Navigate to="/" />;
};

export default ProtectedRoute;

ProtectedRoute.propTypes = {
  children: propTypes.element.isRequired
};
