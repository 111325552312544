import { gql } from '@apollo/client';

const CREATE_REQUEST = gql`
  mutation CreateRequest(
    $url: String!
    $config_name: String!
    $createJob: Boolean
    $inclusion: String
    $exclusion: String
    $website_id: String!
    $isSPA: Boolean
  ) {
    CreateRequest(
      url: $url
      config_name: $config_name
      createJob: $createJob
      inclusion: $inclusion
      exclusion: $exclusion
      isSPA: $isSPA
      website_id: $website_id
    ) {
      message
      request {
        id
        url
        config_name
        inclusion
        exclusion
        active
        last_active
        isSPA
        error_page_string
        website_id
        user_id
      }
    }
  }
`;

export default CREATE_REQUEST;
