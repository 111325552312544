import React from 'react';
import propTypes from 'prop-types';
import Tabs, { TabPane } from 'rc-tabs';
import './TabNav.scss';

const TabNav = ({ data, onSelectHandler, selected, disableOtherTabs, rightAction }) => (
  <Tabs
    defaultActiveKey="1"
    onTabClick={tabIndex => onSelectHandler(tabIndex)}
    tabBarExtraContent={rightAction}
    activeKey={selected}
  >
    {data.map(({ heading, id, body }) => (
      <TabPane tab={heading} key={id} disabled={disableOtherTabs === true && id !== selected}>
        {body}
      </TabPane>
    ))}
  </Tabs>
);

TabNav.propTypes = {
  data: propTypes.arrayOf(
    propTypes.shape({
      id: propTypes.number,
      heading: propTypes.string,
      body: propTypes.node
    })
  ).isRequired,
  onSelectHandler: propTypes.func.isRequired,
  selected: propTypes.string.isRequired,
  disableOtherTabs: propTypes.bool.isRequired,
  rightAction: propTypes.node.isRequired
};

export default TabNav;
