import { gql } from '@apollo/client';

const REMOVE_USER = gql`
  mutation removeUserRole($id: String!) {
    removeUserRole(id: $id) {
      message
      success
    }
  }
`;

const BLOCK_USER = gql`
  mutation blockUserRole($id: String!) {
    blockUserRole(id: $id) {
      success
      message
    }
  }
`;

const UPDATE_USER_ROLE = gql`
  mutation updateUserRole($id: String!, $org_id: String!, $role_id: String!, $website_id: String) {
    updateUserRole(id: $id, org_id: $org_id, role_id: $role_id, website_id: $website_id) {
      success
      message
    }
  }
`;

const CREATE_USER_ROLE = gql`
  mutation createUserRole(
    $user_id: String!
    $org_id: String!
    $role_id: String!
    $website_id: String
  ) {
    createUserRole(user_id: $user_id, website_id: $website_id, role_id: $role_id, org_id: $org_id) {
      id
      user_id
      website_id
      org_id
      role_id
    }
  }
`;

const INVITE_USER_ORG = gql`
  mutation inviteUser($email: String!, $org_id: String!, $role_id: String!, $website_id: String) {
    inviteUser(email: $email, org_id: $org_id, role_id: $role_id, website_id: $website_id) {
      success
      message
    }
  }
`;

export { BLOCK_USER, UPDATE_USER_ROLE, CREATE_USER_ROLE, INVITE_USER_ORG };
export default REMOVE_USER;
