import { useMutation } from '@apollo/client';
import { UPDATE_USER_ROLE } from '../../graphql/mutations/userRole';
import { GET_EMAIL_USERS, GET_ORG_USERS } from '../../graphql/queries/User';

export default function useUpdateRole() {
  const [
    updateUserRole,
    { loading: updateUserRoleLoading, data: updateUserRoleResponse, error: updateUserRoleError }
  ] = useMutation(UPDATE_USER_ROLE, { refetchQueries: [GET_ORG_USERS, GET_EMAIL_USERS] });

  return {
    updateUserRole,
    updateUserRoleLoading,
    updateUserRoleError,
    updateUserRoleResponse: updateUserRoleResponse?.updateUserRole
  };
}
