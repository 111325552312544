import { getLocalStorageKey } from './index';
import { LOCAL_KEYS } from '../constants';

const base64 = require('base-64');

export default function getToken() {
  let token = getLocalStorageKey(LOCAL_KEYS.GUEST_TOKEN)
    ? base64.decode(getLocalStorageKey(LOCAL_KEYS.GUEST_TOKEN))
    : 'NONE';

  if (token.length > 0 && token !== 'NONE') token = `Bearer ${token}`;

  return token;
}
