import React from 'react';
import propTypes from 'prop-types';
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../../containers/Modal';
import Button from '../../components/Button/Button';
import './emailConfirmation.scss';

const ResendMailModal = ({ show, setShow, handleChangeMail }) => (
  <Modal show={show} setShow={setShow} addClass="resend_mail_modal">
    <ModalHeader>
      <div className="resend_mail_modal__header">
        <h2>Are you sure?</h2>
      </div>
    </ModalHeader>
    <ModalBody />
    <ModalFooter>
      <div className="resend_mail_modal__footer">
        <Button
          label="Yes"
          type="button"
          customStyleClass="resend_mail_modal__footer-button"
          clickHandler={handleChangeMail}
        />
        <Button
          label="No"
          type="button"
          appearance="secondary"
          customStyleClass="resend_mail_modal__footer-button"
          clickHandler={() => {
            setShow(false);
          }}
        />
      </div>
    </ModalFooter>
  </Modal>
);
ResendMailModal.defaultProps = {};
ResendMailModal.propTypes = {
  show: propTypes.bool.isRequired,
  setShow: propTypes.func.isRequired,
  handleChangeMail: propTypes.func.isRequired
};
export default ResendMailModal;
