import { useLazyQuery } from '@apollo/client';
import { useEffect, useState, useCallback } from 'react';
import FETCH_SELECTED_JOB_DETAILS from '../graphql/queries/fetchSelectedJob';
import { JOB_STATUS } from '../constants';

export default function useConfigJobInfo(props) {
  const [configJobInfo, setConfigJobInfo] = useState(null);
  const [jobSummary, setJobSummary] = useState(null);
  const [
    fetchConfigJobInfo,
    {
      loading: fetchConfigJobInfoLoading,
      data: configJobInfoData,
      refetch,
      startPolling,
      stopPolling
    }
  ] = useLazyQuery(FETCH_SELECTED_JOB_DETAILS, {
    // pollInterval: props?.pollingInterval || 0,
    fetchPolicy: 'network-only'
  });

  const computeSummary = useCallback(jobInfo => {
    if (jobInfo) {
      // if (
      //   JobDataSelected.fetchSelectedJob &&
      //   scanStatus !== JobDataSelected.fetchSelectedJob.status
      // ) {
      //   setScanStatus(JobDataSelected.fetchSelectedJob.status);
      //   // if (JobDataSelected.fetchSelectedJob && JobDataSelected.fetchSelectedJob.is_aborted) {
      //   //   setIsAborted(true);
      //   // } else {
      //   //   setIsAborted(false);
      //   // }
      //   // if (
      //   //   disableOtherTabs === false &&
      //   //   (JobDataSelected.fetchSelectedJob.status !== 'SUCCEEDED' ||
      //   //     JobDataSelected.fetchSelectedJob.status !== 'FAILED' ||
      //   //     JobDataSelected.fetchSelectedJob.status !== 'ABORTED' ||
      //   //     JobDataSelected.fetchSelectedJob.status !== 'None' ||
      //   //     JobDataSelected.fetchSelectedJob.status !== 'FINISHED')
      //   // ) {
      //   //   setDisableOtherTabs(true);
      //   // } else setDisableOtherTabs(false);
      // }
      const areaChartData = [
        { name: 'A', uv: 30, y: 70 },
        { name: 'B', uv: 12, y: 88 },
        { name: 'C', uv: 15, y: 85 },
        { name: 'D', uv: 35, y: 65 },
        { name: 'E', uv: 54, y: 46 },
        { name: 'F', uv: 72, y: 28 },
        { name: 'G', uv: 32, y: 68 }
      ];

      const donutChartData = [
        {
          name: 'Internal Broken Links',
          value: jobInfo.summary === null ? 0 : jobInfo.summary.internal
        },
        {
          name: 'External Broken Links',
          value: jobInfo.summary === null ? 0 : jobInfo.summary.external
        }
      ];
      let issueTypesData = {};
      let linkTypesData = {};
      let hostsData = {};

      if (jobInfo.summary !== null) {
        let totalIssueTypes = 0;
        let totalLinkTypes = 0;
        let totalHosts = 0;

        const temp1 = jobInfo.summary.issueTypes ? [...jobInfo.summary.issueTypes] : [];
        const temp2 = jobInfo.summary.linkTypes ? [...jobInfo.summary.linkTypes] : [];
        const temp3 = jobInfo.summary.hosts ? [...jobInfo.summary.hosts] : [];

        for (let i = 0; i < temp1.length; i += 1) {
          const temp = {
            title: temp1[i].title,
            count: temp1[i].count
          };

          totalIssueTypes += temp1[i].count;

          temp1[i] = temp;
        }
        issueTypesData = {
          list: temp1,
          total: totalIssueTypes
        };

        for (let i = 0; i < temp2.length; i += 1) {
          const temp = {
            title: temp2[i].title,
            count: temp2[i].count
          };

          totalLinkTypes += temp2[i].count;

          temp2[i] = temp;
        }

        linkTypesData = {
          list: temp2,
          total: totalLinkTypes
        };

        for (let i = 0; i < temp3.length; i += 1) {
          const temp = {
            title: temp3[i].title,
            count: temp3[i].count
          };

          totalHosts += temp3[i].count;

          temp3[i] = temp;
        }
        hostsData = {
          list: temp3,
          total: totalHosts
        };
      }
      const totalBroken =
        !jobInfo.summary || !jobInfo.summary.totalBroken
          ? '0'
          : String(jobInfo.summary.totalBroken);

      const totalScanTime =
        !jobInfo.summary || !jobInfo.totalScanTime
          ? '0'
          : String(parseFloat(jobInfo.totalScanTime).toFixed(2));
      const noPagesScanned =
        !jobInfo.summary || !jobInfo.summary.noPagesScanned ? 0 : jobInfo.summary.noPagesScanned;
      const noLinksChecked =
        !jobInfo.summary || !jobInfo.summary.noLinksChecked ? 0 : jobInfo.summary.noLinksChecked;
      return {
        totalBroken,
        totalScanTime,
        donutChartData,
        areaChartData,
        noPagesScanned,
        noLinksChecked,
        issueTypesData,
        linkTypesData,
        hostsData
      };

      // if (
      //   !JobDataSelected.fetchSelectedJob &&
      //   (JobDataSelected.fetchSelectedJob.status === 'SUCCEEDED' ||
      //     JobDataSelected.fetchSelectedJob.status === 'FAILED' ||
      //     JobDataSelected.fetchSelectedJob.status === 'ABORTED' ||
      //     JobDataSelected.fetchSelectedJob.status === 'FINISHED' ||
      //     JobDataSelected.fetchSelectedJob.status === 'None')
      // ) {
      //   // stopPolling();
      //   if (disableOtherTabs === true) {
      //     setDisableOtherTabs(false);
      //     // notify(data.fetchSelectedJob.status);
      //   }
      // }
    }
    return {
      totalBroken: '0',
      totalScanTime: '0',
      donutChartData: [],
      areaChartData: [],
      noPagesScanned: 0,
      noLinksChecked: 0,
      issueTypesData: {},
      linkTypesData: {},
      hostsData: {}
    };
  }, []);

  useEffect(() => {
    // console.log('configJobInfo Outer');
    if (!fetchConfigJobInfoLoading && configJobInfoData) {
      // console.log('configJobInfo', configJobInfoData);
      configJobInfoData
        ? setConfigJobInfo(configJobInfoData.fetchSelectedJob)
        : setConfigJobInfo(null);
    }
  }, [configJobInfoData, fetchConfigJobInfoLoading]);

  useEffect(() => {
    // console.log('summary Outer', configJobInfo);
    if (configJobInfo) {
      // console.log('summary ');
      const summary = computeSummary(configJobInfo);
      setJobSummary(summary);
    } else setJobSummary(null);
  }, [computeSummary, configJobInfo]);

  useEffect(() => {
    const acceptedStatus = [JOB_STATUS.FAILED, JOB_STATUS.FINISHED, JOB_STATUS.SUCCEEDED];
    if (
      props?.pollingInterval &&
      configJobInfo &&
      !acceptedStatus.includes(configJobInfo?.status)
    ) {
      startPolling(props?.pollingInterval);
      // console.log('polling st');
    }
  }, [configJobInfo, props?.pollingInterval, startPolling]);

  useEffect(() => {
    const acceptedStatus = [JOB_STATUS.FAILED, JOB_STATUS.FINISHED, JOB_STATUS.SUCCEEDED];
    if (!configJobInfo || acceptedStatus.includes(configJobInfo?.status)) stopPolling();
  }, [configJobInfo, stopPolling]);

  return {
    fetchConfigJobInfoLoading,
    jobSummary,
    configJobInfo,
    fetchConfigJobInfo,
    refetchConfigJobInfo: refetch,
    jobInfoStartPolling: startPolling,
    jobInfoStopPolling: stopPolling,
    setConfigJobInfo,
    computeSummary,
    setJobSummary
  };
}
