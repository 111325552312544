/*

This component can be used to create select menus or dropdowns. The configurable props are - 

options - An Array of strings containg the options for the dropdown
onChangeHandler - A function that will be called with the current selection
multi - A boolean to control whether or not to allow multiple selection
addClass - A string in the form of 'classA' or 'classA classB' where classA is to style the box and classB is to style the select'
*/

import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import { APP_ICONS } from '../../constants';
import Select from '../Select/Select';
import './DropDown.scss';
import sprite from '../../assets/sprite.svg';

const DropDown = ({
  options,
  onChangeHandler,
  multi,
  selectedOption,
  color,
  icon,
  addClass,
  position,
  label
}) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const eventHandler = event => {
      setIsOpen(false);
    };

    document.body.addEventListener('click', eventHandler);

    return () => {
      document.body.removeEventListener('click', eventHandler);
    };
  }, [selectedOption, onChangeHandler]);

  const toggleHandler = event => {
    event.stopPropagation();
    setIsOpen(prevState => !prevState);
  };

  const flipClass = isOpen ? 'dd-cont__icon--flip' : '';
  const addClassDp = addClass.split(' ')[0];
  const addClassSelect = addClass.split(' ')[1];
  return (
    <div
      className={addClass.length > 0 ? `dd-cont ${addClassDp}` : 'dd-cont'}
      data-testid="dropdown-container"
    >
      {label && <div className="dd-cont__label">{label}</div>}
      <div
        className="dd-cont__selection-box"
        onClick={toggleHandler}
        role="none"
        data-testid="dropdown-box"
      >
        {selectedOption?.label ? (
          <span className="dd-cont__selection-text">
            <span>{selectedOption.label}</span>
          </span>
        ) : (
          <span className="dd-cont__selection-placeholder">
            <span>Select</span>
          </span>
        )}

        <span className="dd-cont__icon-box">
          <svg className={`dd-cont__icon ${flipClass}`}>
            <use href={`${sprite}#${APP_ICONS[icon]}`} />
          </svg>
        </span>
      </div>

      <Select
        isOpen={isOpen}
        options={options}
        multi={multi}
        onChangeHandler={event => {
          onChangeHandler(event);
          setIsOpen(state => !state);
        }}
        showIcon={false}
        position={position}
        customStyleClass={addClassSelect}
      />
    </div>
  );
};

DropDown.defaultProps = {
  multi: false,
  color: '#f1f1f1',
  icon: 'DOWN_ARROW',
  addClass: '',
  position: '',
  label: null
};

DropDown.propTypes = {
  options: propTypes.arrayOf(
    propTypes.shape({
      label: propTypes.string,
      value: propTypes.string
    })
  ).isRequired,
  onChangeHandler: propTypes.func.isRequired,
  selectedOption: propTypes.shape({
    label: propTypes.string,
    value: propTypes.string
  }).isRequired,
  multi: propTypes.bool,
  color: propTypes.string,
  icon: propTypes.string,
  addClass: propTypes.string,
  position: propTypes.string,
  label: propTypes.string
};

export default DropDown;
