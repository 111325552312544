import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { UPDATE_WEBSITE } from '../../graphql/mutations/Website';
import { USER_DATA_V1 } from '../../graphql/queries/UserData';

export default function useUpdateWebsite() {
  const [openEditWebsite, setOpenEditWebsite] = useState(false);
  const [
    updateWebsite,
    { loading: updateWebsiteLoading, data: updateWebsiteResponse, error: updateWebsiteError }
  ] = useMutation(UPDATE_WEBSITE, { refetchQueries: [USER_DATA_V1] });

  useEffect(() => {
    if (!updateWebsiteLoading && updateWebsiteResponse) {
      if (updateWebsiteResponse.updateWebsite.id) {
        toast.success('Website updated successfully !');
        setOpenEditWebsite(false);
      } else toast.error('Something went wrong !');
    } else if (!updateWebsiteLoading && updateWebsiteError) {
      toast.error('Something went wrong!');
    }
  }, [updateWebsiteError, updateWebsiteLoading, updateWebsiteResponse]);

  return {
    updateWebsite,
    updateWebsiteResponse,
    updateWebsiteError,
    updateWebsiteLoading,
    openEditWebsite,
    setOpenEditWebsite
  };
}
