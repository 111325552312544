import React from 'react';
import PropTypes from 'prop-types';
import { APP_ICONS } from '../../constants';
import sprite from '../../assets/sprite.svg';
import './ActionHeader.scss';
// import IconButton from '../IconButton/IconButton';

const ActionHeaderV1 = ({ switchTitle, children }) => (
  // const [showTooltip, setShowTooltip] = useState(true);
  // const handleMouseEvent = e => {
  //   setShowTooltip(true);
  // };
  <div className="ah-cont">
    <header className="ah-cont__header">
      <div className="ah-cont__domain">
        <div className="ah-cont__icon-box">
          <svg className="ah-cont__icon">
            <use href={`${sprite}#${APP_ICONS.LINK_CHECKER_SMALL_ICON}`} />
          </svg>
        </div>
        <div className="ah-cont__divider" />
        {children}
      </div>
    </header>
  </div>
);
ActionHeaderV1.defaultProps = {
  children: '',
  switchTitle: ''
};

ActionHeaderV1.propTypes = {
  children: PropTypes.node,
  switchTitle: PropTypes.string
};

export default ActionHeaderV1;
