/*

This is the component to be used as the side navigation panel

*/

import React, { useRef, useState, useContext } from 'react';
// import { useAuth0 } from '@auth0/auth0-react';
import PropTypes from 'prop-types';
import { useAuth0 } from '@auth0/auth0-react';
import NavButton from '../NavButton/NavButton';
import IconButton from '../IconButton/IconButton';
import handleGoogleAnalytics from '../../utils/googleAnalytics';
import { APP_ICONS } from '../../constants';
import './SideNav.scss';
import { AuthContext } from '../../contexts/auth-context';

const SideNav = ({ showUserManagement }) => {
  const { isAuthenticated, logout, user } = useAuth0();

  const [isProfileClicked, setIsProfileClicked] = useState(false);
  const profileRef = useRef();
  const authContext = useContext(AuthContext);

  const profileClickHandler = event => {
    setIsProfileClicked(state => !state);
  };

  useState(() => {
    const bodyClickHandler = event => {
      // console.log('profileRef.current: ', profileRef.current);
      // console.log('event.tagret', event.target);
      if (profileRef && profileRef.current && !profileRef.current.contains(event.target))
        setIsProfileClicked(false);
    };

    document.body.addEventListener('click', bodyClickHandler);

    return () => document.body.removeEventListener('click', bodyClickHandler);
  }, []);
  const path = window.location.pathname;
  return (
    <div className="side-nav">
      <nav className="side-nav__nav">
        <div className="side-nav__top-btn-grp">
          <ul className="side-nav__list">
            <li className="side-nav__list-item">
              <NavButton
                defaultIcon={APP_ICONS.LINK_CHECKER_ICON}
                activeIcon={APP_ICONS.LINK_CHECKER_ICON}
                label="Link Checker"
                to="/dashboard"
                active={path === '/dashboard' || path === '/guest/dashboard'}
              />
            </li>
            {showUserManagement && (
              <li className="side-nav__list-item">
                <NavButton
                  defaultIcon={APP_ICONS.USER_MANAGEMENT}
                  activeIcon={APP_ICONS.USER_MANAGEMENT}
                  label="User management"
                  to="/user"
                  active={path === '/user'}
                />
              </li>
            )}
            <li className="side-nav__list-item">
              <NavButton
                defaultIcon={APP_ICONS.COMING_SOON_ICON}
                activeIcon={APP_ICONS.COMING_SOON_ICON}
                label="SEO"
                to="#"
              />
            </li>
            {/* <li className="side-nav__list-item">
              <NavButton
                defaultIcon={APP_ICONS.COMING_SOON_ICON}
                activeIcon={APP_ICONS.COMING_SOON_ICON}
                label="FAQ"
                to="#"
              />
            </li> */}
          </ul>
        </div>
        <div className="side-nav__bottom-btn-grp">
          <ul>
            {' '}
            {/* <li className="side-nav__list-item">
              <NavButton
                defaultIcon={APP_ICONS.SETTINGS_ICON}
                activeIcon={APP_ICONS.SETTINGS_ICON}
                label="Settings"
                to="/results/settings"
              />
            </li> */}
            <li className="side-nav__list-item">
              <div className="side-nav__button" ref={profileRef}>
                <IconButton
                  icon={APP_ICONS.USER_ICON}
                  iconColor={isProfileClicked ? '#2E74F3' : undefined}
                  shape="square"
                  onClickHandler={profileClickHandler}
                />
                <span className="side-nav__button-label">Profile</span>
              </div>
              {isProfileClicked && (
                <div className="side-nav__profile-menu-container">
                  <ul className="side-nav__profile-menu">
                    <li className="side-nav__profile-item">
                      <div className="side-nav__profile-child">
                        {user && user.email ? user.email : 'Guest'}
                      </div>
                    </li>
                    <li className="side-nav__profile-item side-nav__profile-item--hover">
                      <button
                        type="button"
                        name="logout"
                        className="side-nav__profile-child side-nav__profile-child--btn"
                        onClick={() => {
                          handleGoogleAnalytics({
                            event: 'v2_logout'
                          });
                          isAuthenticated
                            ? logout({ returnTo: window.location.origin })
                            : authContext.onLogout();
                        }}
                      >
                        Logout
                      </button>
                    </li>
                  </ul>
                </div>
              )}
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};
SideNav.defaultProps = {
  showUserManagement: false
};

SideNav.propTypes = {
  showUserManagement: PropTypes.bool
};

export default SideNav;
