import { useMutation } from '@apollo/client';
import REMOVE_USER from '../../graphql/mutations/userRole';
import { GET_EMAIL_USERS, GET_ORG_USERS } from '../../graphql/queries/User';

export default function useRemoveUser() {
  const [
    removeUserRole,
    { loading: removeUserLoading, data: removeUserData, error: removeUserError }
  ] = useMutation(REMOVE_USER, { refetchQueries: [GET_ORG_USERS, GET_EMAIL_USERS] });
  return {
    removeUserData: removeUserData?.removeUserRole,
    removeUserError,
    removeUserLoading,
    removeUserRole
  };
}
