import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import SideNav from '../../components/SideNav/SideNav';
import userTypeContext from '../../contexts/userTypeContext';
import ActionHeaderV1 from '../../components/ActionHeader/ActionHeaderV1';
import ConfigV1 from '../../components/ConfigV1/ConfigV1';
import Loader from '../../components/Loader/Loader';
import { APP_ICONS, JOB_STATUS, ORG_USER, SUPER_USER } from '../../constants';
import IconButton from '../../components/IconButton/IconButton';
import '../../containers/layout/Layout.scss';
import Button, { BUTTON_APPEARANCE, BUTTON_SIZE } from '../../components/Button/Button';
import WelcomeModal from '../../components/welcome';
import SubHeader from '../../components/SubHeader/SubHeader';
import WebsiteForm from '../../components/ConfigV1/WebsiteForm';
import ConfigModal from '../../components/Config/ConfigModal';
import {
  useCreateWebsite,
  useUpdateWebsite,
  useWebsiteForm,
  useConfigForm,
  useCreateConfig,
  useUpdateConfig,
  useConfigJobInfo,
  useConfigInfo,
  useUserInfo,
  useDeleteWebsite,
  useDeleteConfig,
  useCreateJob,
  // useLogin,
  useOrgForm,
  useCreateOrg,
  useUpdateOrg,
  useDeleteOrg
} from '../../hooks';

import ConfigFormV1 from '../../components/ConfigV1/ConfigFormV1';

import DeleteConfigModal from '../../components/DeleteConfigModal/DeleteConfigModal';
import OrgForm from '../../components/ConfigV1/OrgForm';
import Loading from '../../components/DetailsTab/Loading';
import { FETCH_CONFIG_BY_WEBSITE_ID } from '../../graphql/queries/Requests';
import { USER_DATA_V1 } from '../../graphql/queries/UserData';

const AdminDashboard = ({ isProtected }) => {
  const [workspaceMode, setWorkspaceMode] = useState(1);
  // console.log('rendering');
  // const { loginError, loginLoading } = useLogin();
  const { authType, handleAuthType } = useContext(userTypeContext);
  // info
  const {
    userInfo,
    websiteOptions,
    userInfoLoading,
    orgOptions,
    selectedOrg,
    // selectedOrgWebOptions,
    setSelectedOrg,
    setWebsiteOptions,
    userType
  } = useUserInfo(workspaceMode);
  // console.log(orgOptions, websiteOptions, 'options', workspaceMode);
  const { configurations, configurationOptions, fetchConfigurationLoading, fetchConfigurations } =
    useConfigInfo();
  const {
    configJobInfo,
    fetchConfigJobInfo,
    fetchConfigJobInfoLoading,
    jobSummary,
    // jobInfoStartPolling,
    jobInfoStopPolling
  } = useConfigJobInfo({ pollingInterval: 5000 });
  // update
  const {
    updateConfiguration,
    updatedConfigId,
    setUpdatedConfigId,
    updateConfigurationError,
    updateConfigurationLoading,
    updateConfigurationResponse
  } = useUpdateConfig([FETCH_CONFIG_BY_WEBSITE_ID]);
  const { updateWebsite, updateWebsiteLoading, openEditWebsite, setOpenEditWebsite } =
    useUpdateWebsite();
  const { openEditOrg, setOpenEditOrg, updateOrgLoading, updateOrg } = useUpdateOrg();
  // create
  const { createWebsite, createWebsiteLoading, openCreateWebsite, setOpenCreateWebsite } =
    useCreateWebsite(false, [USER_DATA_V1]);
  const {
    createConfiguration,
    createConfigurationError,
    createConfigurationLoading,
    createConfigurationResponse
  } = useCreateConfig();
  const { createJob, createJobLoading, createJobResponse, createJobError } = useCreateJob();
  const { createOrg, openCreateOrg, setOpenCreateOrg, createOrgLoading } = useCreateOrg();

  // delete
  const { deleteWebsite, openDeleteWebsite, setOpenDeleteWebsite, deleteWebsiteLoading } =
    useDeleteWebsite();
  const { deleteConfig, deleteConfigError, deleteConfigResponse, deleteConfigLoading } =
    useDeleteConfig();
  const { openDeleteOrg, setOpenDeleteOrg, deleteOrg, deleteOrgLoading } = useDeleteOrg();

  // Local State
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);
  const [selectedWebsite, setSelectedWebsite] = useState(websiteOptions[0] || null);
  const [selectedConfig, setSelectedConfig] = useState(configurationOptions[0] || null);
  const [loadingParam, setLoadingParam] = useState('');
  const [selectedDelete, setSelectedDelete] = useState('');
  const [selectedDeleteId, setSelectedDeleteId] = useState(null);
  const [openDeleteConfig, setOpenDeleteConfig] = useState(false);
  // WebsiteForm
  const {
    websiteName,
    websiteOrg,
    websiteUrl,
    setWebsiteName,
    setWebsiteOrg,
    setWebsiteUrl,
    resetWebsiteForm
  } = useWebsiteForm();
  // organization Form
  const {
    orgName,
    orgUrl,
    selectedDeleteOrg,
    selectedDeleteOrgId,
    setOrgName,
    resetOrgForm,
    setSelectedDeleteOrg,
    setSelectedDeleteOrgId
  } = useOrgForm();
  // Configuration Form
  const {
    openCreateConfig,
    openEditConfig,
    configName,
    configUrl,
    isSPA,
    rowData,
    errorString: SPAErrorString,
    setOpenCreateConfig,
    setOpenEditConfig,
    setConfigName,
    setConfigUrl,
    setIsSPA,
    setRowData,
    setErrorString: setSPAErrorString,
    handleCreateConfigModel
  } = useConfigForm();

  // const [showJobsDropdown, setShowJobsDropdown] = useState(false);

  const handleWorkSpaceMode = e => {
    if (workspaceMode === 1) setWorkspaceMode(2);
    else setWorkspaceMode(1);
  };

  const onSelectWebsiteOption = input => {
    if (input !== selectedWebsite) {
      setSelectedWebsite(input);
    }
  };

  const onSelectOrgOption = input => {
    setWebsiteOptions([]);
    if (input !== selectedOrg) {
      setSelectedOrg(input);
      setWebsiteOrg(input);
    }
  };

  const onSelectConfigurationOption = input => {
    if (input !== selectedConfig) {
      setSelectedConfig(input);
    }
  };

  const handleCreateWebsiteModel = input => {
    resetWebsiteForm();
    setOpenCreateWebsite(input);
  };

  const handleCreateOrgModel = input => {
    resetOrgForm();
    setOpenCreateOrg(input);
  };

  const handleWebsiteOnEditClick = e => {
    resetWebsiteForm();
    const data = e.currentTarget.parentNode.parentNode.getAttribute('data-key');
    const out = websiteOptions.filter(item => item.value === data);

    if (out.length > 0) {
      setSelectedWebsite(out[0]);
      setWebsiteName(out[0]?.label.split(' (')[0]);
    }
    setOpenEditWebsite(true);
  };

  const handleOrgOnEditClick = e => {
    resetOrgForm();
    const data = e.currentTarget.parentNode.parentNode.getAttribute('data-key');
    const out = orgOptions.filter(item => item.value === data);

    if (out.length > 0) {
      setSelectedOrg(out[0]);
      setOrgName(out[0].label);
    }
    setOpenEditOrg(true);
  };

  const handleConfigOnEditClick = e => {
    resetWebsiteForm();
    const data = e.currentTarget.parentNode.parentNode.getAttribute('data-key');
    const out = configurations.filter(item => item.id === data);
    setUpdatedConfigId(data);
    if (out) {
      const inclusion = out[0].inclusion
        ?.split(',')
        ?.map(item => ({ id: Math.random().toString(), inclorexcl: 'Include', pattern: item }));
      const exclusion = out[0].exclusion
        ?.split(',')
        ?.map(item => ({ id: Math.random().toString(), inclorexcl: 'Exclude', pattern: item }));

      setConfigName(out[0].config_name || '');
      setConfigUrl(out[0].url || '');
      setIsSPA(out[0].isSPA || false);
      setSPAErrorString(out[0].error_page_string || '');
      const result = inclusion ? [...inclusion] : [];
      const arr = exclusion ? [...exclusion, ...result] : [...result];
      setRowData(arr);
    }
    setOpenEditConfig(true);
  };

  const handleOnDeleteWebsite = e => {
    const config = e.currentTarget.parentNode.parentNode.parentNode.querySelector(
      ' .select__tooltip-option-label'
    ).innerHTML;
    const id = e.currentTarget.parentNode.parentNode.getAttribute('data-key');
    setSelectedDeleteId(id);
    setSelectedDelete(config);
    setOpenDeleteWebsite(true);
    // console.log(id, config);
  };

  const handleOnDeleteConfig = e => {
    const config = e.currentTarget.parentNode.parentNode.parentNode.querySelector(
      ' .select__tooltip-option-label'
    ).innerHTML;
    const id = e.currentTarget.parentNode.parentNode.getAttribute('data-key');
    setSelectedDeleteId(id);
    setSelectedDelete(config);
    setOpenDeleteConfig(true);
  };

  const handleSaveWebsite = e => {
    e.stopPropagation();
    const { name } = e.currentTarget;
    setLoadingParam(`createWebsite${name}`);
    createWebsite({
      variables: {
        website_name: websiteName,
        url: websiteUrl,
        org_id: workspaceMode === 1 ? '' : selectedOrg.value,
        createJob: name === 'saveAndRun'
      }
    });
  };

  const handleSaveOrg = e => {
    e.stopPropagation();
    createOrg({
      variables: {
        name: orgName
      }
    });
  };

  const handleUpdateWebsite = e => {
    e.stopPropagation();
    const { name } = e.currentTarget;
    updateWebsite({
      variables: {
        website_name: websiteName,
        id: selectedWebsite.value,
        createJob: name === 'saveAndRun'
      }
    });
  };

  const handleUpdateOrg = e => {
    e.stopPropagation();
    updateOrg({
      variables: {
        id: selectedOrg.value,
        name: orgName
      }
    });
  };

  const handleUpdateConfig = e => {
    e.stopPropagation();
    const { name } = e.currentTarget;
    setLoadingParam(`updateConfig${name}`);
    const configDetails = {
      url: configUrl,
      config_name: configName,
      isSPA,
      error_page_string: SPAErrorString,
      inclusion: rowData
        .map(item => {
          if (item.inclorexcl === 'Include') return item.pattern;
          return '';
        })
        .filter(item => item)
        .toString(),
      exclusion: rowData
        .map(item => {
          if (item.inclorexcl === 'Exclude') return item.pattern;
          return '';
        })
        .filter(item => item)
        .toString()
    };
    updateConfiguration({
      variables: {
        updateConfigId: updatedConfigId,
        config: configDetails,
        createJob: name === 'saveAndRun'
      }
    });
  };

  const handleSaveConfig = e => {
    e.stopPropagation();
    const { name } = e.currentTarget;
    setLoadingParam(`createConfig${name}`);
    const configDetails = {
      url: configUrl,
      config_name: configName,
      isSPA,
      createJob: name === 'saveAndRun',
      error_page_string: SPAErrorString,
      website_id: selectedWebsite.value,
      inclusion: rowData
        .map(item => {
          if (item.inclorexcl === 'Include') return item.pattern;
          return '';
        })
        .filter(item => item)
        .toString(),
      exclusion: rowData
        .map(item => {
          if (item.inclorexcl === 'Exclude') return item.pattern;
          return '';
        })
        .filter(item => item)
        .toString()
    };
    // console.log(configDetails, 'details');

    createConfiguration({ variables: { ...configDetails } });
  };

  const handleDeleteWebsite = e => {
    e.stopPropagation();
    deleteWebsite({ variables: { id: selectedDeleteId } });
  };

  const handleDeleteOrg = e => {
    e.stopPropagation();

    deleteOrg({ variables: { id: selectedDeleteOrgId } });
  };

  const handleDeleteConfig = e => {
    e.stopPropagation();
    deleteConfig({ variables: { deleteConfigId: selectedDeleteId } });
  };

  const handleOnDeleteOrg = e => {
    const config = e.currentTarget.parentNode.parentNode.parentNode.querySelector(
      ' .select__tooltip-option-label'
    ).innerHTML;
    const id = e.currentTarget.parentNode.parentNode.getAttribute('data-key');
    setSelectedDeleteOrgId(id);
    setSelectedDeleteOrg(config);
    setOpenDeleteOrg(true);
    // console.log(id, config);
  };

  const handleRunClick = e => {
    createJob({ variables: { request_id: selectedConfig.value } });
  };

  useEffect(() => {
    if (!userInfoLoading) {
      if (userInfo?.userOrganizations.length > 0) {
        setWorkspaceMode(2);
      }
    }
  }, [userInfo?.userOrganizations, userInfoLoading]);

  useEffect(() => {
    if (!userInfoLoading) {
      if (userInfo?.website.length === 0) {
        setShowWelcomeModal(true);
        // setFirstDataReceived(false);
      } else if (userInfo?.website.length > 0) {
        setSelectedWebsite(websiteOptions[0]);
        // fetchConfigurations({ variables: { website_id: options[0].value } });
      }
    }
  }, [userInfo?.website, userInfoLoading, websiteOptions]);

  useEffect(() => {
    if (selectedWebsite && selectedWebsite.value !== '' && selectedWebsite.value !== 'none') {
      fetchConfigurations({ variables: { website_id: selectedWebsite.value } });
      setSelectedConfig(configurationOptions[0] || null);
    }
  }, [configurationOptions, fetchConfigurations, selectedWebsite]);

  useEffect(() => {
    if (selectedConfig && selectedConfig.value !== '' && selectedConfig.value !== 'none') {
      fetchConfigJobInfo({ variables: { request_id: selectedConfig.value } });
    }
    return () => {
      jobInfoStopPolling();
    };
  }, [fetchConfigJobInfo, jobInfoStopPolling, selectedConfig]);

  useEffect(() => {
    if (isProtected && authType !== 'AUTHENTICATED') {
      handleAuthType();
    }
  }, [authType, handleAuthType, isProtected]);

  useEffect(() => {
    if (!createConfigurationLoading && createConfigurationResponse) {
      if (createConfigurationResponse.CreateRequest?.request.id) {
        setLoadingParam('');
        setOpenCreateConfig(false);
        toast.success('Configuration created successfully!');
      } else toast.error('Something went wrong!');
    } else if (!createConfigurationLoading && createConfigurationError) {
      const errorsList = ['invalid', 'not match', 'many requests'];
      if (createConfigurationError.message.includes(errorsList))
        toast.error(createConfigurationError.message);
      else toast.error(`Something went wrong!`);
    }
  }, [
    createConfigurationError,
    createConfigurationLoading,
    createConfigurationResponse,
    setOpenCreateConfig
  ]);

  useEffect(() => {
    if (!updateConfigurationLoading && updateConfigurationResponse) {
      if (updateConfigurationResponse.UpdateConfig.message) {
        setLoadingParam('');
        setOpenEditConfig(false);
        toast.success('Configuration created successfully!');
      } else toast.error('Something went wrong!');
    } else if (!updateConfigurationLoading && updateConfigurationError) {
      const errorsList = ['invalid', 'not match', 'many requests'];
      if (updateConfigurationError.message.includes(errorsList))
        toast.error(updateConfigurationError.message);
      else toast.error(`Something went wrong!`);
    }
  }, [
    setOpenEditConfig,
    updateConfigurationError,
    updateConfigurationLoading,
    updateConfigurationResponse
  ]);

  useEffect(() => {
    if (!deleteConfigLoading && deleteConfigResponse) {
      if (deleteConfigResponse?.DeleteConfig.message) {
        setOpenDeleteConfig(false);
        toast.success('Configuration Deleted successfully!');
      } else if (deleteConfigError) toast.error('Something went wrong!');
    } else if (!deleteConfigLoading && deleteConfigError) toast.error(`Something went wrong!`);
  }, [deleteConfigError, deleteConfigLoading, deleteConfigResponse]);

  useEffect(() => {
    if (!createJobLoading && createJobResponse?.CreateJob?.job.id) {
      toast.success('Job Created successfully!');
    } else if (createJobError) toast.error('Something went wrong!');
  }, [createJobError, createJobLoading, createJobResponse?.CreateJob?.job.id]);

  if (userInfoLoading)
    return (
      <div className="loading-flex">
        <Loader />
      </div>
    );
  // if (loginError) toast.error(loginError.message);
  // console.log(selectedConfig, selectedWebsite, configJobInfo, jobSummary)

  return (
    <div className="sc-page">
      <div className="sc-page__wrapper">
        <div className="sc-page__common">
          <div className="sc-page__container">
            <ActionHeaderV1>
              <div className="flex gap-10">
                <div className="ah-cont__dropdown  --relative">
                  {(userType === SUPER_USER || userType === ORG_USER) && workspaceMode === 2 ? (
                    <ConfigV1
                      dropTopDownTitle="Organizations"
                      dropDownBottomTitle="+ New Organization"
                      showDivider={false}
                      options={orgOptions}
                      selected={selectedOrg}
                      showEdit={userType === SUPER_USER}
                      showDelete={userType === SUPER_USER}
                      showBottomContent={userType === SUPER_USER}
                      onChangeHandler={onSelectOrgOption}
                      setCreateNewModel={handleCreateOrgModel}
                      handleOnEditClick={handleOrgOnEditClick}
                      handleOnDeleteClick={handleOnDeleteOrg}
                      createComponent={
                        <ConfigModal
                          isOpen={openCreateOrg}
                          setShow={setOpenCreateOrg}
                          configTitle="Create New Organization"
                          addClass="height-max"
                          modelBodyClass="height-max"
                          saveLoading={createOrgLoading}
                          disableSaveButton={orgName === ''}
                          // handleSaveAndRunButtonClick={handleSaveWebsite}
                          showSaveAndRunButton={false}
                          handleSaveButtonClick={handleSaveOrg}
                        >
                          <OrgForm
                            orgName={orgName}
                            orgUrl={orgUrl}
                            showUrl={false}
                            setOrgName={setOrgName}
                            // setOrgUrl={setOrgUrl}
                          />
                        </ConfigModal>
                      }
                      editComponent={
                        <ConfigModal
                          isOpen={openEditOrg}
                          setShow={setOpenEditOrg}
                          configTitle="Update Organization"
                          addClass="height-max"
                          modelBodyClass="height-max"
                          saveLoading={updateOrgLoading}
                          disableSaveButton={orgName === ''}
                          // disableSavAndRunButton={websiteName === ''}
                          // handleSaveAndRunButtonClick={handleUpdateWebsite}
                          showSaveAndRunButton={false}
                          handleSaveButtonClick={handleUpdateOrg}
                        >
                          <OrgForm
                            orgName={orgName}
                            // orgUrl={orgUrl}
                            setOrgName={setOrgName}
                            // setOrgUrl={setOrgUrl}
                            showUrl={false}
                          />
                        </ConfigModal>
                      }
                      deleteComponent={
                        <DeleteConfigModal
                          show={openDeleteOrg}
                          setShow={setOpenDeleteOrg}
                          handleDeleteConfig={handleDeleteOrg}
                          loading={deleteOrgLoading}
                          configName={selectedDeleteOrg}
                          deleteTitle="Delete Org"
                          deleteBodyMsg="Are you sure you want to delete "
                        />
                      }
                    />
                  ) : (
                    <ConfigV1
                      dropTopDownTitle="Websites"
                      dropDownBottomTitle="+ New Website"
                      showBottomContent={workspaceMode === 1}
                      showDelete={workspaceMode === 1}
                      showEdit={workspaceMode === 1}
                      options={websiteOptions}
                      selected={selectedWebsite}
                      onChangeHandler={onSelectWebsiteOption}
                      setCreateNewModel={handleCreateWebsiteModel}
                      handleOnEditClick={handleWebsiteOnEditClick}
                      handleOnDeleteClick={handleOnDeleteWebsite}
                      createComponent={
                        <ConfigModal
                          isOpen={openCreateWebsite}
                          setShow={setOpenCreateWebsite}
                          configTitle="Create New Website"
                          addClass="height-max"
                          modelBodyClass="height-max"
                          saveAndRunLoading={
                            loadingParam === 'createWebsitesaveAndRun' && createWebsiteLoading
                          }
                          saveLoading={loadingParam === 'createWebsitesave' && createWebsiteLoading}
                          disableSaveButton={
                            websiteName === '' || websiteUrl === '' || websiteOrg?.value === ''
                          }
                          disableSavAndRunButton={
                            websiteName === '' || websiteUrl === '' || websiteOrg?.value === ''
                          }
                          handleSaveAndRunButtonClick={handleSaveWebsite}
                          handleSaveButtonClick={handleSaveWebsite}
                        >
                          <WebsiteForm
                            // showOrgs={workspaceMode === 2}
                            orgList={orgOptions}
                            websiteName={websiteName}
                            websiteUrl={websiteUrl}
                            setWebsiteName={setWebsiteName}
                            setWebsiteUrl={setWebsiteUrl}
                            websiteOrg={websiteOrg}
                            setWebsiteOrg={setWebsiteOrg}
                          />
                        </ConfigModal>
                      }
                      editComponent={
                        <ConfigModal
                          isOpen={openEditWebsite}
                          setShow={setOpenEditWebsite}
                          configTitle="Update Website"
                          addClass="height-max"
                          modelBodyClass="height-max"
                          saveLoading={updateWebsiteLoading}
                          disableSaveButton={websiteName === ''}
                          // disableSavAndRunButton={websiteName === ''}
                          // handleSaveAndRunButtonClick={handleUpdateWebsite}
                          showSaveAndRunButton={false}
                          handleSaveButtonClick={handleUpdateWebsite}
                        >
                          <WebsiteForm
                            showOrgs={false}
                            showUrl={false}
                            websiteName={websiteName}
                            setWebsiteName={setWebsiteName}
                          />
                        </ConfigModal>
                      }
                      deleteComponent={
                        <DeleteConfigModal
                          show={openDeleteWebsite}
                          setShow={setOpenDeleteWebsite}
                          handleDeleteConfig={handleDeleteWebsite}
                          loading={deleteWebsiteLoading}
                          configName={selectedDelete}
                          deleteTitle="Website Config"
                          deleteBodyMsg="Are you sure you want to delete "
                        />
                      }
                    />
                  )}
                </div>
                {(userType === SUPER_USER ||
                  (userInfo?.userOrganizations && userInfo.userOrganizations.length > 0)) && (
                  <div className="--relative">
                    <IconButton
                      icon={APP_ICONS.SWITCH}
                      iconColor="#2E74F3"
                      shape="small-square"
                      activeStyle="icon-button-active"
                      onClickHandler={handleWorkSpaceMode}
                    />
                    <div className={`switch-icon__label `}>
                      {workspaceMode === 1 ? 'Switch to Org' : 'switch to personal'}
                    </div>
                  </div>
                )}
              </div>
            </ActionHeaderV1>
            <div className="page-container">
              <SideNav
                showUserManagement={
                  (userType === SUPER_USER || userType === ORG_USER) && workspaceMode === 2
                }
              />
              <div className="dashboard">
                {(userType !== SUPER_USER && userType !== ORG_USER) || workspaceMode === 1
                  ? !fetchConfigurationLoading &&
                    configurationOptions &&
                    selectedWebsite &&
                    selectedWebsite?.value !== '' &&
                    selectedWebsite?.value !== 'none' && (
                      <div>
                        <div>
                          <h1 className="config__heading border-bottom-none padding-title">
                            Configuration
                          </h1>
                        </div>
                        <div className="flex-class">
                          <div className="configuration__dropdown ">
                            <ConfigV1
                              dropTopDownTitle="Configurations"
                              dropDownBottomTitle="+ New Config"
                              options={configurationOptions}
                              selected={selectedConfig}
                              showBottomContent={selectedWebsite.role !== '4'}
                              showEdit={selectedWebsite.role !== '4'}
                              showDelete={selectedWebsite.role !== '4'}
                              onChangeHandler={onSelectConfigurationOption}
                              setCreateNewModel={handleCreateConfigModel}
                              handleOnEditClick={handleConfigOnEditClick}
                              handleOnDeleteClick={handleOnDeleteConfig}
                              createComponent={
                                <ConfigModal
                                  isOpen={openCreateConfig}
                                  setShow={setOpenCreateConfig}
                                  configTitle="Create New Configuration"
                                  addClass="height-max"
                                  modelBodyClass="height-max"
                                  disableSaveButton={configName === '' || configUrl === ''}
                                  disableSavAndRunButton={configName === '' || configUrl === ''}
                                  saveAndRunLoading={
                                    loadingParam === `createConfigsaveAndRun` &&
                                    createConfigurationLoading
                                  }
                                  saveLoading={
                                    loadingParam === `createConfigsave` &&
                                    createConfigurationLoading
                                  }
                                  handleSaveAndRunButtonClick={handleSaveConfig}
                                  handleSaveButtonClick={handleSaveConfig}
                                >
                                  <ConfigFormV1
                                    urlError=""
                                    configName={configName}
                                    setConfigName={setConfigName}
                                    configUrl={configUrl}
                                    setConfigUrl={setConfigUrl}
                                    SPA={isSPA}
                                    setSPA={setIsSPA}
                                    rowData={rowData}
                                    setRowData={setRowData}
                                    SPAErrorString={SPAErrorString}
                                    setSPAErrorString={setSPAErrorString}
                                  />
                                </ConfigModal>
                              }
                              editComponent={
                                <ConfigModal
                                  isOpen={openEditConfig}
                                  setShow={setOpenEditConfig}
                                  configTitle="Update Configuration"
                                  addClass="height-max"
                                  modelBodyClass="height-max"
                                  disableSaveButton={configName === '' || configUrl === ''}
                                  disableSavAndRunButton={configName === '' || configUrl === ''}
                                  saveAndRunLoading={
                                    loadingParam === `updateConfigsaveAndRun` &&
                                    updateConfigurationLoading
                                  }
                                  saveLoading={
                                    loadingParam === `updateConfigsave` &&
                                    updateConfigurationLoading
                                  }
                                  handleSaveAndRunButtonClick={handleUpdateConfig}
                                  handleSaveButtonClick={handleUpdateConfig}
                                >
                                  <ConfigFormV1
                                    urlError=""
                                    configName={configName}
                                    setConfigName={setConfigName}
                                    configUrl={configUrl}
                                    setConfigUrl={setConfigUrl}
                                    SPA={isSPA}
                                    setSPA={setIsSPA}
                                    rowData={rowData}
                                    setRowData={setRowData}
                                    SPAErrorString={SPAErrorString}
                                    setSPAErrorString={setSPAErrorString}
                                  />
                                </ConfigModal>
                              }
                              deleteComponent={
                                <DeleteConfigModal
                                  show={openDeleteConfig}
                                  setShow={setOpenDeleteConfig}
                                  handleDeleteConfig={handleDeleteConfig}
                                  loading={deleteConfigLoading}
                                  configName={selectedDelete}
                                  deleteTitle="Delete Config"
                                  deleteBodyMsg="Are you sure you want to delete "
                                />
                              }
                            />
                          </div>
                          {!fetchConfigJobInfoLoading &&
                            configurations &&
                            configurations.length > 0 && (
                              <div className="flex-class">
                                {!configJobInfo && (
                                  <Button
                                    label={`${configJobInfo ? 'Re-Run' : 'Run'}`}
                                    type="button"
                                    name="run"
                                    appearance="primary"
                                    size="xsmall"
                                    customStyleClass="btn-width"
                                    loading={createJobLoading}
                                    clickHandler={handleRunClick}
                                  />
                                )}
                                {configJobInfo && configJobInfo.status === JOB_STATUS.FAILED && (
                                  <div className="flex-class justify-center align-center">
                                    <IconButton
                                      icon={APP_ICONS.FAILED}
                                      iconColor="#f20f0f"
                                      shape="small-square"
                                      activeStyle="icon-button-active w-h-16-icon"
                                      isDisabled
                                      // onClickHandler={handleWorkSpaceMode}
                                    />
                                    <p className="failed-text">Failed</p>
                                    <Button
                                      label={`${configJobInfo ? 'Re-Run' : 'Run'}`}
                                      type="button"
                                      name="run"
                                      appearance="primary"
                                      size="xsmall"
                                      customStyleClass="btn-width"
                                      loading={createJobLoading}
                                      clickHandler={handleRunClick}
                                    />
                                  </div>
                                )}
                                {configJobInfo &&
                                  configJobInfo.status !== JOB_STATUS.FAILED &&
                                  configJobInfo.status !== JOB_STATUS.FINISHED &&
                                  configJobInfo.status !== JOB_STATUS.SUCCEEDED && (
                                    <div className="flex-class justify-center align-center">
                                      <div className="ah-cont_spin">
                                        <div className="ah-cont_square ah-cont_square-one" />
                                        <div className="ah-cont_square ah-cont_square-two" />
                                        <div className="ah-cont_square ah-cont_square-three" />
                                        <div className="ah-cont_square ah-cont_square-four" />
                                      </div>
                                      {/* <span className="ah-cont__status">RUNNING</span> */}
                                      {/* <span
                                              style={{
                                                borderRight: '2px solid #2E74F3',
                                                height: '3rem'
                                              }}
                                            /> */}

                                      <p className="failed-text">{configJobInfo.status}</p>
                                    </div>
                                  )}
                                {configJobInfo &&
                                  configJobInfo.status !== JOB_STATUS.FAILED &&
                                  (configJobInfo.status === JOB_STATUS.FINISHED ||
                                    configJobInfo.status === JOB_STATUS.SUCCEEDED) && (
                                    <Button
                                      label={`${configJobInfo ? 'Re-Run' : 'Run'}`}
                                      type="button"
                                      name="run"
                                      appearance="primary"
                                      size="xsmall"
                                      customStyleClass="btn-width"
                                      loading={createJobLoading}
                                      clickHandler={handleRunClick}
                                    />
                                  )}
                                {configJobInfo &&
                                  configJobInfo?.summary &&
                                  window?.location?.pathname !== '/guest/dashboard' && (
                                    <Link
                                      to={`/history/${selectedConfig?.value}`}
                                      className="dashboard__link"
                                      target="_blank"
                                    >
                                      <Button
                                        label="Historic Runs"
                                        // icon={APP_ICONS.ADD_REMOVE_COLUMN_ICON}
                                        type="button"
                                        name="add"
                                        size={BUTTON_SIZE.NANO}
                                        appearance={BUTTON_APPEARANCE.TERTIARY}
                                        // clickHandler={toggleHandler}
                                      />
                                    </Link>
                                  )}
                              </div>
                            )}
                        </div>
                      </div>
                    )
                  : !userInfoLoading &&
                    websiteOptions &&
                    selectedOrg &&
                    selectedOrg?.value !== '' &&
                    selectedOrg?.value !== 'none' && (
                      <div>
                        <div className="configuration__dropdown">
                          <div>
                            <h1 className="config__heading border-bottom-none padding-left-0">
                              Websites
                            </h1>
                          </div>
                          <ConfigV1
                            dropTopDownTitle="Websites"
                            dropDownBottomTitle="+ New Website"
                            options={websiteOptions}
                            selected={selectedWebsite}
                            onChangeHandler={onSelectWebsiteOption}
                            setCreateNewModel={handleCreateWebsiteModel}
                            handleOnEditClick={handleWebsiteOnEditClick}
                            handleOnDeleteClick={handleOnDeleteWebsite}
                            showDelete={userType === SUPER_USER || userType === ORG_USER}
                            createComponent={
                              <ConfigModal
                                isOpen={openCreateWebsite}
                                setShow={setOpenCreateWebsite}
                                configTitle="Create New Website"
                                addClass="height-max"
                                modelBodyClass="height-max"
                                saveAndRunLoading={
                                  loadingParam === 'createWebsitesaveAndRun' && createWebsiteLoading
                                }
                                saveLoading={
                                  loadingParam === 'createWebsitesave' && createWebsiteLoading
                                }
                                disableSaveButton={
                                  websiteName === '' ||
                                  websiteUrl === '' ||
                                  websiteOrg?.value === ''
                                }
                                disableSavAndRunButton={
                                  websiteName === '' ||
                                  websiteUrl === '' ||
                                  websiteOrg?.value === ''
                                }
                                handleSaveAndRunButtonClick={handleSaveWebsite}
                                handleSaveButtonClick={handleSaveWebsite}
                              >
                                <WebsiteForm
                                  // showOrgs={workspaceMode === 2}
                                  orgList={orgOptions}
                                  websiteName={websiteName}
                                  websiteUrl={websiteUrl}
                                  setWebsiteName={setWebsiteName}
                                  setWebsiteUrl={setWebsiteUrl}
                                  websiteOrg={websiteOrg}
                                  setWebsiteOrg={setWebsiteOrg}
                                />
                              </ConfigModal>
                            }
                            editComponent={
                              <ConfigModal
                                isOpen={openEditWebsite}
                                setShow={setOpenEditWebsite}
                                configTitle="Update Website"
                                addClass="height-max"
                                modelBodyClass="height-max"
                                saveLoading={updateWebsiteLoading}
                                disableSaveButton={websiteName === ''}
                                // disableSavAndRunButton={websiteName === ''}
                                // handleSaveAndRunButtonClick={handleUpdateWebsite}
                                showSaveAndRunButton={false}
                                handleSaveButtonClick={handleUpdateWebsite}
                              >
                                <WebsiteForm
                                  showOrgs={false}
                                  showUrl={false}
                                  websiteName={websiteName}
                                  setWebsiteName={setWebsiteName}
                                />
                              </ConfigModal>
                            }
                            deleteComponent={
                              <DeleteConfigModal
                                show={openDeleteWebsite}
                                setShow={setOpenDeleteWebsite}
                                handleDeleteConfig={handleDeleteWebsite}
                                loading={deleteWebsiteLoading}
                                configName={selectedDelete}
                                deleteTitle="Delete Website"
                                deleteBodyMsg="Are you sure you want to delete "
                              />
                            }
                          />
                        </div>
                        {!fetchConfigurationLoading &&
                          configurationOptions &&
                          selectedWebsite &&
                          selectedWebsite?.value !== '' &&
                          selectedWebsite?.value !== 'none' && (
                            <div>
                              <div>
                                <h1 className="config__heading border-bottom-none padding-title">
                                  Configuration
                                </h1>
                              </div>
                              <div className="flex-class">
                                <div className="configuration__dropdown ">
                                  <ConfigV1
                                    dropTopDownTitle="Configurations"
                                    dropDownBottomTitle="+ New Config"
                                    options={configurationOptions}
                                    selected={selectedConfig}
                                    onChangeHandler={onSelectConfigurationOption}
                                    setCreateNewModel={handleCreateConfigModel}
                                    handleOnEditClick={handleConfigOnEditClick}
                                    handleOnDeleteClick={handleOnDeleteConfig}
                                    createComponent={
                                      <ConfigModal
                                        isOpen={openCreateConfig}
                                        setShow={setOpenCreateConfig}
                                        configTitle="Create New Configuration"
                                        addClass="height-max"
                                        modelBodyClass="height-max"
                                        disableSaveButton={configName === '' || configUrl === ''}
                                        disableSavAndRunButton={
                                          configName === '' || configUrl === ''
                                        }
                                        saveAndRunLoading={
                                          loadingParam === `createConfigsaveAndRun` &&
                                          createConfigurationLoading
                                        }
                                        saveLoading={
                                          loadingParam === `createConfigsave` &&
                                          createConfigurationLoading
                                        }
                                        handleSaveAndRunButtonClick={handleSaveConfig}
                                        handleSaveButtonClick={handleSaveConfig}
                                      >
                                        <ConfigFormV1
                                          urlError=""
                                          configName={configName}
                                          setConfigName={setConfigName}
                                          configUrl={configUrl}
                                          setConfigUrl={setConfigUrl}
                                          SPA={isSPA}
                                          setSPA={setIsSPA}
                                          rowData={rowData}
                                          setRowData={setRowData}
                                          SPAErrorString={SPAErrorString}
                                          setSPAErrorString={setSPAErrorString}
                                        />
                                      </ConfigModal>
                                    }
                                    editComponent={
                                      <ConfigModal
                                        isOpen={openEditConfig}
                                        setShow={setOpenEditConfig}
                                        configTitle="Update Configuration"
                                        addClass="height-max"
                                        modelBodyClass="height-max"
                                        disableSaveButton={configName === '' || configUrl === ''}
                                        disableSavAndRunButton={
                                          configName === '' || configUrl === ''
                                        }
                                        saveAndRunLoading={
                                          loadingParam === `updateConfigsaveAndRun` &&
                                          updateConfigurationLoading
                                        }
                                        saveLoading={
                                          loadingParam === `updateConfigsave` &&
                                          updateConfigurationLoading
                                        }
                                        handleSaveAndRunButtonClick={handleUpdateConfig}
                                        handleSaveButtonClick={handleUpdateConfig}
                                      >
                                        <ConfigFormV1
                                          urlError=""
                                          configName={configName}
                                          setConfigName={setConfigName}
                                          configUrl={configUrl}
                                          setConfigUrl={setConfigUrl}
                                          SPA={isSPA}
                                          setSPA={setIsSPA}
                                          rowData={rowData}
                                          setRowData={setRowData}
                                          SPAErrorString={SPAErrorString}
                                          setSPAErrorString={setSPAErrorString}
                                        />
                                      </ConfigModal>
                                    }
                                    deleteComponent={
                                      <DeleteConfigModal
                                        show={openDeleteConfig}
                                        setShow={setOpenDeleteConfig}
                                        handleDeleteConfig={handleDeleteConfig}
                                        loading={deleteConfigLoading}
                                        configName={selectedDelete}
                                        deleteTitle="Delete Config"
                                        deleteBodyMsg="Are you sure you want to delete "
                                      />
                                    }
                                  />
                                </div>

                                {!fetchConfigJobInfoLoading &&
                                  configurations &&
                                  configurations.length > 0 && (
                                    <div className="flex-class">
                                      {!configJobInfo && (
                                        <Button
                                          label={`${configJobInfo ? 'Re-Run' : 'Run'}`}
                                          type="button"
                                          name="run"
                                          appearance="primary"
                                          size="xsmall"
                                          customStyleClass="btn-width"
                                          loading={createJobLoading}
                                          clickHandler={handleRunClick}
                                        />
                                      )}
                                      {configJobInfo && configJobInfo.status === JOB_STATUS.FAILED && (
                                        <div className="flex-class justify-center align-center">
                                          <IconButton
                                            icon={APP_ICONS.FAILED}
                                            iconColor="#f20f0f"
                                            shape="small-square"
                                            activeStyle="icon-button-active w-h-16-icon"
                                            isDisabled
                                            // onClickHandler={handleWorkSpaceMode}
                                          />
                                          <p className="failed-text">Failed</p>
                                          <Button
                                            label={`${configJobInfo ? 'Re-Run' : 'Run'}`}
                                            type="button"
                                            name="run"
                                            appearance="primary"
                                            size="xsmall"
                                            customStyleClass="btn-width"
                                            loading={createJobLoading}
                                            clickHandler={handleRunClick}
                                          />
                                        </div>
                                      )}
                                      {configJobInfo &&
                                        configJobInfo.status !== JOB_STATUS.FAILED &&
                                        configJobInfo.status !== JOB_STATUS.FINISHED &&
                                        configJobInfo.status !== JOB_STATUS.SUCCEEDED && (
                                          <div className="flex-class justify-center align-center">
                                            <div className="ah-cont_spin">
                                              <div className="ah-cont_square ah-cont_square-one" />
                                              <div className="ah-cont_square ah-cont_square-two" />
                                              <div className="ah-cont_square ah-cont_square-three" />
                                              <div className="ah-cont_square ah-cont_square-four" />
                                            </div>
                                            {/* <span className="ah-cont__status">RUNNING</span> */}
                                            {/* <span
                                              style={{
                                                borderRight: '2px solid #2E74F3',
                                                height: '3rem'
                                              }}
                                            /> */}

                                            <p className="failed-text">{configJobInfo.status}</p>
                                          </div>
                                        )}
                                      {configJobInfo &&
                                        configJobInfo.status !== JOB_STATUS.FAILED &&
                                        (configJobInfo.status === JOB_STATUS.FINISHED ||
                                          configJobInfo.status === JOB_STATUS.SUCCEEDED) && (
                                          <Button
                                            label={`${configJobInfo ? 'Re-Run' : 'Run'}`}
                                            type="button"
                                            name="run"
                                            appearance="primary"
                                            size="xsmall"
                                            customStyleClass="btn-width"
                                            loading={createJobLoading}
                                            clickHandler={handleRunClick}
                                          />
                                        )}
                                      {configJobInfo && configJobInfo?.summary && (
                                        <Link
                                          to={`/history/${selectedConfig?.value}`}
                                          className="dashboard__link"
                                          target="_blank"
                                        >
                                          <Button
                                            label="Historic Runs"
                                            // icon={APP_ICONS.ADD_REMOVE_COLUMN_ICON}
                                            type="button"
                                            name="add"
                                            size={BUTTON_SIZE.NANO}
                                            appearance={BUTTON_APPEARANCE.TERTIARY}
                                            // clickHandler={toggleHandler}
                                          />
                                        </Link>
                                      )}
                                    </div>
                                  )}
                              </div>
                            </div>
                          )}
                      </div>
                    )}
                {fetchConfigurationLoading && (
                  <div className='config__heading border-bottom-none padding-title"'>
                    Loading Configurations ...
                  </div>
                )}
                <div className="dashboard__protected padding-top-3">
                  {!showWelcomeModal &&
                  !fetchConfigJobInfoLoading &&
                  !fetchConfigurationLoading &&
                  configJobInfo &&
                  configJobInfo?.summary.totalBroken !== null &&
                  selectedWebsite &&
                  selectedWebsite?.value !== '' &&
                  selectedWebsite?.value !== 'none' &&
                  jobSummary ? (
                    <SubHeader
                      // disableOtherTabs={disableOtherTabs}
                      summaryData={jobSummary}
                      jobId={configJobInfo.id}
                      requestId={selectedConfig.value}
                      firstDataReceived
                      scanStatus={false}
                    />
                  ) : (
                    fetchConfigJobInfoLoading && <Loading />
                  )}
                  {userInfo?.userOrganizations && userInfo.userOrganizations.length <= 0 && (
                    <WelcomeModal
                      show={showWelcomeModal}
                      setShow={setShowWelcomeModal}
                      // newJob={newJob}
                      // type="ADD"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

AdminDashboard.defaultProps = {
  isProtected: false
};

AdminDashboard.propTypes = {
  isProtected: PropTypes.bool
};

export default AdminDashboard;
