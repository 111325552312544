import { gql } from '@apollo/client';

const DELETE_CONFIG = gql`
  mutation DeleteConfig($deleteConfigId: String!) {
    DeleteConfig(id: $deleteConfigId) {
      message
    }
  }
`;

export default DELETE_CONFIG;
