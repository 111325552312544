import { useLazyQuery } from '@apollo/client';
import { useState, useEffect } from 'react';
import { FETCH_CONFIG_BY_WEBSITE_ID } from '../graphql/queries/Requests';

export default function useConfigInfo() {
  const [configurations, setConfigurations] = useState([]);
  const [configurationOptions, setConfigurationOptions] = useState([]);
  const [fetchConfigurations, { loading: fetchConfigurationLoading, data: configurationData }] =
    useLazyQuery(
      FETCH_CONFIG_BY_WEBSITE_ID
      //    {
      //   fetchPolicy: 'network-only'
      // }
    );
  useEffect(() => {
    // console.log('useConfigInfo Outer', configurationData);
    if (!fetchConfigurationLoading && configurationData) {
      // console.log('useConfigInfo', configurationData);
      configurationData
        ? setConfigurations(configurationData?.fetchConfigurations)
        : setConfigurations(null);
      const configurationOptionsList = configurationData?.fetchConfigurations?.map(
        ({ id, config_name: configName, url }) => ({
          link: url,
          label: configName,
          value: id
        })
      );
      setConfigurationOptions(configurationOptionsList);
    }
  }, [configurationData, fetchConfigurationLoading]);

  return {
    configurationOptions,
    configurations,
    fetchConfigurationLoading,
    setConfigurations,
    fetchConfigurations,
    setConfigurationOptions
  };
}
