import { useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { GET_ORG_USERS } from '../../graphql/queries/User';

export default function useUsersList() {
  const [currentData, setCurrentData] = useState(null);
  const [
    fetchUsersInfo,
    { loading: fetchUsersInfoLoading, data: fetchUsersInfoResponse, error: fetchUsersInfoError }
  ] = useLazyQuery(GET_ORG_USERS, { fetchPolicy: 'network-only' });

  useEffect(() => {
    if (!fetchUsersInfoLoading) {
      setCurrentData(fetchUsersInfoResponse?.fetchUsers);
    }
  }, [fetchUsersInfoLoading, fetchUsersInfoResponse]);
  // console.log(currentData, 'current');
  return {
    fetchUsersInfo,
    fetchUsersInfoLoading,
    fetchUsersInfoError,
    fetchUsersInfoResponse: currentData
  };
}
