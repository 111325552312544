import React from 'react';
import PropTypes from 'prop-types';
import '../../pages/userManagement/user.scss';
import Select from 'react-select';

const UserRoleForm = ({
  name,
  email,
  orgOptions,
  roleOptions,
  webOptions,
  selectedOrg,
  selectedRole,
  selectedWeb,
  setSelectedOrg,
  setSelectedRole,
  setSelectedWeb
}) => {
  const handleSelectOrg = val => {
    setSelectedOrg(val);
  };

  const handleSelectRole = val => {
    setSelectedRole(val);
  };
  const handleSelectWeb = val => {
    setSelectedWeb(val);
  };

  return (
    <div
      className="user_role_form__container"
      style={{
        marginBottom: '3rem'
      }}
    >
      <div className="user_role_form__wrapper">
        <div className="user_role_form__row_wrapper">
          <h1 className="user_role_form__label">Name :</h1>
          <p>{name}</p>
        </div>
        <div className="user_role_form__row_wrapper">
          <h1 className="user_role_form__label">Email :</h1>
          <p>{email}</p>
        </div>
      </div>
      <div>
        <div className="user_role_form__wrapper">
          <div className="user_role_form__row_wrapper">
            <h1 className="user_role_form__label">Organization :</h1>
            <div className="user_role_form__select_wrapper">
              <Select options={orgOptions} value={selectedOrg} onChange={handleSelectOrg} />
            </div>
          </div>
          <div className="user_role_form__row_wrapper">
            <h1 className="user_role_form__label">Role :</h1>
            <div className="user_role_form__select_wrapper">
              <Select options={roleOptions} value={selectedRole} onChange={handleSelectRole} />
            </div>
          </div>
        </div>

        {selectedRole && selectedRole.value > 2 && (
          <div className="user_role_form__row_wrapper">
            <h1 className="user_role_form__label">Website :</h1>
            <div className="user_role_form__select_wrapper">
              <Select options={webOptions} value={selectedWeb} onChange={handleSelectWeb} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

UserRoleForm.defaultProps = {
  orgOptions: [],
  roleOptions: [],
  webOptions: [],
  selectedWeb: null,
  name: '',
  email: ''
};

UserRoleForm.propTypes = {
  name: PropTypes.string,
  email: PropTypes.string,
  selectedOrg: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  }).isRequired,
  selectedRole: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  }).isRequired,
  selectedWeb: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  }),

  orgOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })
  ),
  roleOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })
  ),
  webOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })
  ),
  setSelectedOrg: PropTypes.func.isRequired,
  setSelectedRole: PropTypes.func.isRequired,
  setSelectedWeb: PropTypes.func.isRequired
};

export default UserRoleForm;
