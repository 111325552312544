import { useState } from 'react';

export default function useConfigForm() {
  const [openCreateConfig, setOpenCreateConfig] = useState(false);
  const [openEditConfig, setOpenEditConfig] = useState(false);
  const [configUrl, setConfigUrl] = useState('');
  const [configName, setConfigName] = useState('');
  const [isSPA, setIsSPA] = useState(false);
  const [errorString, setErrorString] = useState('');
  const [rowData, setRowData] = useState([
    { id: Math.random().toString(), inclorexcl: '', pattern: '' }
  ]);

  const resetConfigForm = () => {
    setConfigUrl('');
    setConfigName('');
    setIsSPA(false);
    setErrorString('');
    setRowData([{ id: Math.random().toString(), inclorexcl: '', pattern: '' }]);
  };

  const handleCreateConfigModel = input => {
    resetConfigForm();
    setOpenCreateConfig(input);
  };

  return {
    openCreateConfig,
    openEditConfig,
    configUrl,
    configName,
    isSPA,
    rowData,
    errorString,
    setOpenCreateConfig,
    setConfigName,
    setOpenEditConfig,
    setConfigUrl,
    setIsSPA,
    setRowData,
    setErrorString,
    resetConfigForm,
    handleCreateConfigModel
  };
}
