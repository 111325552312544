import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import CREATE_ORG from '../../graphql/mutations/organization';
import { USER_DATA_V1 } from '../../graphql/queries/UserData';
import { USER_ERROR_MESSAGE } from '../../constants';

export default function useCreateOrg() {
  const [openCreateOrg, setOpenCreateOrg] = useState(false);
  const [createOrg, { loading: createOrgLoading, data: createOrgData, error: createOrgError }] =
    useMutation(CREATE_ORG, { refetchQueries: [USER_DATA_V1] });

  useEffect(() => {
    if (!createOrgLoading && createOrgData) {
      if (createOrgData?.createOrganization.id) {
        setOpenCreateOrg(false);
        toast.success('Organization created successfully!');
      } else toast.error('Something went wrong!');
    } else if (!createOrgLoading && createOrgError) {
      if (createOrgError.message.includes('Validation')) toast.error(USER_ERROR_MESSAGE.ORG_EXIST);
      else toast.error('Something went wrong!');
    }
  }, [createOrgData, createOrgError, createOrgLoading]);

  return {
    createOrg,
    createOrgError,
    createOrgLoading,
    createOrgData,
    openCreateOrg,
    setOpenCreateOrg
  };
}
