import { gql } from '@apollo/client';

const CREATE_ORG = gql`
  mutation createOrganization($name: String!) {
    createOrganization(name: $name) {
      id
      name
    }
  }
`;

const UPDATE_ORG = gql`
  mutation updateOrganization($id: String!, $name: String!) {
    updateOrganization(id: $id, name: $name) {
      id
      name
    }
  }
`;

const DELETE_ORG = gql`
  mutation deleteOrganization($id: String!) {
    deleteOrganization(id: $id) {
      id
      name
    }
  }
`;

export default CREATE_ORG;

export { UPDATE_ORG, DELETE_ORG };
