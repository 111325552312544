/*

This component is to create an icon button without text. It type is fixed to 'button' as its unlikely to be used for form submission. The following are its configurable props - 

icon - Takes a string using the APP_ICON constant to specify the icon.
isDisabled - Its optional. When present it disables the icon and adds the 'icon-btn--disabled' class.
isActive - Its optional. The active state is used when this component is used in the ToggleButton component. It adds the 'icon-btn--active' class.
shape - Its optional. It can take either of 'square' and 'round' to create a round or square icon button. Default is square.
onClickHandler - function to execute on click events.

*/

import React from 'react';
import propTypes from 'prop-types';

import sprite from '../../assets/sprite.svg';
import './IconButton.scss';

const IconButton = ({
  icon,
  iconColor,
  isDisabled,
  shape,
  isActive,
  onClickHandler,
  activeStyle
}) => {
  const disabledClass = isDisabled ? 'icon-btn--disabled' : '';
  let shapeClass;
  switch (shape) {
    case 'square':
      shapeClass = 'icon-btn--square';
      break;
    case 'small-square':
      shapeClass = 'icon-btn--small_square';
      break;
    default:
      shapeClass = 'icon-btn--round';
      break;
  }

  const activeClass = isActive ? 'icon-btn--active' : '';
  const classes = `${disabledClass} ${shapeClass} ${activeClass}`;

  return (
    <button
      type="button"
      className={`icon-btn ${classes} ${activeStyle || ''}`}
      disabled={isDisabled}
      onClick={onClickHandler}
    >
      <span className="icon-btn__icon-box" style={{ color: iconColor }}>
        <svg className="icon-btn__icon" data-testid="btn-icon">
          <use href={`${sprite}#${icon}`} />
        </svg>
      </span>
    </button>
  );
};

IconButton.defaultProps = {
  iconColor: '#828282',
  isDisabled: false,
  isActive: false,
  shape: 'square',
  activeStyle: ''
};

IconButton.propTypes = {
  icon: propTypes.string.isRequired,
  iconColor: propTypes.string,
  isDisabled: propTypes.bool,
  shape: propTypes.oneOf(['square', 'round', 'small-square']),
  isActive: propTypes.bool,
  activeStyle: propTypes.string,
  onClickHandler: propTypes.func.isRequired
};

export default IconButton;
