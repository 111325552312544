import { useLazyQuery } from '@apollo/client';
import { GET_ORG_SEARCH_USERS } from '../../graphql/queries/User';

export default function useOrgSearch() {
  const [
    fetchOrgSearch,
    { loading: fetchOrgSearchLoading, data: fetchOrgSearchResponse, error: fetchOrgSearchError }
  ] = useLazyQuery(GET_ORG_SEARCH_USERS, { fetchPolicy: 'network-only' });

  return {
    fetchOrgSearch,
    fetchOrgSearchLoading,
    fetchOrgSearchResponse: fetchOrgSearchResponse?.searchOrganization,
    fetchOrgSearchError
  };
}
