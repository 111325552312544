import React, { useMemo, useRef, useEffect } from 'react';

import propTypes from 'prop-types';
import './DetailsTab.scss';
import { useQuery, NetworkStatus } from '@apollo/client';
import GET_DETAILS from '../../graphql/queries/Details';

import Loader from '../Loader/Loader';
import Loading from './Loading';
import Table from './Table';
import { eventLinker } from '../../constants';

const DetailsTab = props => {
  const { tableColumns, hiddenColumns, jobId, noLinksChecked } = props;

  useEffect(() => {
    // const temp = queryParam.current;
    // temp.job = jobId;
    // queryParam.current = temp;
    // handleRefetch(queryParam.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobId]);
  const queryParam = useRef({
    job: jobId,
    limit: 25,
    offset: 0,
    dropdown: true,
    parameters: {}
  });

  const { loading, error, data, refetch, networkStatus } = useQuery(GET_DETAILS, {
    variables: queryParam.current,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onError: err => {
      if (err.graphQLErrors) {
        // eslint-disable-next-line no-console
        console.error(err.graphQLErrors);
      }
      if (err.networkError) {
        // eslint-disable-next-line no-console
        console.error(err.networkError.result.errors);
      }
    }
  });

  const handleRefetch = variables => {
    queryParam.current = variables;
    refetch(variables);
  };

  const dataR = data && data.details ? data.details : {};

  // DISABLED because the array of dependencies is empty in react-table v7 docs
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = useMemo(() => tableColumns, []);

  let statusDropdownOptions = [];
  if (dataR && dataR.dropdownValues) {
    statusDropdownOptions = dataR.dropdownValues[0].values.map(item => ({
      label: item,
      value: item
    }));
    statusDropdownOptions.unshift({ label: 'All', value: 'All' });
  }

  const typeOptions = useMemo(
    () => [
      { label: 'All', value: 'All' },
      { label: 'Internal', value: 'Internal' },
      { label: 'External', value: 'External' }
    ],
    []
  );

  const pageCountOptions = useMemo(
    () => [
      { label: '25', value: '25' },
      { label: '50', value: '50' },
      { label: '75', value: '75' },
      { label: '100', value: '100' }
    ],
    []
  );

  const count = dataR ? dataR.totalCount : 0;

  const tableData =
    dataR && dataR.results
      ? dataR.results.map((item, index) => ({
          sno: index + queryParam.current.offset + 1,
          link: item.link,
          page: item.page,
          link_status: item.link_status,
          link_location: item.link_location
        }))
      : [];

  if (networkStatus === NetworkStatus.refetch) return <Loading text="refetching data!" />;
  if (loading) return <Loader />;
  if (error) return <Loading text={`Error! ${error}`} />;

  return (
    <div className="details">
      <Table
        columns={columns}
        hiddenColumns={hiddenColumns}
        tableData={tableData}
        statusDropdownOptions={statusDropdownOptions}
        typeOptions={typeOptions}
        pageCountOptions={pageCountOptions}
        count={count}
        noLinksChecked={noLinksChecked}
        handleRefetch={handleRefetch}
        jobId={jobId}
        search={
          queryParam.current.parameters[eventLinker.SEARCH]
            ? String(queryParam.current.parameters[eventLinker.SEARCH])
            : ''
        }
        status={
          queryParam.current.parameters[eventLinker.STATUS]
            ? String(queryParam.current.parameters[eventLinker.STATUS])
            : String(statusDropdownOptions[0].value)
        }
        type={
          queryParam.current.parameters[eventLinker.TYPE]
            ? String(queryParam.current.parameters[eventLinker.TYPE])
            : String(typeOptions[0].value)
        }
        limit={String(queryParam.current.limit)}
        offset={String(queryParam.current.offset)}
      />
    </div>
  );
};

DetailsTab.propTypes = {
  tableColumns: propTypes.arrayOf(
    propTypes.shape({
      Header: propTypes.string,
      accessor: propTypes.string,
      width: propTypes.string
    })
  ).isRequired,
  hiddenColumns: propTypes.arrayOf(propTypes.string).isRequired,
  jobId: propTypes.string.isRequired,
  noLinksChecked: propTypes.number.isRequired
};

export default DetailsTab;
