import { gql } from '@apollo/client';

const REQUESTS = gql`
  query Requests {
    requests {
      id
      url
      last_active
      inclusion
      exclusion
    }
  }
`;

const FETCH_CONFIG_BY_WEBSITE_ID = gql`
  query FetchConfigByWebsite($website_id: String!) {
    fetchConfigurations(website_id: $website_id) {
      id
      url
      last_active
      inclusion
      exclusion
      isSPA
      error_page_string
      config_name
    }
  }
`;

export { FETCH_CONFIG_BY_WEBSITE_ID };

export default REQUESTS;
