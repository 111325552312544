import icons from './icons.json';
import links from './links.json';
import errors from './errors.json';
import example from './example.json';

export const APP_ICONS = { ...icons };
export const LINKS = { ...links };
export const ERRORS = { ...errors };
export const EXAMPLE = { ...example };
export const CHART_SEGMENT_COLORS = ['#2E74F3', '#F5BD2E', '#8DB4F6', '#b2cdf9'];
export const CHART_TEXT_COLOR = '#090909';
export const BAR_COLORS = {
  primary: '#2E74F3',
  secondary: '#F5BD2E'
};
export const TEXTFIELD_STATUS = {
  WARNING: 'warning',
  SUCCESS: 'success',
  ERROR: 'error'
};
export const TEXTFIELD_TYPE = {
  TEXT: 'text',
  PASSWORD: 'password',
  NUMBER: 'number'
};

export const USER_ERROR_MESSAGE = {
  ORG_EXIST: 'Organization name is already taken. Please choose a different one',
  CONFIG_DOMAIN_MISMATCH: "Configuration URL doesn't match the base URL"
};

export const LOCAL_KEYS = {
  GUEST_TOKEN: 'guest_user_token',
  GUEST_ID: 'guest_user_id',
  GOOGLE_CAPTCHA: '_grecaptcha',
  SELECTED_TAB: 'selected_tab'
};

export const eventLinker = {
  SEARCH: 'link',
  STATUS: 'link_status',
  TYPE: 'link_location',
  LIMIT: 'limit',
  OFFSET: 'offset'
};

export const JOB_STATUS = {
  FINISHED: 'FINISHED',
  FAILED: 'FAILED',
  SUCCEEDED: 'SUCCEEDED'
};

// variables

export const SUPER_USER = 'superAdmin';
export const ORG_USER = 'orgAdmin';
