import { useMutation } from '@apollo/client';
import { useState } from 'react';
import UPDATE_CONFIG from '../../graphql/mutations/UpdateConfig';
// import { FETCH_CONFIG_BY_WEBSITE_ID } from '../../graphql/queries/Requests';

export default function useUpdateConfig(refetchQueries = '') {
  const [updatedConfigId, setUpdatedConfigId] = useState();
  const [updateConfiguration, { loading, data, error }] = useMutation(
    UPDATE_CONFIG,
    refetchQueries
      ? {
          refetchQueries: [...refetchQueries]
        }
      : {}
  );
  return {
    updateConfiguration,
    updatedConfigId,
    setUpdatedConfigId,
    updateConfigurationLoading: loading,
    updateConfigurationResponse: data,
    updateConfigurationError: error
  };
}
