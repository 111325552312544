import { useMutation } from '@apollo/client';
import { CREATE_USER_ROLE } from '../../graphql/mutations/userRole';
import { GET_EMAIL_USERS, GET_ORG_USERS } from '../../graphql/queries/User';

export default function useCreateUserRole() {
  const [
    createUserRole,
    { loading: createUserRoleLoading, data: createUserRoleResponse, error: createUserRoleError }
  ] = useMutation(CREATE_USER_ROLE, { refetchQueries: [GET_EMAIL_USERS, GET_ORG_USERS] });
  return {
    createUserRole,
    createUserRoleError,
    createUserRoleResponse: createUserRoleResponse?.createUserRole,
    createUserRoleLoading
  };
}
