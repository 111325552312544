import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { DELETE_WEBSITE } from '../../graphql/mutations/Website';
import { USER_DATA_V1 } from '../../graphql/queries/UserData';

export default function useDeleteWebsite() {
  const [openDeleteWebsite, setOpenDeleteWebsite] = useState(false);
  const [
    deleteWebsite,
    { loading: deleteWebsiteLoading, data: deleteWebsiteData, error: deleteWebsiteError }
  ] = useMutation(DELETE_WEBSITE, { refetchQueries: [USER_DATA_V1] });

  useEffect(() => {
    if (!deleteWebsiteLoading && deleteWebsiteData) {
      if (deleteWebsiteData?.deleteWebsite.message) {
        setOpenDeleteWebsite(false);
        toast.success('Website Deleted successfully!');
      } else toast.error('Something went wrong!');
    } else if (!deleteWebsiteLoading && deleteWebsiteError) toast.error('Something went wrong!');
  }, [deleteWebsiteData, deleteWebsiteError, deleteWebsiteLoading]);

  return {
    deleteWebsiteLoading,
    deleteWebsiteData,
    deleteWebsiteError,
    deleteWebsite,
    openDeleteWebsite,
    setOpenDeleteWebsite
  };
}
