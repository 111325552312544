import * as React from 'react';
import { PropTypes } from 'prop-types';

import './Layout.scss';
import Header from '../../components/Header/Header';

const LoginPageLayout = ({ children, showLogin }) => (
  <div className="login-page">
    <div className="login-page__wrapper">
      <div className="login-page__common">
        <div className="login-page__common__bg-top" />
        <div className="login-page__container">
          <Header showLogin={showLogin} />
          <div className="page-container">{children}</div>
        </div>
        <div className="login-page__common__bg-btm">
          <div className="login-page__common__bg-btm_cr login-page__common__bg-btm_cr--circle-left" />
          <div className="login-page__common__bg-btm_cr login-page__common__bg-btm_cr--circle-right" />
        </div>
      </div>
    </div>
  </div>
);

LoginPageLayout.defaultProps = {
  showLogin: true
};

LoginPageLayout.propTypes = {
  children: PropTypes.element.isRequired,
  showLogin: PropTypes.bool
};

export default LoginPageLayout;
