import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { LOCAL_KEYS } from '../constants';

// THIS IS FOR GUEST USER STATUS

export const AuthContext = React.createContext({
  isLoggedIn: false,
  onLogin: () => {},
  onLogout: () => {}
});

const AuthContextProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const guestUserId = localStorage.getItem(LOCAL_KEYS.GUEST_ID);
    const guestUserToken = localStorage.getItem(LOCAL_KEYS.GUEST_TOKEN);
    const googleCaptcha = localStorage.getItem(LOCAL_KEYS.GOOGLE_CAPTCHA);
    const isUserStored = guestUserId && guestUserToken && googleCaptcha;

    if (isUserStored) setIsLoggedIn(true);
  }, []);

  const onLogin = useCallback(() => {
    setIsLoggedIn(true);
  }, []);

  const onLogout = useCallback(() => {
    localStorage.clear(LOCAL_KEYS.GUEST_ID);
    localStorage.clear(LOCAL_KEYS.GUEST_TOKEN);
    localStorage.clear(LOCAL_KEYS.GOOGLE_CAPTCHA);

    setIsLoggedIn(false);
  }, []);

  const value = useMemo(
    () => ({
      isLoggedIn,
      onLogin,
      onLogout
    }),
    [isLoggedIn, onLogin, onLogout]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

AuthContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContextProvider;
