import React from 'react';
import propTypes from 'prop-types';
import { BAR_COLORS } from '../../constants/index';

const PercentageBar = ({ percentage, type }) => {
  const Parentdiv = {
    width: `${percentage + 10}%`,
    // width: '100%',
    borderRadius: '2px'
  };

  const Childdiv = {
    transition: '0.3s',
    height: '100%',
    width: '100%',
    backgroundColor: BAR_COLORS[type],
    borderRadius: '2px',
    textAlign: 'right',
    display: 'inline-block'
  };

  return (
    <div style={Parentdiv}>
      <div style={{ width: '100%', height: '9px' }}>
        <div style={Childdiv} />
      </div>
      <span
        className="margin__1 width__30 --flex-horizontal-right"
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          marginLeft: '0.5rem',
          marginRight: '0',
          paddingRight: '0.5rem'
        }}
      >
        {parseFloat(percentage).toFixed(1)}%
      </span>
    </div>
  );
};

PercentageBar.propTypes = {
  percentage: propTypes.number.isRequired,
  type: propTypes.string.isRequired
};

export default PercentageBar;
