import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import TextField from '../TextField/TextField';
import '../../pages/userManagement/user.scss';
import 'react-toggle/style.css';
import '../Config/Config.scss';

const InviteForm = ({
  email,
  setEmail,
  orgOptions,
  selectedOrg,
  selectedRole,
  roleOptions,
  setSelectedOrg,
  setSelectedRole,
  webOptions,
  selectedWeb,
  setSelectedWeb
}) => {
  const handleEmailChange = e => {
    setEmail(e.target.value);
  };

  const handleSelectOrg = val => {
    setSelectedOrg(val);
    setSelectedWeb('');
  };

  const handleSelectRole = val => {
    setSelectedRole(val);
  };

  const handleSelectWeb = val => {
    setSelectedWeb(val);
  };

  return (
    <div
      className="config_modal__inputs"
      style={{
        marginBottom: '3rem'
      }}
    >
      <div
        style={{
          marginBottom: '2rem'
        }}
      >
        <TextField
          type="text"
          label="Enter Email"
          size="small"
          placeholder="Enter Email"
          changeHandler={handleEmailChange}
          value={email}
          // statusMessage={urlError.statusMessage}
          // status={urlError.status}
          // onTouch={() => {
          //   setIsTouched(true);
          // }}
          // isTouched={isTouched}
          maxLength={255}
          subLabel="Max: 255 characters"
        />
      </div>
      <div>
        <div className="user_role_form__wrapper">
          <div className="user_role_form__row_wrapper">
            <h1 className="user__invite_h1">Organization :</h1>
            <div className="user_role_form__select_wrapper">
              <Select options={orgOptions} value={selectedOrg} onChange={handleSelectOrg} />
            </div>
          </div>
          <div className="user_role_form__row_wrapper">
            <h1 className="user__invite_h1">Role :</h1>
            <div className="user_role_form__select_wrapper">
              <Select options={roleOptions} value={selectedRole} onChange={handleSelectRole} />
            </div>
          </div>
        </div>

        {selectedRole && selectedRole.value > 2 && (
          <div className="user_role_form__row_wrapper">
            <h1 className="user_role_form__label">Website :</h1>
            <div className="user_role_form__select_wrapper">
              <Select options={webOptions} value={selectedWeb} onChange={handleSelectWeb} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

InviteForm.defaultProps = {
  // showEmail: false
  selectedOrg: '',
  selectedRole: '',
  selectedWeb: ''
};

InviteForm.propTypes = {
  email: PropTypes.string.isRequired,
  setEmail: PropTypes.func.isRequired,
  orgOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })
  ).isRequired,
  webOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })
  ).isRequired,
  selectedOrg: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  }),
  selectedRole: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  }),
  selectedWeb: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  }),
  roleOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })
  ).isRequired,
  setSelectedOrg: PropTypes.func.isRequired,
  setSelectedRole: PropTypes.func.isRequired,
  setSelectedWeb: PropTypes.func.isRequired
};

export default InviteForm;
