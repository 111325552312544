import { gql } from '@apollo/client';

const CREATE_JOB = gql`
  mutation CreateJob($request_id: String!) {
    CreateJob(request_id: $request_id) {
      message
      job {
        id
      }
    }
  }
`;

export default CREATE_JOB;
