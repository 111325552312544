import { gql } from '@apollo/client';

const GET_DETAILS = gql`
  query Details(
    $job: String!
    $limit: Int!
    $offset: Int!
    $dropdown: Boolean
    $parameters: ResultInput
  ) {
    details(
      job: $job
      limit: $limit
      offset: $offset
      dropdown: $dropdown
      parameters: $parameters
    ) {
      results {
        link
        link_status
        link_type
        link_location
        page
        content_type
      }
      dropdownValues {
        title
        values
      }
      totalCount
    }
  }
`;

export default GET_DETAILS;
