/* eslint-disable */
import React, { useState, useCallback } from 'react';

export const APIErrorContext = React.createContext({
  error: null,
  addError: () => {},
  removeError: () => {}
});

const APIErrorProvider = ({ children }) => {
  const [error, setError] = useState(null);

  const removeError = () => setError(null);

  const addError = (title, message, status) => {
    setError({ title, message, status });
  };

  const contextValue = {
    error,
    addError: useCallback((title, message, status) => addError(title, message, status), []),
    removeError: useCallback(() => removeError(), [])
  };

  return <APIErrorContext.Provider value={contextValue}>{children}</APIErrorContext.Provider>;
};

export default APIErrorProvider;
