import * as React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import Login from '../../pages/Login/Login';
// import Dashboard from '../../pages/Dashboard/Dashboard';
import PageNotFound from '../../pages/PageNotFound';
import HomePage from '../../pages/home';
import ProtectedRoute from './ProtectedRoute';
import ConfirmationPage from '../../pages/emailConfirmation';
import { AuthContext } from '../../contexts/auth-context';
// import DashboardV1 from '../../pages/Dashboard/DashboardV1';
import History from '../../pages/History';
import AdminDashboard from '../../pages/AdminDashboard';
import UserManagementContainer from '../../pages/userManagement/userManagementContainer';

const AppRoutes = () => {
  const authContext = React.useContext(AuthContext);
  return (
    <Routes>
      <Route
        path="/dashboard"
        element={
          <ProtectedRoute>
            <AdminDashboard isProtected />
            {/* <DashboardV1 isProtected /> */}
          </ProtectedRoute>
        }
      />
      <Route
        path="/history/:id"
        element={
          <ProtectedRoute>
            <History isProtected />
          </ProtectedRoute>
        }
      />
      {/* <Route
        path="/dashboard-v1"
        element={
          <ProtectedRoute>
            <DashboardV1 isProtected />
          </ProtectedRoute>
        }
      /> */}
      <Route exact path="/user" element={<UserManagementContainer isProtected />} />
      {/* {!authContext.isLoggedIn && <Route path="/dashboard" element={<Navigate to="/" replace />} />} */}
      {authContext.isLoggedIn && <Route path="/guest/dashboard" element={<AdminDashboard />} />}
      {!authContext.isLoggedIn && (
        <Route path="/guest/dashboard" element={<Navigate to="/" replace />} />
      )}
      <Route exact path="/components" element={<Login />} />
      <Route exact path="/" element={<HomePage />} />
      <Route exact path="/email-confirmation" element={<ConfirmationPage />} />

      <Route element={<PageNotFound />} />
    </Routes>
  );
};

export default AppRoutes;
