import { gql } from '@apollo/client';

const LOGIN = gql`
  query Login {
    login {
      id
      email
    }
  }
`;

export default LOGIN;
