import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useAuth0 } from '@auth0/auth0-react';
import SideNav from '../../components/SideNav/SideNav';
import userTypeContext from '../../contexts/userTypeContext';
import ActionHeaderV1 from '../../components/ActionHeader/ActionHeaderV1';
import Loader from '../../components/Loader/Loader';
import '../../containers/layout/Layout.scss';
import UserDisplay from './userDisplay';
// import UserSearch from './userSearch';
import UserCreate from './userCreate';
import { useUsersList } from '../../hooks';
// import ConfigV1 from '../../components/ConfigV1/ConfigV1';

const User = ({ isProtected }) => {
  const { isLoading } = useAuth0();

  const { authType, handleAuthType } = useContext(userTypeContext);
  const { fetchUsersInfo, fetchUsersInfoResponse, fetchUsersInfoLoading } = useUsersList();

  useEffect(() => {
    if (isProtected && authType !== 'AUTHENTICATED') {
      handleAuthType();
    }
  }, [authType, handleAuthType, isProtected]);

  useEffect(() => {
    fetchUsersInfo();
  }, [fetchUsersInfo]);

  if (fetchUsersInfoLoading || isLoading)
    return (
      <div className="loading-flex">
        <Loader />
      </div>
    );
  return (
    <div className="sc-page">
      <div className="sc-page__wrapper">
        <div className="sc-page__common">
          <div className="sc-page__container">
            <ActionHeaderV1>
              {/* <ConfigV1
                options={[{ label: 'ABC', value: 'ABC' }]}
                selected={{ label: 'ABC', value: 'ABC' }}
                showDivider={false}
                showTopContent={false}
                showEdit={false}
                showDelete={false}
                onChangeHandler={() => {}}
              /> */}
            </ActionHeaderV1>
            <div className="page-container">
              <SideNav showUserManagement />
              <div className="user__management">
                <UserCreate />
                {/* <UserSearch /> */}
                <UserDisplay renderData={fetchUsersInfoResponse || []} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

User.defaultProps = {
  isProtected: false
};

User.propTypes = {
  isProtected: PropTypes.bool
};

export default User;
