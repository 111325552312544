import { gql } from '@apollo/client';

const FETCH_JOBS_OF_REQUEST = gql`
  query fetchJobs($request_id: String!) {
    jobs(request_id: $request_id) {
      id
      status
      start_time
      finish_time
      totalScanTime
      error_message
      summary {
        external
        internal
        totalCrawlTime
        totalBroken
        noPagesScanned
        noLinksChecked
        issueTypes {
          title
          count
        }
        linkTypes {
          title
          count
        }
        hosts {
          title
          count
        }
      }
      is_aborted
    }
  }
`;

const FETCH_JOB = gql`
  query fetchJob($id: String!) {
    job(id: $id) {
      id
      status
      start_time
      finish_time
      totalScanTime
      error_message
      summary {
        external
        internal
        totalCrawlTime
        totalBroken
        noPagesScanned
        noLinksChecked
        issueTypes {
          title
          count
        }
        linkTypes {
          title
          count
        }
        hosts {
          title
          count
        }
      }
      is_aborted
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export { FETCH_JOBS_OF_REQUEST, FETCH_JOB };
